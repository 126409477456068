import React, { Component } from 'react'
import { connect } from 'react-redux'
import StaticModal from 'components/general/modal/static'
import c from 'classnames'
import { setHelpInfo } from 'state/actions/mainForm'
import { DRF } from 'types/drf'
import { Dispatch } from 'redux';
import { ReduxState, AnyThunkDispatch } from 'types/redux'

interface IStateProps {
	field: DRF.FieldBase
}
interface IDispatchProps {
	closeHelpInfo: () => void
}


const Field = ({field, closeHelpInfo}: IStateProps & IDispatchProps) => {
	return (
		<React.Fragment>
			{ field.id &&
				<StaticModal
					onClose={closeHelpInfo}
					title={field.title}
				>
					 <div dangerouslySetInnerHTML={{__html: field.help_info.html_content}} />
				</StaticModal>
			}
		</React.Fragment>
	)
}

const mapStateToProps = (state: ReduxState) => {
	return {
		field: state.mainForm.helpInfoField
	}
}

const mapDispatchToProps = (dispatch: AnyThunkDispatch): IDispatchProps => (
	{
		closeHelpInfo: () => {
			dispatch(setHelpInfo({}))
		}
	}
)

export default connect(
	mapStateToProps, 
	mapDispatchToProps,
)(Field)
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'
import { useRouter } from 'components/context'
import * as style from './index.module.sass'

export const Tabs = (props) => {
	const router = useRouter()
	if (props.tabs.length <= 1) {
		return null
	}
	return (
		<div className={style.tabContainer}>
			{props.tabs.map(t => (
				<Link
					key={t.name}
					className={cx(style.tab, {[style.active]: router.location.pathname == t.url})}
					to={t.url}
				>
					{t.name}
				</Link>
			))}
		</div>
	)
}


import React, { useState, useMemo } from 'react'
import { Doughnut as DoughnutChart } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import { FormLink, NoData } from '../common'
import * as style from './index.module.sass'

const CHART_OPTIONS = {
		options: {
			legend: false,
			cutoutPercentage: 75
		},
}

const COLORS = [
	"#9b59b6","#3498db","#2ecc71","#f1c40f","#e67e22",
	"#e74c3c","#34495e","#7f8c8d","#27ae60","#ff87d9",
	"#c6e000","#ad9dff","#df9dff","#49f5cf","#c1d3de",
]

type Props = {
	values: {
		name: string
		formLinks: FormLink[]
	}[]
}

const PieMetric = ({values}: Props) => {
	const globalContext = useGlobalContext()
	const [showDetails, setShowDetails] = useState<number | null>(null)
	const processData = useMemo(() => ({
		data: values.map(series => series.formLinks.length),
		backgroundColor: values.map((_,i) => COLORS[i]),
		details: values.map(series => series.formLinks),
		label: values.map(series => series.name),
	}), values)

	if (processData.data.reduce((a, b) => a + b, 0) === 0) {
		return <NoData />
	}

	return (
		<div className={style.container}>
			<div className={style.pie}>
				{}
				<DoughnutChart 
					data={{datasets:[processData], labels: processData.label}}
					{...CHART_OPTIONS}
				/>
			</div>
			<div className={style.pieLabels}>
				{ processData.details.map((set, i) => {
					const selected = showDetails===i
					const classes = classNames(style.pieLabel, {[style.selected]: selected}) 
					return (
						<div 
							key={processData.label[i]} 
							className={classes} 
							onClick={() => setShowDetails(i === showDetails ? null : i)} 
							style={{backgroundColor: selected ? processData.backgroundColor[i] : "rgba(0,0,0,0)"}}
						>
							<div className={style.labelDot} style={{backgroundColor:processData.backgroundColor[i]}}></div>
							<div className={style.labelText}>
								{processData.label[i]}
							</div>
						</div>
					)
				})}
			</div>
			
			{ showDetails !== null &&
				<div className={style.pieDetailedValuesContainer}>
					<div className={style.pieDetailedValues}>
						{ processData.details[showDetails].map(formLink => {
							return (
								<Link
									key={formLink.record.id + "-" + formLink.form.title}
									className={style.formLinkRow}
									to={formUrl({recordId: formLink.record.id, formId: formLink.form.id, highlightFieldId: formLink.field?.id, ...globalContext})}
								>
									<div className={style.topContent}>{formLink.record.name}</div>
									<div className={style.bottomContent}>{formLink.form.title}</div>
								</Link>
							)
						})}
						{ processData.data[showDetails] === 0 && <NoData />}
					</div>
				</div>
			}
		</div>
	)
}

export default PieMetric
import 'core-js/stable' // polyfill ECMAScript features
import 'regenerator-runtime/runtime' // polyfill transpiled generator functions
import "antd/dist/antd.css";

import React from 'react'
import Raven from 'raven-js'
import { render } from 'react-dom'
import { Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'

import { apolloClient } from 'state/apollo-client'
import { ApolloProvider } from "react-apollo"

import ErrorBoundary from 'components/common/error-boundary'
import App from 'components'
import store from './state/redux-store'
import history from 'utils/history'

//@ts-ignore: ignore ie properties that doesn't actually exist in normal browsers
if (/msie\ [0-9]{1}/i.test(navigator.userAgent) || (!!window.MSInputMethodContext && !!document.documentMode)) {
	//using internet explorer
	alert("Internet Explorer is an unsupported browser, try using a modern browser if you experience any issues")
}

// Set up Sentry only in production, so console errors point to the right line
if (process.env.NODE_ENV == 'production') {
	Raven.config(
		'https://38189a0f7a7a49c48928273e23ac84cc@sentry.io/1253022', 
		{
			shouldSendCallback: () => {
				return process.env.NODE_ENV == 'production'
			},
		},
	).install()
}

const AppRoot = () => (
	<ErrorBoundary>
		<ApolloProvider client={apolloClient}>
			{/*
			//@ts-ignore: assume this is because store isn't TS but not sure.. */}
			<Provider store={store}>
				<Router history={history}>
					<Route path="*" component={App} />
				</Router>
			</Provider>
		</ApolloProvider>
	</ErrorBoundary>
)


render(
	<AppRoot />,
	document.getElementById('app')
)
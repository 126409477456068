import gql from "graphql-tag";

export const recordEssentials = gql`
	fragment RecordEssentials on RecordType {
		id
		archived
		image
		name
		position
		team {
			id
		}
	}
`

export const userEssentials = gql`
	fragment UserEssentials on UserType {
		id
		email
		firstName
		howYouHeardAboutUs
		isActive
		isAdmin
		lastName
		recordCount
		seenWelcomeMessage
		topLevelManager
		usingTemporaryPassword
		organisation {
			id
			csvDownloadEnabled
			logo
			name
			showTeamFiles
			subdomain
			usingFormFileLinks
			suite {
				id
				welcomeMessageHtml
				moduleSetItems(orderBy: [{field: rank}]) {
					id
					rank
					module {
						id
						name
						icon
						singleton
						descriptionHtml
						showActiveLinksOnDashboard
						allowRecordTemplateChange
						allowPositionTextChange
						recordFilesEnabled
						recordImages
						recordWord
						positionWord
						teamWord
						teams(orderBy: [{field: name}]) {
							id
							logo
							name
						}
					}
				}
				moduleWord
				adviceSection {
					id
					title
				}
			}
		}
	}
`
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Placeholder from 'components/common/placeholder'
import { wrapWithContext } from 'components/context'
import { getEvents } from 'state/actions/analytics'
import Event from './event'
import { getParams } from '../../common'
import * as style from './index.module.sass'


class Events extends Component {
	state = {
		loading: true
	}

	componentDidMount() {
		this.props.getEvents({offset: 0, limit: this.props.limit || 11, ...getParams(this.props), record: this.props.record || undefined}).then(() => {
			this.setState({...this.state, loading: false})
		})
	}

	loadMore() {
		this.setState({...this.state, loading: true})
		this.props.getEvents({offset: this.props.events.length, limit: 40, ...getParams(this.props), record: this.props.record || undefined}).then(() => {
			this.setState({...this.state, loading: false})
		})
	}

	render() {
		return (
			<div className={style.container}>
				<h5>Recent Activity</h5>
				{
					this.props.events.map(event => {
						return <Event key={event.id} event={event} />
					})
				}
				{ this.props.events.length == 0 && !this.state.loading &&
					<div className="muted">No recent events</div>
				}
				{this.state.loading &&
					[...Array(5).keys()].map((i) => (
						<Placeholder key={i} style={{width: 600, height: 40, margin: 10}} />
					))
				}
				<div className={style.loadMoreButtonContainer}>
					{this.props.more && !this.state.loading &&
						<button className={style.loadMoreButton} onClick={() => this.loadMore()}>more</button>
					}
				</div>
			</div>
		)
	}

}

const mapStateToProps = (state, ownProps) => {
	var events
	if(ownProps.record && ownProps.record.id) {
		events = _.filter(state.analytics.events.objects, e => e.record && e.record.id == ownProps.record.id)
	}
	else {
		events = state.analytics.events.objects
	}

	return {
		events,
		more: state.analytics.events.more,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getEvents: (params) => dispatch(getEvents(params)),
	}
}

export default wrapWithContext(connect(
	mapStateToProps, 
	mapDispatchToProps
)(Events))

import React from 'react'
import * as styles from './index.module.sass'

type Props = {
	items: string[]
}

const SmallFeatureList = ({items}: Props) => (
	<div className={styles.featureSet}>
		{items.map(i => (
			<div key={i} className={styles.feature}><span className="icon-tick" />{i}</div>
		))}
	</div>
)

export default SmallFeatureList
import React from 'react'
import * as styles from './index.module.sass'
import PRIVACY_PDF from '../../../common/pharos_privacy_policy.pdf' 
declare const STATIC_BASE: string

const Footer = () => (
    <>
        <div className={styles.container}>
            <a className={styles.link} href="mailto:support@bastionsystems.com">Contact Us</a>
            <a className={styles.link} href={STATIC_BASE + PRIVACY_PDF} target="_blank">Privacy</a>
        </div>
        <div className="muted">Pharos HR Pty Ltd trading as Bastion Systems · ABN: 60 485 246 546</div>
    </>
)

export default Footer
import * as Yup from 'yup'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import c from 'classnames'
import _ from 'lodash'

import { resetForm } from 'state/actions/mainForm'
import { saveAnswers } from 'state/actions/answer'
import { showModal } from 'state/actions/modal'
import PdfSection from './pdf-section'
import QuickLinks from './quick-links'
import ExternalLink from './external-link'
import { Spinner, SmallSpinner } from 'components/common/loading'
import { answerEmailValidator } from '../form-input-panel/answer-types/answer-email'
import { DRF } from 'types/drf'
import { ReduxState, AnyThunkDispatch } from 'types/redux'
import * as style from './index.module.sass'
import * as commonStyle from './common.module.sass'
import { FormProps } from '../hooks/form-context'
import { createNotification } from 'state/actions/userInterface'
import { changeFormSaveStatus } from 'components/dashboard/module/left-side-panel/record'

type OwnProps = {formProps: FormProps}

type StateProps = {
	answers: ReduxState["answers"]
	form: DRF.Form
	fields: {[id: string]: DRF.Field}
}

type DispatchProps = {
	showRestFormModal: (record: { name: string, position: string }, onConfirm: () => any) => void
	resetForm: typeof resetForm
	showUnsavedChangesModal: () => any
	onSave: ({answers, recordId, formId, code, ignoreNoChange}: {answers: ReduxState["answers"], recordId: string, formId: string, code: string | null, ignoreNoChange: boolean}) => any
	showInvalidFieldsModal: (fields: DRF.Field[]) => any
	showInvalidEmailModal: () => void
}
type Props = OwnProps & StateProps & DispatchProps

type State = {
	showPdfSection: boolean
	saving: boolean
	publicSubmitLoading: boolean
}

class FormControlPanelContainer extends Component<Props, State> {
	state = {
		showPdfSection: true,
		saving: false,
		publicSubmitLoading: false,
	}

	unsavedChanges = () => {
		let newAnswersContainContent = false
		Object.keys(this.props.answers.created).forEach(id => {
			if(this.props.answers.created[id].content != undefined) {
				newAnswersContainContent = true
			}
		})

		return (
			Object.keys(this.props.answers.updated).length > 0 ||
			newAnswersContainContent
		)
	}

	validateForm = () => {
		return this.checkMandatoryFeilds() && this.checkFieldValidity()
	}

	checkFieldValidity = () => {
		const fields = this.props.form.fields.map(f => this.props.fields[f])
		const answerValidatorSchema = Yup.object().shape({
			content: answerEmailValidator,
		})
		const emailFields = fields.filter(f => f.type == 'email')
		const validFieldsArray = emailFields.map(f => {
			const createdAnswers = _.pickBy(this.props.answers.created, a => a.field == f.id)
			const updatedAnswers = _.pickBy(this.props.answers.updated, a => a.field == f.id)
			const answers = { ...createdAnswers, ...updatedAnswers }
			const validAnswersArray = Object.keys(answers).map(aId => {
				const a = answers[aId]
				return answerValidatorSchema.isValidSync(a)
			})
			return validAnswersArray.every(v => v === true)
		})
		const allValid = validFieldsArray.every(v => v === true)
		if (!allValid) {
			this.props.showInvalidEmailModal()
		}
		return allValid
	}

	checkMandatoryFeilds = () => {
		const fields = this.props.form.fields.map((f) => this.props.fields[f])
		const mandatoryFields = fields.filter(f => f.mandatory)

		var invalidFields: DRF.Field[] = []
		mandatoryFields.forEach(f => {

			const createdAnswers = _.pickBy(this.props.answers.created, a => a.field == f.id)
			const oldAnswers = _.pickBy(this.props.answers.saved, a => a.field == f.id)
			const updatedAnswers = _.pickBy(this.props.answers.updated, a => a.field == f.id)

			const content = _.values({...oldAnswers, ...updatedAnswers, ...createdAnswers}).map(a => a.content)
			const validContent = content.filter(c => c !== undefined && c !== null && c !== false)

			const concatenatedValidContent = validContent.map(c => "" + c).join('').replace(' ', '')
			
			if(!concatenatedValidContent) {
				invalidFields = [...invalidFields, f]
			}
		})

		if(invalidFields.length > 0) {
			this.props.showInvalidFieldsModal(invalidFields)
			return false
		}
		return true
	}

	save = ({ignoreNoChange}: {ignoreNoChange: boolean}) => {
		if(this.state.saving) return
		if(!this.validateForm()) return
		this.setState({...this.state, saving: true})
		return this.props.onSave({
			answers: this.props.answers,
			recordId: this.props.formProps.record.id,
			formId: this.props.form.id,
			code: this.props.formProps.publicFormCode,
			ignoreNoChange,
		}).then(() => {
			this.setState({...this.state, saving: false})
			changeFormSaveStatus(this.props.form.id, this.props.formProps.record.id, true)
		})
	}

	publicSubmit = () => {
		if(!this.validateForm()) return
		
		const result = this.save({ignoreNoChange: false})
		if(!result) return

		return result.then(() => {
			if(!this.props.formProps.publicFormSubmit) {
				throw new Error('Public submit missing function : (')
			}
			this.props.formProps.publicFormSubmit()
		})
	}

	reset = () => {
		this.props.resetForm({
			formId: this.props.form.id,
			recordId: this.props.formProps.record.id,
		})
	}

	showResetModal = () => {
		if(this.unsavedChanges()) {
			this.props.showUnsavedChangesModal()
		}
		else {
			this.props.showRestFormModal(this.props.formProps.record, this.reset)
		}
	}

	render() {
		const saveButton = (
			<div className={style.saveButtonContainer}>
				<button
					className={c("button-primary-solid", style.saveButton)}
					onClick={() => this.save({ignoreNoChange: false})}
				>
					Save
				</button>
				{this.state.saving && <SmallSpinner />}
			</div>
		)

		if(this.props.formProps.publicFormCode) {
			return (
				<>
					{saveButton}
					<div>
						<div className="line-break"></div>
						<button
							className="button-primary-solid"
							onClick={this.publicSubmit}>
							Submit
						</button>
						<div>Push Submit once you have completed the form</div>
						{ this.state.publicSubmitLoading && <Spinner />}
					</div>
				</>
			)
		}
		else {
			return (
				<>
					{!this.props.formProps.record.archived &&
						<div className={style.buttonRow}>
							{saveButton}
							{ this.props.formProps.form.allowReset &&
								<div>
									<button
										className={c(style.resetButton, "button-grey", "icon-reset", "button-no-outline")}
										onClick={this.showResetModal}
									>
										Reset
									</button>
								</div>
							}
						</div>
					}
					{ this.props.formProps.form.allowPublicLinks &&
						<div>
							<ExternalLink
								formProps={this.props.formProps}
								readOnly={this.props.formProps.record.archived}
							/>
						</div>
					}
					
					<PdfSection
						recordId={this.props.formProps.record.id}
						formId={this.props.formProps.form.id}
						readOnly={this.props.formProps.record.archived}
						save={() => this.save({ignoreNoChange: true})}
					/>

					<QuickLinks formId={this.props.form.id}/>
				</>
			)
		}
	}
}


const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
	return {
		answers: state.answers,
		fields: state.mainForm.fields,
		form: state.mainForm.form,
	}
}

const mapDispatchToProps = (dispatch: AnyThunkDispatch): DispatchProps => {
	return {
		showRestFormModal: (record, onConfirm) => {
			dispatch(showModal({
				title: 'Are you sure?',
				content: 
					<p>
						Resetting the form will delete data from the form: <b>{record.name}</b>.
						This action will reset all of the specific data for <b>{record.name} - {record.position}</b>.
						All other data will be retained.
						A final pdf of the current data will be created before the Reset is completed.
						Click <b>Reset Form</b> to continue.
					</p>,
				confirmClass: 'button-red',
				confirmText: 'Reset Form',
				confirmAction: onConfirm,
			}))
		},
		resetForm: (params) => {
			return dispatch(resetForm(params))
		},
		showUnsavedChangesModal: () => {
			dispatch(showModal({
				title: 'Unable to reset form: Unsaved changes',
				content: `Please save the form or revert your changes by refreshing the page.`,
				cancelText: 'Cancel'
			}))
		},
		onSave: ({answers, recordId, formId, code, ignoreNoChange}) => {
			if(!ignoreNoChange &&
			Object.keys(answers.updated).length==0 &&
			Object.keys(answers.created).length==0) {
				dispatch(showModal({title: 'Nothing to save', content: 'You have made no changes to the current form', cancelText: 'Cancel'}))
				return Promise.resolve()
			}
			
			return dispatch(saveAnswers(
				{ answers, record: recordId, form: formId },
				code,
			//@ts-ignore
			)).then(() => {
				dispatch(createNotification({
					'content': `Form saved`
				}))
			})
		},
		showInvalidFieldsModal: (fields) => {
			dispatch(showModal({
				title: 'Please fill out required fields',
				content: <div>
					The folling fields must be completed before saving: <br/>
					<ul>
						{fields.map(f => (
							<li key={f.id}>{f.title}</li>
						))}
					</ul>
				</div>,
				cancelText: 'OK'
			}))
		},
		showInvalidEmailModal: () => {
			dispatch(showModal({
				title: 'Please recheck the email fields above',
				content: <div>
					One of the emails you entered is invalid,
					please enter a correct email and save the form again
				</div>,
				cancelText: 'OK'
			}))
		},
	}
}

export default connect(
	mapStateToProps, 
	mapDispatchToProps
)(FormControlPanelContainer)


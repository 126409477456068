import { Event, ActiveExternalLink, TabularRecordMetric } from 'resources/resource'

export const getEvents = (params) => {
	return async dispatch => {
		const response = await new Event().query(params)
		const events = await response.json()
		dispatch({
			type: "GOT_EVENTS",
			events,
		})
	}
}

export const getActiveExternalLinks = (params) => {
	return async dispatch => {
		const response = await new ActiveExternalLink().query(params)
		const links = await response.json()
		dispatch({
			type: "GOT_ACTIVE_EXTERNAL_LINKS",
			links,
		})
	}
}

export const getAggregatedTable = (id, params) => {
	return async dispatch => {
		dispatch({
			type: "GET_TABULAR_EMPLOYEE_METRIC",
			id
		})
		const response = await new TabularRecordMetric().get(id, params)
		const table = await response.json()
		dispatch({
			type: "GOT_TABULAR_EMPLOYEE_METRIC",
			table,
			id,
		})
	}
}

export const getAggregatedTablesList = (m) => {
	return async dispatch => {
		const response = await new TabularRecordMetric().query({moduleId: m.id})
		const tables = await response.json()
		dispatch({
			type: "GOT_TABULAR_EMPLOYEE_METRICS",
			tables,
		})
	}
}
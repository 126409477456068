import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { loginUrl, noTeamUrl, downloadAnswerFileUrl } from 'utils/url'
import { useGlobalContext, useRouter } from 'components/context'
import NavBar from './navbar'
import styles from './index.module.sass'
import NoTeamWarning from 'components/general/full-page-messages/NoTeamWarning'
import Advice from 'components/advice'
import AdminPanel from 'components/admin'
import AccountSettings from 'components/account/settings'
import OrganisationSettings from 'components/organisation/settings'
import AddModule from 'components/module'
import UserManagement from 'components/user-management'
import Module from 'components/dashboard/module'
import { WrappedRouteNotFound } from 'components/common/route-not-found'
import history from 'utils/history'
import ErrorBoundary from 'components/common/error-boundary'
import DownloadAnswerFile from 'components/general/download-answer-file'
import {
	pharosAdminUrl,
	addModuleUrl,
	recordAddUrl,
	userSettingsUrl,
	organisationSettingsUrl,
	userManagementUrl,
	adviceUrl,
	teamNoListUrl,
	GLOBAL_CONTEXT_PARAMS
} from 'utils/url'


export default () => {
	const globalContext = useGlobalContext()
	const [redirected, setRedirected] = useState(false)
	const router = useRouter()
	
	if(!globalContext.user) {
		router.history.push(loginUrl({next: router.location.pathname}))
		return null
	}

	useEffect(() => {
		const user = globalContext.user
		if(!redirected) {
			const noTeam = globalContext.organisation.suite.moduleSetItems.filter(
				m => m.module.teams.length > 0
			).length == 0
			if(user.usingTemporaryPassword) {
				history.push(userSettingsUrl())
			} else if(noTeam) {
				if(user.topLevelManager) {
					history.push(addModuleUrl())
				} else {
					history.push(noTeamUrl())
				}
			} else if(!user.recordCount) {
				const currentModule = globalContext.organisation.suite.moduleSetItems.filter(
					m => m.module.teams.length > 0
				)[0].module
				const currentTeam = currentModule.teams[0]
				history.push(recordAddUrl({currentModule, currentTeam}))
			} else {
				return
			}
			setRedirected(true)
		}
	}, [globalContext.user])

	return (
		<div className={styles.dashboard}>
			<NavBar />
			<ErrorBoundary>
				<div className={styles.dashboardPage}>
					<Switch>
						<Route path={teamNoListUrl(GLOBAL_CONTEXT_PARAMS)} component={Module} />
						<Route path={noTeamUrl()} component={NoTeamWarning} />
						<Route path={adviceUrl()} component={Advice} />
						<Route path={pharosAdminUrl()} component={AdminPanel} />
						<Route path={userSettingsUrl()} component={AccountSettings} />
						<Route path={organisationSettingsUrl()} component={OrganisationSettings} />
						<Route path={addModuleUrl()} component={AddModule} />
						<Route path={downloadAnswerFileUrl(':fileId')} component={DownloadAnswerFile} />
						<Route path={userManagementUrl()} component={UserManagement} />
						<Route path="*" component={WrappedRouteNotFound} />
					</Switch>
				</div>
			</ErrorBoundary>
		</div>
	)
}
import React, { Component } from 'react'
import _ from 'lodash'
import gql from "graphql-tag"
import { LargeSpinner } from 'components/common/loading'
import SignupForm from './signup-form'
import { useQuery } from '@apollo/react-hooks'
import { publicSchemas as QueryType } from './gql-types/publicSchemas'


const GET_PUBLIC_ORGS =  gql`
	query publicSchemas {
		schemaPublic_List(orderBy: [{field: name}]) {
			objects {
				id
				name
				public
				signupSubdomain
				moduleSetItems {
					module {
						id
						name
					}
				}
			}
		}
	}
`


export default () => {
	const { data, loading} = useQuery<QueryType>(GET_PUBLIC_ORGS)
	return (
		<div className="page-form-container">
			<div className="page-form">
				{ loading 
					? <LargeSpinner />
					: <SignupForm suiteOptions={data!.schemaPublic_List!.objects} />
				}
			</div>
			<br/><br/>
		</div>
	)
}

import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'

import * as style from './user-item.module.sass'
import { userManagementEditUrl } from 'utils/url'
import { useRouter } from 'components/context'
import { userList_user_List_objects as UserType } from './gql-types/userList'


export default (props: {user: UserType}) => {
	const router = useRouter()
	const activeLink = userManagementEditUrl(props.user.id) === router.location.pathname
	return (
		<Link
			to={userManagementEditUrl(props.user.id)}
			className={c(style.user, {[style.userActive]: activeLink})}
		>
			<div className={style.topRow}>
				<div className={style.name}>
					{props.user.firstName} {props.user.lastName}
				</div>
				{props.user.topLevelManager &&
					<div className={style.isAdmin}>
						Admin
					</div>
				}
			</div>
			<div className={style.email}>
				{props.user.email}
			</div>
			
		</Link>
	)
}

import React, { Component } from 'react'
import { Query, Mutation } from "react-apollo"
import gql from "graphql-tag";

import { Spinner } from 'components/common/loading'
import Switch from './switch'

const GET_TEAMS = gql`
	query adminTeams($organisation: ID!, $name_Icontains: String) {
		teamAdmin_List(
			limit: 300,
			organisation: $organisation,
			name_Icontains: $name_Icontains,
		) {
			objects {
				id
				name
				organisation{
					id
				}
			}
		}
	}
`

export default class Teams extends Component {
	render() {
		return (
			<Query query={GET_TEAMS} variables={this.props.filter}>
				{({ loading, error, data }) => {
					if(loading) return <Spinner />
					return (
						<div className="teams-container">
							{data.teamAdmin_List.objects.map(b => (
								<div key={b.id} className="team">
									<div className="id">{b.id}</div>
									<div className="team-name">{b.name}</div>
									<Switch orgId={b.organisation.id} teamId={b.id}/>
								</div>
							))}
						</div>
					)
				}}
			</Query>
						
		)
	}
}
import React, { useState } from 'react'
import c from 'classnames'

import RecordListItem from './record-list-item'
import {
	GetRecordList_record_List
} from './gql-types/GetRecordList'
import { useGlobalContext } from 'components/context'
import * as style from './record-list.module.sass'
import { PanelTypes } from '../const'
import LoadingRecords, { PLACEHOLDER_CARDS, PLACHOLDER_CARD_HEIGHT } from './loading-records'


type Props = {
	data: GetRecordList_record_List
	setShowPanel: (panel: PanelTypes) => void
	loading: boolean
	fetchMore: (config: {reset: boolean}) => void
	recordId: string | null
}

const RecordList = ({data, loading, fetchMore, setShowPanel, recordId}: Props) => {
	const globalContext = useGlobalContext()
	const records = data.objects

	const active   = records.filter((e) => !e.archived)
	const archived = records.filter((e) =>  e.archived)

	const emptyList = (
		!data.pageInfo.hasNextPage &&
		!data.objects.length
	)

	const isContainerFull = (scrollEvent: React.UIEvent<HTMLDivElement>) => {
		const container = scrollEvent.target as HTMLDivElement
		let full
		if(container.clientHeight - container.scrollHeight >= 0) {
			full = false
		}
		else {
			//have we scrolled near the bottom?
			const bottomOfView = container.clientHeight + container.scrollTop
			const distanceFromBottom = container.scrollHeight - bottomOfView
			full = distanceFromBottom > PLACHOLDER_CARD_HEIGHT * PLACEHOLDER_CARDS + 200
		}
		if (!full) {
			fetchMore({reset: false})
		}
	}

	const [showArchived, setShowArchived] = useState(false)
	
	return (
		<div className={style.recordListScrollContainerOuter} onScroll={isContainerFull}>
			<div className={style.recordListScrollContainerInner}>
				<div className={style.recordList}>
					{ emptyList && !loading &&
						<div className={style.noRecordsText}>
							There are no {globalContext.currentModule.recordWord.toLowerCase()}s to show
						</div>
					}
					{ active.map((e) => (
						<RecordListItem 
							key={e.id} 
							record={e}
							setShowPanel={setShowPanel}
							recordParamId={recordId}
						/>
					))}
				</div>

				{ archived.length > 0 && 
					<>
						{ showArchived
							? <>
								<h5>Archived</h5>
								<div className={style.recordList}>
									{archived.map((e) => (
										<RecordListItem 
											key={e.id} 
											record={e}
											setShowPanel={setShowPanel}
											recordParamId={recordId}
										/>
									))}
								</div>
							</>
							: <button className={c(style.viewArchived, "button-no-outline", "button-grey")} onClick={() => setShowArchived(true)}>View archived</button>
						}
					</>
				}

				{ loading &&  <LoadingRecords /> }
			</div>
		</div>
	)
}

export default RecordList


import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'

import { formUrl, teamMetricsUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import { FormLink, NoData } from '../common'
import * as style from './index.module.sass'


type Props = {
	result: {
		value: string | null
		formLink: FormLink | null
	}
}

const FieldValueMetric = ({ result }: Props) => {
	const globalContext = useGlobalContext()

	const toUrl = result.formLink
		? formUrl({recordId: result.formLink.record.id, formId: result.formLink.form.id, highlightFieldId: result.formLink.field?.id, ...globalContext})
		: teamMetricsUrl(globalContext)

	if (result.value === null) {
		return <NoData />
	}
	
	return (
		<Link
			className={c(style.container, {'disabled-link': !result.formLink})}
			to={toUrl}
		>
			<div className={style.value}>
				{result.value}
			</div>
			<div className={style.subtitle}>
				{result.formLink?.record.name}
			</div>
		</Link>
	)
}

export default FieldValueMetric
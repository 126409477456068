import React from 'react'
import { Route } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { SinglePanel } from 'components/common/panel'
import AddUser from './user/add'
import EditUser from './user/edit'
import LeftMenu, { GET_USERS } from './left-menu'
import * as style from './index.module.sass'
import { userManagementAddUrl, userManagementEditUrl } from 'utils/url'
import { userList as QueryType } from './left-menu/gql-types/userList'
import { UserListContext } from './context'


export default (props: {children: React.ReactNode}) => {
	const apolloProps = useQuery<QueryType>(
		GET_USERS,
	)
	return (
		<UserListContext.Provider value={apolloProps.refetch}>
			<div className={style.container}>
				<LeftMenu {...apolloProps} />
				<SinglePanel type="thin">
					<Route path={userManagementAddUrl()} component={AddUser} />
					<Route path={userManagementEditUrl(':userId')} component={EditUser} />
				</SinglePanel>
			</div>
		</UserListContext.Provider>
	)
}
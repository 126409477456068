import gql from "graphql-tag"
import c from 'classnames'
import { graphql, Mutation, ChildDataProps, useQuery } from "react-apollo"
import React, { Component, useState } from 'react'
import formatDistanceToNow  from 'date-fns/formatDistanceToNow'
import { SmallSpinner } from 'components/common/loading'
import SimpleIconButton from 'components/common/simple-icon-button'
import { email_List, email_ListVariables, email_List_email_List_objects } from './gql-types/email_List'
import * as styles from './index.module.sass'


const GET_EMAILS = gql`
	query email_List($regardingObjectId: ID!, $regardingObjectContentType: String!) {
		email_List(
			regardingObjectId: $regardingObjectId,
			regardingContentTypeString: $regardingObjectContentType,
			limit: 1000,
			orderBy: [{field: id}]
		) {
			objects {
				id
				sendToEmail
				created
			}
		}
	}
`
type Props = {
	regardingObjectId: string,
	regardingObjectContentType: string
}

const sentEmailList: React.FC<Props> = (props) => {
	const { data, loading } = useQuery<email_List, email_ListVariables>(GET_EMAILS, { variables: props})
	if(loading) {
		return <SmallSpinner />
	}
	return (
		<div>
			{data?.email_List?.objects.map((e) => (
				<div className={styles.emailRow} key={e.id}>
					<div className={c(styles.email, "icon-letter")}>{e.sendToEmail}</div>
					<div className={styles.created}>{formatDistanceToNow(new Date(e.created))} ago</div>
				</div>
			))}
			{data?.email_List?.objects.length == 0  &&
				<div className={styles.noContent}>No emails</div>
			}
		</div>
	)
}

export default sentEmailList

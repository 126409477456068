import React, { Component } from 'react'
import _ from 'lodash'
import gql from "graphql-tag"
import { Formik, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useMutation } from "@apollo/react-hooks"
import { Modal, Button } from 'antd'

import { createNotification } from 'state/actions/userInterface'
import { apolloClient } from 'state/apollo-client'
import { SpinnerButton } from 'components/common/button'
import { TextField, SelectField } from 'components/common/formik'
import { useGlobalContext, GlobalContextProps, useRouter } from 'components/context'
import { recordAddUrl } from 'utils/url'
import { saveTeam as MutationType, saveTeamVariables as MutationVariables } from './gql-types/saveTeam'
import { useDispatch } from 'react-redux'


const CREATE_TEAM = gql`
	mutation saveTeam(
		$name: String!,
		$organisation: ID!,
		$module: ID!
	) {
		team_Update(input: {
			name: $name,
			organisation: $organisation,
			module: $module,
		}) {
			object {
				id
				name
				logo
			}
		}
	}
`

type ModuleType =  NonNullable<GlobalContextProps['currentModule']>

type ValuesType = {
	name: string
}

type Props = {
	module: ModuleType,
	show: boolean,
	callback: () => void,
}

const AddTeam = ({module, show, callback}: Props) => {
	const [mutate, {loading}] = useMutation<MutationType, MutationVariables>(CREATE_TEAM)
	const dispatch = useDispatch();
	const router = useRouter()
	const { organisation, user } = useGlobalContext()
	return (
		<Modal visible={show}  footer={null} onCancel={callback}>
			<Formik
				initialValues={{
					name: "",
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Required'),
				})}
				onSubmit={async (values: ValuesType, actions) => {
					const r = await mutate({variables: {
						name: values.name,
						module: module.id,
						organisation: organisation.id,
					}})
					actions.setSubmitting(false)
					callback()
					dispatch(createNotification({
						content: `Added ${module.teamWord.toLocaleLowerCase()} ${values.name}`,
					}))

					// could potenitally update cache here, but full reload to be safer
					apolloClient.resetStore()
					router.history.push(recordAddUrl({
						currentTeam: {id: r.data!.team_Update!.object!.id},
						currentModule: {id: module.id}
					}))
				}}
			>
				{props => {
					const { isSubmitting, handleSubmit } = props
					return (
						<div className="page-form-container">
							<h5>Enter the name of your {module.teamWord.toLowerCase()}:</h5>
							<form className="common-form" onSubmit={handleSubmit}>
								<Field
									name="name"
									placeholder={`${module.teamWord} name`}
									autoFocus
									component={TextField}
								/>
								<div style={{display: 'flex', justifyContent: 'space-around', width: 200}}>
									<SpinnerButton loading={loading} type="submit">
										Create
									</SpinnerButton>
								</div>
							</form>
						</div>
					)
				}}
			</Formik>
		</Modal>
	)
}

export default AddTeam
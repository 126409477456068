import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import querystring from 'query-string'

import { useGlobalContext, useRouter } from '../context'
import { teamMetricsDefaultUrl, loginUrl, signupUrl } from 'utils/url'
import Login from './login'
import Signup from './signup'
import NavBar from 'components/common/public-navbar'
import DashboardLoading from 'components/dashboard/loading'
import { IS_INCIDENT_WATCH } from 'utils/constants'

const Unauthenticated = () => {
	const router = useRouter()
	const globalContext = useGlobalContext()

	useEffect(() => {
		if(globalContext.user) {
			const qs = querystring.parse(router.location.search)
			router.history.push(qs.next || teamMetricsDefaultUrl(globalContext))
		}
	})
	if(globalContext.user) {
		return <DashboardLoading />
	}
	return (
		<>
			<NavBar />
			<div className="navbar-page">
				<Route path={loginUrl()} component={Login} />
				{!IS_INCIDENT_WATCH &&
					<Route path={signupUrl()} component={Signup}/>
				}
			</div>
		</>
	)
}

export default Unauthenticated
import React, { Component } from 'react'
import { Query, Mutation } from "react-apollo"
import gql from "graphql-tag";

import { Spinner } from 'components/common/loading'
import Org from './org'

const GET_ORGANISATIONS = gql`
	query adminOrganisations($search: String!) {
		organisationAdmin_List(
			search: $search,
			limit: 300
		) {
			objects {
				id
				name
			}
		}
	}
`

export default class Orgs extends Component {
	setOrg = (organisation) => {
		if(this.state.organisation == organisation) {
			this.setState({...this.state, organisation: null})
		}
		else {
			this.setState({...this.state, organisation, teamsLoading: true})
			this.props.getAdminTeams({organisation}).then(r => {
				this.setState({
					...this.state,
					teamsLoading: false,
				})
			})
		}
	}

	render() {
		return (
			<Query query={GET_ORGANISATIONS} variables={{search:this.props.search}}>
				{({ loading, error, data }) => {
					if(loading) return <Spinner />
					return (
						<div>
							{data.organisationAdmin_List.objects.map(o => (
								<Org changeOrg={this.props.changeOrg} org={o} key={o.id}/>
							))}
							
							{data.organisationAdmin_List.objects.length == 0 &&
								<div>
									No results
								</div>
							}
						</div>
					)
				}}
			</Query>
		)
	}
}
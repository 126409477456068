import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { formUrl, recordEditUrl } from 'utils/url'
import formatDistanceToNow  from 'date-fns/formatDistanceToNow'
import { wrapWithContext } from 'components/context'

import * as style from './event.module.sass'

const Event = (props) => {
	var link = null
	if(props.event.record && props.event.form) {
		link = formUrl({recordId: props.event.record, formId: props.event.form, ...props})
	}
	else if(props.event.record) {
		link = recordEditUrl({recordId: props.event.record, ...props})
	}
	return (
		<Link className={style.container} to={link}>
			<div dangerouslySetInnerHTML={{__html: props.event.text_html}}></div>
			<div className={style.secondaryContent}>{formatDistanceToNow(new Date(props.event.created))} ago</div>
		</Link>
	)
}

export default wrapWithContext(Event)
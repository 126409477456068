import React from 'react'
import gql from 'graphql-tag'
import { List } from 'antd'
import { useQuery, useMutation } from 'react-apollo'

import { useGlobalContext } from 'components/context'
import { notifications, notificationsVariables, notifications_fieldNotification_List_objects as NotificationType } from './gql-types/notifications'
import * as style from './index.module.sass'
import { ModalProps } from '../../types'
import AddNotification from './add'


const GET_NOTIFICATIONS = gql`
	query notifications($fieldId: String!, $fieldType: String!, $userId: ID!) {
		fieldNotification_List(
			fieldId: $fieldId,
			fieldContentType: $fieldType,
			relevantToUser: $userId,
		) {
			objects {
				id
				delta
				sendToOrganisation
				isActive
			}
		}
	}
`

const DEACTIVATE_NOTIFICATION = gql`
	mutation deactivateNotification($id: ID!) {
		fieldNotification_Update(input: {id: $id, isActive: false}) {
			object {
				id
				isActive
			}
		}
	}

`

const getText = (num: number) => {
	const dayWord = Math.abs(num) == 1 ? " day" : " days"
	if(num > 0) return num + dayWord + ' after'
	else if(num < 0) return -num + dayWord + ' before'
	else if(num == 0) return 'On the day'
}

const FieldNotificationList = (props: ModalProps) => {
	const globalContext = useGlobalContext()
	const [deactivateNotificationMutation, { loading: deleting }] = useMutation(DEACTIVATE_NOTIFICATION)
	const variables = { fieldId: props.fieldId, fieldType: props.fieldType, userId: globalContext.user.id }
	const { data, loading, refetch } = useQuery<notifications, notificationsVariables>(
		GET_NOTIFICATIONS,
		{ variables },
	)
	const deactivateNotification = async (n: NotificationType) => {
		await deactivateNotificationMutation({variables: {id: n.id}})
	}
	
	return (
		<div>
			{!data?.fieldNotification_List
				? (loading ? null : 'error')
				: (
					<div className={style.listContainer}>
						<List
							size="small"
							bordered
							dataSource={data.fieldNotification_List.objects.filter(n => n.isActive)}
							locale={{emptyText: 'No notifications set'}}
							renderItem={n => (
								<List.Item>
									<div className={style.row}>
										<div className={style.deltaText}>{getText(n.delta)}</div>
										<div className={style.sendToContainer}>
											{n.sendToOrganisation
												? <div className={style.everyone}>Everyone</div>
												: <div className={style.me}>Me</div>
											}
										</div>
										<div style={{width: 30}}>
											{ (!n.sendToOrganisation || globalContext.user.topLevelManager) &&
												<div
													className={`icon-bin ${style.deleteIcon}`}
													onClick={() => deactivateNotification(n)}
												/>
											}
										</div>
									</div>
								</List.Item>
							)}
						/>
					</div>
				)
			}
			<AddNotification {...props} refetch={() => {refetch(variables)}} />
		</div>
	)
}

export default FieldNotificationList



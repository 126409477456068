import React from 'react'
import _ from 'lodash'
import gql from "graphql-tag"
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

import { useRouter } from 'components/context'
import Forms from './forms'
import { record as RecordType, recordVariables as QueryVariables } from './gql-types/record'
import ActionButtons from './action-buttons'
import * as style from './index.module.sass'
import * as commonStyle from '../common.module.sass'
import { TEAM_PANEL, PanelTypes } from '../const'
import Placeholder from 'components/common/placeholder'
import { useGlobalContext } from 'components/context'
import { apolloClient } from 'state/apollo-client'
import { teamMetricsUrl, recordDashboardUrl } from 'utils/url'
import record from 'state/reducers/record'

const GET_EMPLOYEE_FORMS = gql`
	query record($id: ID!) {
		record(
			id: $id,
		) {
			id
			name
			position
			archived
			team {
				id
				name
			}
			template {
				id
				formSet {
					id
					forms(orderBy: [{field: category_Rank}, {field: rank}]) {
						id
						hasCreatedPdf(recordId: $id)
						hasSaved(recordId: $id)
						title
						rank
						category {
								id
								title
								rank
								collapsible
						}
						dependency {
							id
						}
					}
				}
			}
		}
	}
`

export const changeFormSaveStatus = (formId: string, recordId: string, value: boolean) => {
	apolloClient.writeFragment({
		id: 'FormType:' + formId,
		fragment: gql`
			fragment UpdateForm_hasSaved on FormType {
				hasSaved(recordId: "${recordId}")
			}
		`,
		data: {
			hasSaved: value,
			__typename: 'FormType'
		},
	})
}

export const changeFormPdfStatus = (formId: string, recordId: string, value: boolean) => {
	apolloClient.writeFragment({
		id: 'FormType:' + formId,
		fragment: gql`
			fragment UpdateForm_hasCreatedPdf on FormType {
				hasCreatedPdf(recordId: "${recordId}")
			}
		`,
		data: {
			hasCreatedPdf: value,
			__typename: 'FormType'
		},
	})
}



type BaseProps = {
	setShowPanel: (panel: PanelTypes) => void
}
type InnerProps = BaseProps & {
	recordId: string
}


const Record = (props: InnerProps) => {
	const globalContext = useGlobalContext()
	const router = useRouter()
	const { data, error, loading } = useQuery<RecordType, QueryVariables>(
		GET_EMPLOYEE_FORMS,
		{ variables: { id: props.recordId } }
	)
	return (
		<>
			<button
				className={`icon-arrow-left button-grey-solid ${commonStyle.backButton}`}
				onClick={() => {
					props.setShowPanel(TEAM_PANEL)
					router.history.push(teamMetricsUrl(globalContext))
				}}
			>
				All {globalContext.currentModule.recordWord}s
			</button>
			{loading
				? <>
					<Placeholder />
					<Placeholder style={{width: '100%', height: 300}} />
					<Placeholder style={{width: '100%', height: 400}} />
				</>
				: (!data || error)
					? <div>Error</div>
					: <>
						<Link className={commonStyle.title} to={recordDashboardUrl({...props, ...globalContext})}>
							{data.record.name || <span style={{fontStyle: 'italic'}}>No Name</span>}
							{data.record.archived && <span className={style.archived}>&nbsp;Archived</span>}
						</Link>
						<div>
							<span className={style.recordPosition}>{data.record.position}</span>
							<Link
								className={style.recordTeam}
								to={teamMetricsUrl({...globalContext, currentTeam: data.record.team})}
							>
								&nbsp;·&nbsp;{data.record.team.name}
							</Link>
						</div>
						<div className={commonStyle.lineSpacer} />

						<ActionButtons record={data.record} />
						<Forms record={data.record} />
					</>
			}
			
			
		</>
	)
}

type OuterProps = BaseProps & {
	recordId: string | null
}

export default (props: OuterProps) => {
	if (!props.recordId) {
		return <div>No record error</div>
	}
	return <Record {...props} recordId={props.recordId} />
}

import {useEffect} from 'react'
import { getForm, getPublicFormData } from 'state/actions/mainForm'
import { useDispatch } from 'react-redux'

type Params = {recordId: string, formId: string} | {publicFormCode: string}

export const useFetchRestResources = (params: Params) => {
	const dispatch = useDispatch()
	useEffect(() => {
		if ("publicFormCode" in params) {
			dispatch(getPublicFormData(params.publicFormCode))
		} else {
			dispatch(getForm(params))
		}
	}, [
		"recordId" in params && params.recordId,
		"formId" in params && params.formId,
		"publicFormCode" in params && params.publicFormCode,
	])
}

import React, { useEffect, useState } from 'react'
import c from 'classnames'

import * as style from './index.module.sass'

type Props = {
	showFor: number
	size: number
}

const HighlightCircle = ({ showFor, size }: Props) => {
	const [hide, setHide] = useState(false)
	useEffect(() => {
		setTimeout(() => {setHide(true)}, showFor)
	})
	return (
		<div className={c(style.container, {[style.containerHide]: hide})}>
			<div className={style.highlight} style={{width: size, height: size}}/>
		</div>
	)
}

export default HighlightCircle

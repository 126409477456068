import React from 'react'
import { Empty } from 'antd'

import * as style from './common.module.sass'

export type FormLink = {
	field: {
		id: string
		title: string
	} | null
	form: {
		id: string
		title: string
	}
	record: {
		id: string
		name: string
	}
}

export const NoData = () => {
	return (
		<div className={style.container}>
			<Empty />
		</div>
	)
}
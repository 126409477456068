import React, { useState } from 'react'
import _ from 'lodash'

import { SinglePanel } from 'components/common/panel'
import UpdateUser from './update-user'
import UpdatePassword from './update-password'
import { useGlobalContext } from 'components/context'


const AccountSettings = () => {
	const { user } = useGlobalContext()
	const [showPasswordForm, setShowPasswordForm] = useState(user.usingTemporaryPassword)

	return (
		<SinglePanel type="thin">
			<div className="page-form-container">
				<div className="page-form">
					{ !user.usingTemporaryPassword ?
						<>
							<UpdateUser />
							<button
								className="button-small"
								onClick={() => setShowPasswordForm(!showPasswordForm)}
							>
								Change password
							</button>
						</>
					:
						<h3>Set your password</h3>
					}
					
					{ showPasswordForm &&
						<UpdatePassword />
					}
				</div>
			</div>
		</SinglePanel>
	)
}

export default AccountSettings
import React, { Component } from 'react'

import { SinglePanel } from 'components/common/panel'
import Form from './form'

const OrganisationSettings = () => (
	<SinglePanel type="thin">
		<h3 style={{textAlign: 'center'}}>Organisation Settings</h3>
		<Form />
	</SinglePanel>
)

export default OrganisationSettings
import React from 'react'
import gql from "graphql-tag"
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { recordEditUrl, recordCopyUrl, recordDashboardUrl, teamMetricsUrl } from 'utils/url'
import SimpleIconButton from 'components/common/simple-icon-button'
import { useGlobalContext, useRouter } from 'components/context'
import { createNotification } from 'state/actions/userInterface'
import { SetArchived, SetArchivedVariables } from './gql-types/SetArchived'
import { record as RecordType } from './gql-types/record'
import * as style from './action-buttons.module.sass'


const UPDATE_ARCHIVED = gql`
    mutation SetArchived(
        $id: ID!,
        $archived: Boolean!,
        $archivedBy: ID
    ) {
        record_Update(input: {
            id: $id,
            archived: $archived,
            archivedBy: $archivedBy
        }) {
            object {
                id
                archived
            }
        }
    }
`

type Props = {
    record: RecordType["record"]
}

export default ({ record }: Props) => {
    const globalContext = useGlobalContext()
    const router = useRouter()
    const dispatch = useDispatch()
    const [setArchivedMutation, {loading}] = useMutation<SetArchived, SetArchivedVariables>(
        UPDATE_ARCHIVED,
    )
    const setArchived = () => {
        const archived = !record.archived
        setArchivedMutation({variables: {
            id: record.id,
            archived,
            archivedBy: archived ? globalContext.user.id : null,
        }}).then(() => {
            dispatch(createNotification({
                content: `${globalContext.currentModule.recordWord} ${archived ? 'archived' : 'restored'}`,
            }))
        })
        if (archived) {
            router.history.push(teamMetricsUrl(globalContext))
        }
    }
    return (
        <div className={style.container}>
            { !record.archived && 
                <SimpleIconButton
                    to={recordEditUrl({recordId: record.id, ...globalContext})}
                    iconClass="icon-pencil"
                    tooltipDirection="right"
                    title="Edit"
                />
            }

            <SimpleIconButton
                to={recordCopyUrl({recordId: record.id, ...globalContext})}
                iconClass="icon-copy"
                tooltipDirection="right"
                title="Copy"
            />

            <SimpleIconButton
                onClick={setArchived}
                iconClass={'icon-archive'}
                tooltipDirection="right"
                title={record.archived ? 'Reactivate' : 'Archive'}
            />
        </div>
    )
}

export default function publicOrganisations(state={}, action) {
	if(action.type == 'GOT_PUBLIC_ORGANISATIONS') {
		const pOrgs = {}
		action.organisations.forEach(o => {
			pOrgs[o.id] = o
		})
		return pOrgs
	}
	return state
}


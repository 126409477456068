import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import c from 'classnames'
import formatDistanceToNow  from 'date-fns/formatDistanceToNow'

import { CenteredSpinner, SmallSpinner } from 'components/common/loading'
import { showModal } from 'state/actions/modal'
import TickButton from 'components/common/tick-button'
import { getFiles, getFolders, createFile, updateFile, createFolder, updateFolder, createEmptyFolder } from 'state/actions/file'
import Placeholder from 'components/common/placeholder'

class File extends Component {
	constructor(props) {
		super(props)
		this.state = {
			deleting: false,
		}
	}
	
	delete() {
		this.setState({...this.state, deleting: true})
		this.props.details.updateFile(
			{...this.props.file, active: false},
			() => this.setState({...this.state, deleting: false}),
		)
	}

	hasLink() {
		return !!this.props.details.matchFiles[this.props.file.id]
	}

	toggleLink = () => {
		if(this.hasLink()) {
			this.props.details.removeFormLink(this.props.details.matchFiles[this.props.file.id])
		}
		else if(this.props.details.matchedParent) {
			// do nothing
		}
		else {
			this.props.details.createFormLink(this.props.file)
		}
	}

	render() {
		const safeFileName = this.props.file.name.replace(/[^a-zA-Z0-9\.]/g, '') // this is just presentational in the url
		const url = `/api/v1/download_file/${this.props.file.id}/${safeFileName}`
		const queryString = `?content_type=${this.props.details.contentType}&object_id=${this.props.details.objectId}`
		const link = this.props.details.disableDownload ? 'javascript:void(0);' : `${url}${queryString}`

		return <div className="file">
			<div className="row">
				<div className="name-container">
					{(this.props.details.linkMode && this.props.details.readOnly) ?
						<TickButton
							selected={this.hasLink()}
							semiSelected={this.props.details.matchedParent}
							onClick={this.toggleLink}
						/>
					:
						<div className="icon-doc" />
					}
					<a href={link} className="link">
						<b className="name">{this.props.file.name}</b> - {formatDistanceToNow(new Date(this.props.file.created))} old
					</a>
				</div>
				{ !this.props.details.readOnly && !this.state.deleting && !this.props.details.writeOnly && 
					<div className="icon-bin" onClick={() => this.delete()}/>
				}
				{ !this.props.details.readOnly && this.state.deleting &&
					<SmallSpinner />
				}
			</div>
		</div>
	}
}

export class Folder extends Component {
	state = {
		open: true,
		new: false,
		creatingFile: false,
		creatingFolder: false,
		deleting: false,
	}

	toggle() {
		this.setState({...this.state, open: !this.state.open})
	}

	addToFolder() {
		if(this.props.details.newFile) {
			this.setState({...this.state, creatingFile: true})
			this.props.details.createFile(this.props.folder.id).then(r => this.setState({...this.state, creatingFile: false}))
		}
		else if(!this.props.details.newFile && this.props.details.newFolder) {
			this.setState({...this.state, creatingFolder: true})
			this.props.details.createFolder(this.props.folder.id).then(r => this.setState({...this.state, creatingFolder: false}))
		}
		else {
			this.toggle()
		}
	}

	delete() {
		this.setState({...this.state, deleting: true})
		this.props.details.updateFolder(
			{...this.props.folder, active: false},
			() => this.setState({...this.state, deleting: false}),
		)
	}

	hasLink() {
		return !!this.props.details.matchFolders[this.props.folder.id]
	}

	toggleLink = () => {
		if(this.hasLink()) {
			this.props.details.removeFormLink(this.props.details.matchFolders[this.props.folder.id])
		}
		else if(this.props.details.matchedParent) {
			// do nothing
		}
		else {
			this.props.details.createFormLink(this.props.folder)
		}
	}

	render() {
		const adding = this.props.details.newFile || this.props.details.newFolder
		const icon = c({
			'icon-folder': !this.state.open && !adding,
			'icon-folder-open': this.state.open && !adding,
			'icon-add-circle': this.props.details.newFile || this.props.details.newFolder,
		})
		const empty = this.props.folder.folders.length == 0 && this.props.folder.files.length == 0

		const creatingEl = <div className="row">
			<div className="name-container">
				<SmallSpinner />
				<div className="name">Uploading...</div>
			</div>
		</div>

		const newDetails = {...this.props.details, matchedParent: this.props.details.linkMode && (this.props.details.matchedParent || this.hasLink())}

		return <div className="folder">
			<div className="row">
				<div className="name-container">
					{(this.props.details.linkMode && this.props.folder.id && this.props.details.readOnly) ?
						<TickButton
							selected={this.hasLink()}
							semiSelected={this.props.details.matchedParent}
							onClick={this.toggleLink}
						/>
					:
						<div className={icon} onClick={() => this.addToFolder()} />
					}
					<b className="name" onClick={() => this.toggle()}>{this.props.root ? ( this.props.name || 'Files') : this.props.folder.name}</b>
				</div>
				{ !this.props.details.readOnly && !this.props.root && !this.state.deleting && !this.props.details.writeOnly && 
					<div className="icon-bin" onClick={() => this.delete()}/>
				}
				{ !this.props.details.readOnly && this.state.deleting &&
					<SmallSpinner />
				}
			</div>
			{ this.state.open && 
				<div className="folder-contents">
					{this.props.folder.folders.map(f => 
						<Folder key={f.id} folder={f} details={newDetails} />
					)}
					{ this.state.creatingFolder && creatingEl}

					{this.props.folder.files.map(f =>
						<File key={f.id} file={f} details={newDetails}/>
					)}
					{ this.state.creatingFile && creatingEl}

					{empty && !this.state.creatingFolder && !this.state.creatingFile &&
						<div className="muted">This folder is empty</div>
					}
				</div>
			}
		</div>
	}
}



class Files extends Component {
	constructor(props) {
		super(props)
		this.state = {
			newFile: null,
			newFolder: null,
			loading: !this.props.writeOnly,
			folderName: "",
			showRootLoader: false,
		}
	}

	componentDidMount() {
		if(this.props.writeOnly) {
			this.props.createEmptyFolder({
				content_type: this.props.contentType,
				object_id: this.props.objectId,
			})
		}
		else {
			this.props.getFilesAndFolders({
				content_type: this.props.contentType,
				object_id: this.props.objectId,
				parent_folder__isnull: true
			}).then(
				f => this.setState({
					...this.state,
					loading:false,
				})
			)
		}
	}

	onFileReceived = (newFiles) => {
		this.setState({...this.state, newFile: newFiles[0]})
	}

	updateFile = (data, cancelAction) => {
		this.props.updateFile(data, cancelAction)
	}

	updateFolder = (data, cancelAction) => {
		this.props.updateFolder(data, cancelAction)
	}

	createFile = async (folderId) => {
		if(!folderId) {
			this.setState({...this.state, showRootLoader: true})
		}
		const fileName = this.state.newFile.name.length > 70 ? 
			this.state.newFile.name.substring(this.state.newFile.name.length-70,this.state.newFile.name.length) :
			this.state.newFile.name

		await this.props.createFile(
			{
				name: fileName,
				parent_folder: folderId,
				content_type: this.props.contentType,
				object_id: this.props.objectId,
				code: this.props.publicFormCode,
			},
			this.state.newFile,
		)
		this.setState({...this.state, newFile: null, showRootLoader: false})
	}

	showCreateFolder() {
		this.setState({...this.state, newFolder: true})
		setTimeout(() => this.folderInput.focus())
	}

	hideCreateFolder() {
		this.setState({...this.state, newFolder: null})
	}

	createFolder = async (folderId) => {
		if(!this.folderInput.value) {
			this.props.invalidFolderName()
		}
		else {
			this.setState({...this.state, newFolder: null, folderName: "", showRootLoader: true})
			const name = this.state.folderName
			await this.props.createFolder({
				name,
				parent_folder: folderId,
				content_type: this.props.contentType,
				object_id: this.props.objectId,
				code: this.props.publicFormCode,
			})
			this.setState({...this.state, showRootLoader: false})

		}
		return Promise.resolve(true) // so it return a promise either way
	}

	handleFolderNameChange = (v, b) => {
		this.setState({...this.state, folderName: v.target.value})
	}

	render() {
		if(this.state.loading) return (
			<div>
				<Placeholder style={{height: 90}} />
			</div>
		)

		const details = {
			contentType: this.props.contentType,
			objectId: this.props.objectId,

			newFolder: this.state.newFolder,
			newFile: this.state.newFile,

			createFolder: this.createFolder,
			updateFolder: this.updateFolder,
			createFile: this.createFile,
			updateFile: this.updateFile,

			readOnly: this.props.readOnly,
			writeOnly: this.props.writeOnly,
			disableDownload: this.props.disableDownload,

			linkMode: this.props.linkMode,
			matchFiles: this.props.matchFiles,
			matchFolders: this.props.matchFolders,
			removeFormLink: this.props.removeFormLink,
			createFormLink: this.props.createFormLink,
		}
		return (
			<div className="root-folder">
				{Array.isArray(this.props.rootFolder.files) && Array.isArray(this.props.rootFolder.folders) &&
					<Folder
						folder={this.props.rootFolder}
						details={details}
						root
						name={this.props.rootName || null}
					/>
				}
				{this.state.showRootLoader &&
					<CenteredSpinner />
				}
				{ !this.props.readOnly && !this.state.showRootLoader &&
					<div className="create-section">
						{ this.state.newFile && !this.state.newFolder &&
							<div className="upload-row">
								<div className="filename icon-doc">{this.state.newFile.name}</div>
								<div className="button-row">
									<button className="button-primary-solid button-small icon-upload" onClick={() => this.createFile(null)}>
										Upload
									</button>
									<button className="button-small button-red icon-cross" onClick={() => this.setState({...this.state, newFile: null})}>
										Cancel
									</button>
								</div>
							</div> 
						}
						{ this.state.newFolder && !this.state.newFile &&
							<div className="upload-row"> 
								<input 
									type="text"
									placeholder="Folder name"
									ref={i => this.folderInput=i}
									value={this.state.folderName}
									onChange={this.handleFolderNameChange}
									maxLength={100}
								/>
								<button className="button-green button-small icon-tick" onClick={() => this.createFolder(null)}>
									Create
								</button>
								<button className="button-small button-red icon-cross" onClick={() => this.hideCreateFolder()}>
									Cancel
								</button>
							</div>
						}
						{ !this.state.newFile && !this.state.newFolder &&
							<div className="add-container">
								<div className="add-row">
									<button className="button-secondary" onClick={() => this.dropzone.open()}>
										New File
									</button>
									<button className="button-secondary" onClick={() => this.showCreateFolder()}>
										New Folder
									</button>
								</div>
								<Dropzone 
									ref={d => this.dropzone=d}
									className="dropzone-container"
									onDrop={this.onFileReceived}
									multiple={false}
								>
									<div className="dropzone-text">Drop a file here</div>
								</Dropzone>
							</div>
						}
						{(this.state.newFile || this.state.newFolder) && this.props.rootFolder.folders.length > 0 &&
							<div className="instructions">
								To {this.state.newFile ? 'upload' : 'add'} to a specific folder,
								click on the corresponding <span className="icon-add-circle" /> button above
							</div>
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const folders = state.file.folders[ownProps.contentType][ownProps.objectId] || {}
	return {
		rootFolder: {
			id: null,
			files: folders.files,
			folders: folders.folders,
		}
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getFilesAndFolders: (params) => {
			return Promise.all([
				dispatch(getFiles(params)),
				dispatch(getFolders(params)),
			])
		},
		createEmptyFolder: (params) => dispatch(createEmptyFolder(params)),
		createFile: (data, file) => dispatch(createFile(data, file)),
		updateFile: (data, cancelAction) => {
			if(!data.active) {
				dispatch(showModal({
					title: 'Are you sure?',
					content: <p>You will no longer have access to: <b>{data.name}</b></p>,
					confirmClass: 'button-red',
					confirmText: 'Delete',
					cancelAction: () => {
						cancelAction()
					},
					confirmAction: () => {
						dispatch(updateFile(data))
					}
				}))
			}
		},
		updateFolder: (data, cancelAction) => {
			if(!data.active) {
				return dispatch(showModal({
					title: 'Are you sure?',
					content: <div>
						You will no longer have access to <b>${data.name}</b>.
						Any of thie files inside will also be deleted
					</div>,
					confirmClass: 'button-red',
					confirmText: 'Delete',
					cancelAction: () => {
						cancelAction()
					},
					confirmAction: () => {
						dispatch(updateFolder(data))
					}
				}))
			}
		},
		createFolder: (data) => dispatch(createFolder(data)),
		invalidFolderName: () => dispatch(showModal({
			title: "No name provided",
			content: "Please type a name above, then click the location you wish to add the folder",
		}))
	}
}

export default connect(
	mapStateToProps, 
	mapDispatchToProps
)(Files)
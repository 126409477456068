import React, { Component } from 'react'
import _ from 'lodash'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import gql from "graphql-tag"
import { userEssentials } from 'fragments'
import { apolloClient } from 'state/apollo-client'
import { TextField, SelectField } from 'components/common/formik'
import { SpinnerButton } from 'components/common/button'
import { publicSchemas_schemaPublic_List_objects as SchemaType } from './gql-types/publicSchemas'
import { signupVariables as MutationVariables, signup as MutationType } from './gql-types/signup'
import { useMutation } from '@apollo/react-hooks'


const SIGN_UP = gql`
	mutation signup(
		$suiteId: ID,
		$organisationName: String!,
		$firstName: String!,
		$lastName: String!,
		$email: String!,
		$password: String!,
		$howYouHeardAboutUs: String!,
	) {
		user_Signup(
			orgData: {
					suiteId: $suiteId,
					name: $organisationName,
			},
			userData: {
					firstName: $firstName,
					lastName: $lastName,
					email: $email,
					password: $password,
					howYouHeardAboutUs: $howYouHeardAboutUs,
			}
		) {
			user {
				...UserEssentials
			}
			errors {
				field
				messages
			}
		}
	}
	${userEssentials}
`

type Props = {
	suiteOptions: SchemaType[]
}

export default ({ suiteOptions }: Props) => {
	const [signup, {}] = useMutation<MutationType, MutationVariables>(SIGN_UP)
	return (
		<Formik
			initialValues={{
				suite: suiteOptions.length > 1 ? undefined : suiteOptions[0],
				organisationName: '',
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				passwordConfirm: '',
				howYouHeardAboutUs: '',
			}}
			validationSchema={Yup.object().shape({
				suite: Yup.object().required('Required'),
				organisationName: Yup.string().required('Required'),
				email: Yup.string().email().required('Required'),
				firstName: Yup.string().required('Required'),
				lastName: Yup.string().required('Required'),
				password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
				passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
			})}
			onSubmit={(values, actions) => {
				signup({variables: {
					suiteId: values.suite && values.suite.id,
					organisationName: values.organisationName,
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					password: values.password,
					howYouHeardAboutUs: values.howYouHeardAboutUs,
				}}).then((r) => {
					if(r.data!.user_Signup!.errors) {
						const errors = _.mapValues(
							_.keyBy(r.data!.user_Signup!.errors, 'field'),
							'messages'
						)
						actions.setErrors(errors)
					}
					else {
						apolloClient.resetStore()
					}
				}).catch((r: any) => {
					console.error(r)
					actions.setFieldError('all', 'Error!')
				}).finally(() => {
					actions.setSubmitting(false)
				})
			}}
		>
			{ props => {
				const { errors, isSubmitting, handleSubmit } = props
				return (
					<form onSubmit={handleSubmit} className="common-form">
						<h3 className="common-form-title">Sign Up</h3>
						<br/>
						<br/>
						{ suiteOptions.length == 0 &&
							<div className="error">
								There are no available organisations at the moment.
								Please check your domain name or contact support.
							</div>
						}

						{ suiteOptions.length > 1 &&
							<Field
								name={'suite'}
								placeholder="Company type"
								component={SelectField}
								options={suiteOptions}
								renderOption={(s: SchemaType) => s.name}
								formatOptionLabel={(s: SchemaType) => s.name}
								getOptionValue={(s: SchemaType) => s.id}
							/>
						}
						
						{([
							['organisationName', 'Organisation name'],
							['firstName', 'First name'],
							['lastName', 'Last name'],
							['email', 'Email'],
							['password', 'Password', {type: 'password'}],
							['passwordConfirm', 'Password (again)', {type: 'password'}],
							['howYouHeardAboutUs', 'How you heard about us'],
						] as [string, string , {type: string}][]).map(([name, placeholder, options={}]) => {
							return <Field
								key={name}
								name={name}
								placeholder={placeholder}
								component={TextField}
								{...options}
							/>
						})}
						
						{/* 
						//@ts-ignore */}
						<div className="error">{errors.all}</div>

						<SpinnerButton loading={isSubmitting} type="submit">
							Sign Up
						</SpinnerButton>
					</form>
				)
			}}
		</Formik>
	)
}


import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Query, Mutation } from "react-apollo"
import gql from "graphql-tag";

import { userEssentials } from 'fragments'
import Organisations from './orgs'


const UPDATE_USER = gql`
	mutation adminChangeUserOrg(
		$organisation: ID!,
		$team: ID
	) {
		adminChangeUserOrg(
			organisation: $organisation,
			team: $team
		) {
			user {
				...UserEssentials
			}
		}
	}
	${userEssentials}
`

const AdminPanel = ({ search }) => {
	return (
		<Mutation mutation={UPDATE_USER} key={'change-user-company'}>
			{(changeOrg, { loading, error }) => (
				<div style={{display: 'flex'}}>
					<Organisations search={search} changeOrg={changeOrg} />
					{/* <Teams search={this.props.params.search} changeOrg={changeOrg} /> */}
				</div>
			)}
		</Mutation>
	)
}

export default AdminPanel
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Select, InputNumber, Button, Spin } from 'antd'
import { useMutation } from 'react-apollo'

import { useGlobalContext } from 'components/context'
import * as style from './add.module.sass'
import { ModalProps } from '../../types'


const BEFORE = 'Before'
const AFTER = 'After'
type BeforeAftertype = typeof BEFORE | typeof AFTER

const ME = 'Me'
const EVERYONE = 'Everyone'
type SendToType = typeof ME | typeof EVERYONE

const CREATE_NOTIFICATION = gql`
	mutation saveFieldNotification($fieldId: String!, $fieldType: String!, $delta: Int!, $sendToOrganisation: Boolean!) {
		fieldNotification_Update(input: {
			fieldId: $fieldId,
			fieldContentType: $fieldType,
			delta: $delta,
			sendToOrganisation: $sendToOrganisation,
		}) {
			object {
				id
				delta
				sendToOrganisation
				isActive
			}
		}
	}
`

const AddFieldNotification = ({fieldId, fieldType, refetch }: ModalProps & {refetch: () => void}) => {
	const globalContext = useGlobalContext()
	const [newDelta, updateNewDelta] = useState(0)
	const [beforeOrAfter, setBeforeOrAfter] = useState<BeforeAftertype>(BEFORE)
	const [meOrEveryone, setMeOrEveryone] = useState<SendToType>(ME)
	const [createNotificationMutation, { loading: saving }] = useMutation(CREATE_NOTIFICATION)

	const createNotification = async () => {
		await createNotificationMutation({variables: {
			fieldId,
			fieldType,
			sendToOrganisation: meOrEveryone == EVERYONE,
			delta: Math.min(newDelta, 365) * (beforeOrAfter === BEFORE ? -1 : 1)
		}})
		refetch()
	}
	return (
		<div className={style.saveRow}>
			<div className={style.addRow}>
				Notify
				<Select defaultValue={ME} style={{ width: 110 }} onChange={(v: SendToType) => setMeOrEveryone(v)}>
					<Select.Option value={ME}>Me</Select.Option>
					{ globalContext.user.topLevelManager &&
						<Select.Option value={EVERYONE}>Everyone</Select.Option>
					}
				</Select>

				<InputNumber
					defaultValue={newDelta}
					min={0}
					style={{width: 65}}
					max={365}
					placeholder="Days"
					onChange={(v) => updateNewDelta(v || 0)}
				/>
				days
				<Select defaultValue={BEFORE} style={{ width: 90 }} onChange={(v: BeforeAftertype) => setBeforeOrAfter(v)}>
					<Select.Option value={BEFORE}>{BEFORE}</Select.Option>
					<Select.Option value={AFTER}>{AFTER}</Select.Option>
				</Select>
				
				{ saving
					? <Spin />
					:	(
						<Button
							type="primary"
							onClick={createNotification}
						>
							Add
						</Button>
					)
				}
			</div>
			
		</div>
	)
}

export default AddFieldNotification




import React from 'react'
import { Link } from 'react-router-dom'
import _ from "lodash"

import * as style from './dropdown.module.sass'

export type Props = {
	title: string
	iconLink: string
	links?: {
		name: React.ReactNode
		to?: string
		onClick?: () => void
	}[]
	closeDropdown?: () => void
}

export default (props: Props) => {
	return (
		<>
			<Link to={props.iconLink} className={style.title}>{props.title}</Link>
			{props.links && props.links.map((link, i) => (
				<div
					key={i}
					onClick={() => {
						link.onClick && link.onClick()
						props.closeDropdown && props.closeDropdown()
					}}
				>
					{ link.to
						? (
							<Link
								className={style.link}
								to={link.to}
							>
								{link.name}
							</Link>
						) : (
							<div className={style.link}>
								{link.name}
							</div>
						)
					}
					
				</div>
			))}
		</>
	)
}

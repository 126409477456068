import React from 'react'

import Category from './category'
import {
	AdviceSection_adviceSection_List_objects as SectionType
} from '../gql-types/AdviceSection'
import * as style from './index.module.sass'

type Props = {
	sections: SectionType[]
	currentCategoryId: string | null
	currentSubcategoryId: string | null
}

const AdviceLeftPanel = (props: Props) => {
	return (
		<div className={style.container}>
			<div className={style.inner}>
				{props.sections.map(adviceSection => (
					adviceSection.categories.map(c => (
						<Category 
							key={c.id}
							category={c}
							currentCategoryId={props.currentCategoryId}
							currentSubcategoryId={props.currentSubcategoryId}
						/>
					))
				))}
			</div>
		</div>
	)
}

export default AdviceLeftPanel
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PRIVACY_PDF from './pharos_privacy_policy.pdf' 
declare const STATIC_BASE: string

export default function Footer() {
	return (
		<div className="footer">
			<Link to="/public/terms"
				><div className="footer-link">Terms and Conditions</div>
			</Link>
			<a href={STATIC_BASE + PRIVACY_PDF} target="_blank">
				<div className="footer-link">Privacy</div>
			</a>
		</div>
	)
}

import gql from "graphql-tag"

export const GET_RECORD = gql`
	query GetRecord($id: ID!) {
		record(id: $id) {
			id
			name
			position
			image
			team {
				id
				name
			}
			template {
				id
				namePlaceholder
				copyPositionText
				position
			}
		}
	}
`
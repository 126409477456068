import React, {useContext} from 'react'
import { UserEssentials } from 'fragments/gql-types/UserEssentials'
import { SinglePanel, SinglePanelTypeProps } from 'components/common/panel'
import { getFormParam } from 'utils/url'
import { RouteComponentProps } from 'react-router-dom'

export const GLOBAL_CONTEXT_EMPTY: NullableGlobalContextProps = {
	user: null,
	organisation: null,
	currentTeam: null,
	currentModule: null,
}

export const RouterContext = React.createContext<RouteComponentProps | null>(null)

export const useRouter = () => {
	const router = useContext(RouterContext) as any
	return router as Pick<RouteComponentProps, 'location' | 'history'>
}

export const useFormParam = () => {
	const router = useRouter()
	return getFormParam(router.location.pathname)
}

export const GlobalContext = React.createContext(GLOBAL_CONTEXT_EMPTY)

export const useGlobalContext = () => {
	return useContext<NullableGlobalContextProps>(GlobalContext) as GlobalContextProps
}

export interface GlobalContextProps {
	user: UserEssentials
	organisation: UserEssentials["organisation"]
	currentModule: UserEssentials["organisation"]["suite"]["moduleSetItems"][0]["module"]
	currentTeam: UserEssentials["organisation"]["suite"]["moduleSetItems"][0]["module"]["teams"][0] | null
}

export interface NullableGlobalContextProps {
	user: GlobalContextProps["user"] | null
	organisation: GlobalContextProps["organisation"] | null
	currentModule: GlobalContextProps["currentModule"] | null
	currentTeam: GlobalContextProps["currentTeam"] | null
}

type NoConflictProps<P> = Pick<P, Exclude<keyof P, keyof GlobalContextProps>>

export function wrapWithContext<P>(Component: React.ComponentType<P & GlobalContextProps>) {
	const wrappedComponent: React.FC<NoConflictProps<P>> = (props) => {
		const globalContext = useContext<NullableGlobalContextProps>(GlobalContext) as GlobalContextProps
		return <Component {...props as P} {...globalContext} />
	}
	return wrappedComponent
}

export function wrapWithContextPanel<P>(Component: React.ComponentType<P & GlobalContextProps>, panelProps: SinglePanelTypeProps) {
	const wrappedComponent: React.FC<NoConflictProps<P>> = (props) => {
		const globalContext = useContext<NullableGlobalContextProps>(GlobalContext) as GlobalContextProps
		return <SinglePanel {...panelProps}>
			<Component {...props as P } {...globalContext} />
		</SinglePanel>
	}
	return wrappedComponent
}
import React from 'react'
import { Link } from 'react-router-dom'

import { useGlobalContext } from 'components/context'
import { formUrl, teamMetricsUrl } from 'utils/url'
import { FormLink, NoData } from '../common'
import * as style from './index.module.sass'


type Props = {
	__typename?: string
	useFieldTitle?: boolean
	values: {
		__typename?: string
		content: string
		contentObjectName: string
		formLink: FormLink | null
	}[]
}

const ListMetric = ({ values, useFieldTitle }: Props) => {
	const globalContext = useGlobalContext()
	if (values.length == 0) {
		return <NoData />
	}
	
	return (
		<div className={style.container}>
			{ values.map(v => {
				const toUrl = v.formLink
					? formUrl({
						recordId: v.formLink?.record.id,
						formId: v.formLink?.form.id,
						highlightFieldId: v.formLink?.field?.id,
						...globalContext,
					})
					: teamMetricsUrl(globalContext) // default to same URL, so do nothing
				const bottomContent = useFieldTitle ? v.formLink?.field?.title : v.formLink?.form.title
				return (
					<Link
						to={toUrl}
						className={style.row}
						key={`${v.content}-${v.contentObjectName}-${bottomContent}`}
					>
						<div className={style.topRow}>
							<div className={style.leftContent}>
								{v.formLink?.record.name} 
							</div>
							<div className={style.rightContent}>
								{v.content} 
							</div>
						</div>
						<div className={style.bottomContent}>
							{bottomContent}
						</div>
					</Link>
				)
			})}
		</div>
	)
}

export default ListMetric
import { combineReducers } from 'redux'

function refreshRecordList(state = null, action) {
	if(action.type == "SET_REFRESH_EMPLOYEE_LIST") {
		return action.func
	}
	return state
}


export default combineReducers({
	refreshRecordList,
})


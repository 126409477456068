import React, { Component, useState } from 'react'
import gql from "graphql-tag";
import _ from 'lodash'
import classNames from 'classnames'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { singlePanelWrapper, SinglePanel } from 'components/common/panel'
import { CenteredSpinner } from 'components/common/loading'
import {
	publicRecordCreateLink,
	publicRecordCreateLinkVariables,
	publicRecordCreateLink_publicRecordCreateLink_choices as Choice,
} from './gql-types/publicRecordCreateLink'
import {
	CreateRecord,
	CreateRecordVariables,
} from './gql-types/CreateRecord'
import { RouteComponentProps } from 'react-router';

const GET_PUBLIC_RECORD_LINK = gql`
	query publicRecordCreateLink($code: String) {
		publicRecordCreateLink(code: $code) {
			title
			htmlMessage
			namePlaceholder
			choices {
				id
				name
				template {
					namePlaceholder
				}
				form {
					id
				}
			}
		}
	}
`

const CREATE_RECORD = gql`
	mutation CreateRecord($code: String!, $name: String!, $choice: ID!) {
		publicRecordCreate(code: $code, name: $name, choice: $choice) {
			publicFormCode
			success
		}
	}
`

type Props = RouteComponentProps<{code: string}>

const PublicRecordCreate: React.FC<Props> = ({ match }) => {
	const [name, setName] = useState('')
	const [selectedChoice, setSelectedChoice] = useState<Choice | null>(null)
	const [formErrorMessage, setFormErrorMessage] = useState<string>("")
	const { data, error, loading } = useQuery<publicRecordCreateLink, publicRecordCreateLinkVariables>(
		GET_PUBLIC_RECORD_LINK,
		{ variables: { code: match.params.code } },
	)
	const [createRecord, {}] = useMutation<CreateRecord, CreateRecordVariables>(CREATE_RECORD)
	
	if (loading) {
		return <CenteredSpinner />
	} else if (error || !data || !data.publicRecordCreateLink) {
		return(
			<div>
				<h2> This link is invalid </h2>
				<p> Please double check the url and try again</p>
			</div>
		)
	}

	const onSubmit = () => {
		if(!selectedChoice || !name) {
			setFormErrorMessage("Please add a name and choose a catagory")
			return
		}
		const variables = {
			name,
			choice: selectedChoice.id,
			code: match.params.code,
		}
		createRecord({variables}).then(r => {
			if (!r.data || !r.data.publicRecordCreate || !r.data.publicRecordCreate.success) {
				setFormErrorMessage("There was an issue submitting your data, please contact support if your issue persists")
			}
			else {
				document.location.href = `/public_form/${r.data.publicRecordCreate.publicFormCode}`
			}
		})
	}

	const link = data.publicRecordCreateLink
	
	return	(
		<SinglePanel type="thin">
			<div className="public-create-record">
				<h3 className="ie-compat">{link.title}</h3>
				<p className="ie-compat" dangerouslySetInnerHTML={{__html: link.htmlMessage}}></p>
				<input
					type="text" 
					placeholder={link.namePlaceholder || "Name"}
					value={name}
					onChange={(e) => setName(e.target.value)} 
				/>
				<div className="choice-section">
					<h6>Please select a category:</h6>
					{ link.choices.map(choice => {
						const selected = selectedChoice && selectedChoice.id == choice.id
						return (
							<div 
								onClick={() => setSelectedChoice(choice)} 
								className={classNames('choice', {selected})} 
								key={choice.id}>
								{choice.name}
							</div>
						)
					})}
				</div>
				{ formErrorMessage && 
					<div className="error">{formErrorMessage}</div>
				}
				<div><button onClick={onSubmit}>Submit</button></div>
			</div>
		</SinglePanel>
	)
}

export default PublicRecordCreate
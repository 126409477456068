
type Structure = {
	[rank: string]: {
		[tableFieldId: string]: {[atr: string]: any}
	}
}

const fillInAnswerAttributes = (answer: any, field: any, tableField: any, objectId: string) => {
	return {
		...answer,
		content_type: field.content_type,
		object_id: objectId,
		tempId: `initial-${Math.random().toString(36).substring(7)}`,
		type: field.type == 'table' ? tableField.type : field.type,
		id: undefined, // override id, as initial answer will have an id come down
	}
}

export const getInitialAnswers = (field: any, recordId: string) => {
	if (field.type == 'table') {
		const structure: Structure = {}
		field.answers.initial.forEach((initialAnswer: any) => {
			if (!(initialAnswer.rank in structure)) {
				structure[initialAnswer.rank] = {}

			}
			structure[initialAnswer.rank][initialAnswer.table_field] = initialAnswer
		})
		for (let [rank, tableFields] of Object.entries(structure)) {
			for(let fieldTableField of field.table_fields) {
				structure[rank][fieldTableField.id] = fillInAnswerAttributes(
					fieldTableField.id in tableFields ? structure[rank][fieldTableField.id] : {
						content: null,
						field: field.id,
						table_field: fieldTableField.id,
						rank: rank,
					},
					field,
					fieldTableField,
					recordId
				)
			}
		}
		const out: any = {}
		Object.entries(structure).forEach(([_, tableFields]) => {
			Object.entries(tableFields).forEach(([_, answer]) => {
				out[answer.tempId] = answer
			})
		})
		return out
	}
	else {
		const out: any = []
		field.answers.initial.forEach((initialAnswer: any) => {
			const answer = fillInAnswerAttributes(
				initialAnswer,
				field,
				null,
				recordId
			)
			out[answer.tempId] = answer
		})
		return out
	}
	
}

import React, { Component, useState } from 'react'
import c from 'classnames'
import AddTeamModal from 'components/common/add-team-modal'
import { UserEssentials_organisation_suite_moduleSetItems_module as ModuleType } from 'fragments/gql-types/UserEssentials'
import * as styles from './index.module.sass'
import { useGlobalContext } from 'components/context'

type Props = {
	module: ModuleType
}

const ModuleCard = ({module}: Props) => {
	const [showModal, setShowModal] = useState(false)
	const globalContext = useGlobalContext()
	const disabled = module.teams.length > 0
	return (
		<>
			<div
				className={c({
					[styles.cardDisabled]: disabled,
					[styles.cardEnabled]: !disabled,
				})}
				onClick={() => !disabled && setShowModal(true)}
			>
				<h6 className={styles.titleRow}>
					<span className={c(styles.moduleIcon, `icon-${module.icon}`)}/>
					{module.name}
				</h6>
				<div
					className={styles.description} 
					dangerouslySetInnerHTML={{ __html: module.descriptionHtml }}
				/>
				<div className={styles.grow} />
				<div className={styles.buttonRow}>
					{disabled
						? <button disabled={disabled}>Already Active</button>
						: <button className="button-primary-solid icon-add">Activate</button>
					}
				</div>
			</div>
			<AddTeamModal module={module} show={showModal} callback={() => {setShowModal(false)}} />
		</>
	)
}

export default ModuleCard
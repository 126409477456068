import React, { Component } from 'react'
import Select from 'react-select'
import _ from 'lodash'
import SimpleIconButton from 'components/common/simple-icon-button'
import { AnswerTextBaseProps } from './types'
import * as style from './answer-dropdown.module.sass'
import * as commonStyle from './common.module.sass'
import { ValueType } from 'react-select/src/types'

interface IOption {
	label: React.ReactNode;
	value: string;
}

interface IState {
	options: IOption[]
	selected: IOption | null
}

const OTHER: IOption = {label: <div>Other &nbsp;<span className="icon-pencil" /></div>, value: '_other'}

export default class AnswerDropDown extends Component<AnswerTextBaseProps, IState> {
	textInput: HTMLInputElement | null;
	innerSelect: HTMLInputElement | null;
	// this should be:
	// private innerSelect = React.createRef<Select<IOption>>()

	constructor(props: AnswerTextBaseProps) {
		super(props)
		this.textInput = null
		this.innerSelect = null
		
		let options: any = props.field.select_options.map((o: string) => ({value: o, label: o}))
		
		let selected = options.find((o: IOption) => o.value==this.props.answer.content) || null

		if(props.field.type == 'drop_down_other') {
			options.push(OTHER)
		}
		if(this.props.answer.content && !selected) {
			selected = OTHER
		}

		this.state = {
			selected,
			options
		}
	}

	reset = () => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: '',
		})
		this.setState({selected: null})
		_.defer(() => this.innerSelect && this.innerSelect.focus())
	}

	onDropdownChanged = (selectedAny: ValueType<IOption>) => {
		const selected = selectedAny as IOption
		
		if(Array.isArray(selected)) {
			throw('hee')
		}
		if(selected === OTHER) {
			this.props.onAnswerChanged({
				...this.props.answer,
				content: '',
			})
			_.defer(() => this.textInput && this.textInput.focus())
		}
		else if(selected) {
			this.props.onAnswerChanged({
				...this.props.answer,
				content: (selected || {}).value || "",
			})
		}
		
		this.setState({ selected })
	}

	onTextInputChanged = (event:  React.ChangeEvent<HTMLInputElement>) => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: event.target.value,
		})
	}

	formatOption = (o: IOption) => {
		if (o === OTHER) {
			return <div className={style.otherOption}>{o.label}</div>
		}
		return o.label
	}

	render() {
		return (
			this.props.disabled ?
				<div>{this.props.answer.content || <i className={commonStyle.noContent}>No content</i>}</div>
			: this.state.selected === OTHER ?
				<div className={style.answerDropdownOther}>
					<input 
						type='text'
						className={commonStyle.formInput}
						ref={c => this.textInput = c}
						onChange={this.onTextInputChanged}
						placeholder={this.props.field.placeholder}
						value={this.props.answer.content || ""}
					/>
					<SimpleIconButton
						small
						iconClass="icon-list"
						onClick={this.reset}
						title="See selectable options"
						tooltipDirection="left"
					/>
				</div>
			:
				<div className={style.answerDropdown}>
					<Select
						ref={(c: any) => this.innerSelect = c}
						options={this.state.options}
						className={style.input}
						value={this.state.selected}
						onChange={this.onDropdownChanged}
						placeholder={this.props.field.placeholder || 'Select...'}
						formatOptionLabel={this.formatOption}
						clearable={false}
						openMenuOnFocus
					/>
				</div>
				
		)
	}
}


import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import c from 'classnames'

interface IProps {
	iconClass?: string;
	small?: boolean;
	noHover?: boolean;
	to?: string;
	text?: string;
	onClick?: () => any;

	title?: string;
	description?: React.ReactNode;
	tooltipDirection?: string;

}

export default (props: IProps) => {
	const button = (
		<button
			className={c('simple-icon', 'button-no-outline', props.iconClass, {
				'small-icon': props.small,
				'no-hover': props.noHover,
			})}
			onClick={props.onClick}
		>
			{props.text}
		</button>
	)

	return ( 
		<div 
			className={`simple-icon-button ${props.tooltipDirection == 'left' ? 'left' : 'right'}`}
		>
			<Tooltip placement="bottom" title={props.title} >
				{props.to 
					? <Link to={props.to}>
						{button}
					</Link>
					: button
				}
			</Tooltip>
		</div>
	)
}

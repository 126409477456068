import React from 'react'
import { Link } from 'react-router-dom'
import { SinglePanel } from '../panel'
import { useGlobalContext } from 'components/context'
import { teamMetricsDefaultUrl, landingPageUrl } from 'utils/url'

const RouteNotFound = () => {
	const globalContext = useGlobalContext()
	return (
		<div>
			<h1><span className="icon-not-found"/>Page not found</h1>
			<h4>Please contact support if this issue persists</h4>
			{globalContext.user
				? <Link className="classic-anchor" to={teamMetricsDefaultUrl(globalContext)}>Go to dashboard</Link>
				: <Link className="classic-anchor" to={landingPageUrl()}>Go to home page</Link>
			}
		</div>
	)
}

export const WrappedRouteNotFound = () => (
	<SinglePanel>
		<RouteNotFound />
	</SinglePanel>	
)



export default RouteNotFound
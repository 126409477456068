import React, { Component } from 'react'
import * as Yup from 'yup'
import * as style from './answer-text.module.sass'
import * as commonStyle from './common.module.sass'

export const answerEmailValidator = Yup.string().email().nullable(true)
import { AnswerTextBaseProps } from './types'


export default class AnswerTextShort extends Component<AnswerTextBaseProps> {
	onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: event.target.value,
		})
	}

	render() {
		const isValid = answerEmailValidator.isValidSync(this.props.answer.content)
		return this.props.disabled ?
			<div className={style.answerTextDisabled}>
				{this.props.answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
		:
			<input 
				type='text'
				className={commonStyle.formInput}
				onChange={this.onChange}
				placeholder={this.props.field.placeholder}
				value={this.props.answer.content || ""}
				style={isValid ? {} : {borderColor: 'red'}}
			/>
	}
}


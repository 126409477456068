import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import _ from 'lodash'

import Placeholder from 'components/common/placeholder'
import { DRF } from 'types/drf'
import SimpleIconButton from 'components/common/simple-icon-button'
import * as commonStyle from '../common.module.sass'
import * as style from './pdf-generator.module.sass'
import Pdf, { PDF_FRAGMENT } from './pdf'
import { pdfGenerators_pdfGenerator_List_objects as PdfGeneratorType } from './gql-types/pdfGenerators'
import { createPdf, createPdfVariables } from './gql-types/createPdf'
import { useGlobalContext } from 'components/context'
import { changeFormPdfStatus } from 'components/dashboard/module/left-side-panel/record'
import { useSelector } from 'react-redux'
import { ReduxState } from 'types/redux'


type Props = {
	pdfGenerator: PdfGeneratorType
	recordId: string
	formId: string
	readOnly: boolean
	save: (args: {ignoreNoChange: boolean}) => Promise<any>
}


const CREATE_PDF = gql`
	mutation createPdf(
		$pdfGenerator: ID!
		$form: ID!
		$record: ID!
		$name: String!
		$createdBy: ID!
	) {
		pdf_Update(
			input: {
				pdfGenerator: $pdfGenerator
				record: $record
				form: $form
				name: $name
				createdBy: $createdBy
			}
		) {
			object {
				...Pdf
			}
		}
	}
	${PDF_FRAGMENT}
`

const  PdfGenerator = (props: Props) => {
	const [showPdfs, setShowPdfs] = useState(1)
	const globalContext = useGlobalContext()
	const [seeMore, setSeeMore] = useState(false)
	const [createPdfMutation, { loading: createPdfLoading }] = useMutation<createPdf, createPdfVariables>(CREATE_PDF)

	const createPdf = async () => {
		const maybePromise = props.save({ignoreNoChange: true})
	
		if (maybePromise && maybePromise.then) {
			maybePromise.then( async () => {
				const response = await createPdfMutation({variables: {
					pdfGenerator: props.pdfGenerator.id,
					record: props.recordId,
					form: props.formId,
					name: props.pdfGenerator.name,
					createdBy: globalContext.user.id,
				}, refetchQueries: ['pdfGenerators']})
				changeFormPdfStatus(props.formId, props.recordId, true)
				const pdf = response.data?.pdf_Update?.object
				setShowPdfs(showPdfs + 1)
				if (!pdf) {
					alert('error')
				}
			})
		}
	}
	const formIsDirty = useSelector((state: ReduxState) => {
		return (
			Object.keys(state.answers.created).length
			+ Object.keys(state.answers.updated).length
		) > 0
	})
	
	const checkIfFormIsDirty = (e: React.SyntheticEvent) => {
		if ( formIsDirty ) {
			alert('Please save to preview the most recent chanes')
			e.preventDefault()
		}
	}

	const shownPdfs = seeMore
		? props.pdfGenerator.pdfs
		: props.pdfGenerator.pdfs.slice(0, showPdfs)
	const hiddenPdfs = props.pdfGenerator.pdfs.length - showPdfs
	return (
		<div>
			<div className={commonStyle.sectionTitle}>
				Pdf:&nbsp;
				<span className="muted">{props.pdfGenerator.name}</span>
			</div>
			{props.readOnly && props.pdfGenerator.pdfs.length == 0 &&
				<div className="muted">No pdfs</div>
			}
			{ createPdfLoading && <Placeholder />}

			{props.pdfGenerator.pdfs.length > 0 &&
				<div className={style.pdfList}>
					{ shownPdfs.filter(pdf => pdf.isActive).map(pdf => 
						<Pdf
							key={pdf.id}
							pdf={pdf}
							recordId={props.recordId}
							formId={props.formId}
						/>
					)}
				</div>
			}
			
			<div className={style.createPdfButtonContainer}>
				<a onClick={checkIfFormIsDirty} target="_blank" href={`/api/v1/pdf/preview/${props.pdfGenerator.id}/${props.recordId}/${props.formId}`}>
					<SimpleIconButton
						iconClass="icon-eye"
						tooltipDirection="right"
						title="Preview pdf"
					/>
				</a>
				
				<SimpleIconButton
					iconClass="icon-add"
					tooltipDirection="right"
					title="Create pdf"
					onClick={createPdf}
				/>
				
				{ hiddenPdfs > 0 &&
					<SimpleIconButton
						iconClass="icon-clock"
						tooltipDirection="right"
						title={`Show previous (${hiddenPdfs} more...)`}
						onClick={() => setSeeMore(!seeMore)}
					/>
				}
			</div>
		</div>
	)
}

export default PdfGenerator
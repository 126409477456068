import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import ErrorBoundary from 'components/common/error-boundary'
import { SinglePanel } from 'components/common/panel'
import { useGlobalContext, useRouter } from 'components/context'
import ExportToCsv from 'components/organisation/export-to-csv'
import TeamSettings from 'components/organisation/team-settings'
import Form from 'components/form'
import Analytics from 'components/analytics'
import RecordEdit from 'components/record/update/edit'
import { FilesTeam } from 'components/file/team'
import RecordAdd from 'components/record/update/add'
import RecordDashboard from 'components/record/dashboard'
import { WrappedRouteNotFound } from 'components/common/route-not-found'
import {
	GLOBAL_CONTEXT_PARAMS,
	formUrl,
	exportDataUrl,
	teamSettingsUrl,
	teamUrl,
	recordUrl,
	teamFilesUrl,
	teamAnalyticsBaseUrl,
	recordAddUrl,
	recordDashboardUrl,
	recordEditUrl,
	recordCopyUrl,
} from 'utils/url'
import LeftSidePanel from './left-side-panel'

type Props = RouteComponentProps<{recordId: string}>

const LeftPanelRoutes = ({ match }: Props) => {
	return (
		<>
			<LeftSidePanel recordId={match.params.recordId || null} />
			<ErrorBoundary>
				<Switch>
					<Route path={teamAnalyticsBaseUrl(GLOBAL_CONTEXT_PARAMS)} component={Analytics} />
					<Route path={formUrl({recordId: ':recordId', formId: ':formId', ...GLOBAL_CONTEXT_PARAMS})} component={Form} />
					<Route path={recordAddUrl(GLOBAL_CONTEXT_PARAMS)} component={RecordAdd} />
					<Route path={teamSettingsUrl(GLOBAL_CONTEXT_PARAMS)} component={TeamSettings} />
					<Route path={exportDataUrl(GLOBAL_CONTEXT_PARAMS)} component={ExportToCsv} />
					<Route path={teamFilesUrl(GLOBAL_CONTEXT_PARAMS)} component={FilesTeam} />
					<Route path={recordDashboardUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS})} component={RecordDashboard} />
					<Route path={recordEditUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS})} component={RecordEdit} />
					<Route path={recordCopyUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS})} component={RecordAdd} />
					<Route path="*" component={WrappedRouteNotFound} />
				</Switch>
			</ErrorBoundary>
		</>
	)
}

export default (props: RouteComponentProps<{teamId: string}>) => {
	const context = useGlobalContext()
	const router = useRouter()
	const teamError = props.match.params.teamId !== "all" && !context.currentTeam
	if (teamError || !context.currentModule) {
		return (
			<SinglePanel type="thin">
				<h1><span className="icon-warning" />Permission error</h1>
				<p>There was an issue fetching your current {context.organisation.suite.moduleWord}/team.</p>
				<p>If you think this is a bug, please contact support.</p>
			</SinglePanel>
		)
	} else if (context.currentModule.teams.length === 0) {
		return (
			<SinglePanel type="thin">
				<h1><span className="icon-warning" />Error: No {context.currentModule.teamWord.toLowerCase()}</h1>
				<p>
					Please create a {context.currentModule.teamWord.toLowerCase()} for {context.currentModule.name}
				</p>
				<p>If you think this is a bug, please contact support.</p>
			</SinglePanel>
		)
	}
	return (
		<Switch>
			<Route path={recordUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS})} component={LeftPanelRoutes} />
			<Route path={teamUrl(GLOBAL_CONTEXT_PARAMS)} component={LeftPanelRoutes} />
			<Route path="*" component={WrappedRouteNotFound} />
		</Switch>
	)
}
import React from 'react'

import PricingBox from './pricing-box'
import { DRF } from 'types/drf'
import { PRICING_SCROLL_ID } from 'components/common/public-navbar/light'

const PRICING_COLORS = [
	'color-peach',
	'color-purple',
	'color-green',
	'color-blue',
]

type Props = {
	plans: React.ComponentProps<typeof PricingBox>['plan'][]
}

const Pricing = ({ plans }: Props) => (
	<div className="pricing-container" id={PRICING_SCROLL_ID}>
		{ plans.map((p, i) => {
			return <PricingBox
				key={i}
				color={PRICING_COLORS[i]}
				plan={p}
			/>
		})}
	</div>
)

export default Pricing
import React, { Component } from 'react'
import { SinglePanel } from 'components/common/panel'

export default (props: {children: React.ReactNode}) => (
    <SinglePanel type="thin">
        <div className="page-form-container">
            <div className="page-form">
                {props.children}
            </div>
        </div>
    </SinglePanel>
)
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useGlobalContext, useRouter } from 'components/context'
import { SinglePanel } from 'components/common/panel'
import AdminSearchResults from './search-results'


const AdminPanel = ({ match }: RouteComponentProps<{search: string}>) => {
	const [search, setSearch] = useState(match.params.search || '')
	const globalContext = useGlobalContext()

	return (
		<SinglePanel type="thin">
			<div className="admin-panel">
				<h2>Switch company</h2>
				<div className="current-org">
					Current:
					<div className="org">{globalContext.organisation.name}</div>
					{!globalContext.currentTeam 
						? '(top level)'
						: globalContext.currentTeam.name
					}
				</div>
				{!globalContext.user.isAdmin &&
					<div className="error">You are not admin</div>
				}
				<div className="search-row" >
					<input
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						type="text"
						placeholder="Search"
					/>
				</div>
				{ search &&
					<AdminSearchResults search={search} />
				}
			</div>
		</SinglePanel>
	)
}

export default AdminPanel
import React, { Component } from 'react'
import { AnswerNumberBaseProps } from './types'
import * as commonStyle from './common.module.sass'

const REGEX = /\d{0,12}/

export default class AnswerInteger extends Component<AnswerNumberBaseProps> {
	onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value

		const regexResult = REGEX.exec(value)

		if(regexResult) {
			const regexValue = regexResult[0]
			const validValue = regexValue === '' ? null : regexValue
			
			this.props.onAnswerChanged({
				...this.props.answer,
				content: validValue,
			})
		}
	}

	render() {
		return this.props.disabled ?
			<div>{this.props.answer.content || <i className={commonStyle.noContent}>No content</i>}</div>
		:
			<input 
				type='text'
				className={commonStyle.formInput}
				placeholder={this.props.field.placeholder}
				onChange={this.onChange}
				value={this.props.answer.content || ""}
			/>
	}
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import c from 'classnames'

import Answer from './Answer'
import { ANSWER_SIZE_LOOKUP } from './answer-types/lookup'
import FieldNotification from './field-notification'
import { toggleFullScreenField } from 'state/actions/userInterface'
import { wrapWithContext, GlobalContextProps } from 'components/context'
import { TABLE_FIELD_CONTENT_TYPE } from 'utils/constants'
import HelpInfo from './help-info/button'
import SimpleIconButton from 'components/common/simple-icon-button'
import { DRF } from 'types/drf'
import { ReduxState, AnyThunkDispatch } from 'types/redux'
import HighlightCircle from './highlight-circle'
import * as style from './field.module.sass'
import * as answerTableStyle from './answer-types/answer-table.module.sass'
import { FormProps } from '../hooks/form-context'

const MIN_COL_WIDTH = 115

type OwnProps = {
	field: DRF.Field
	formProps: FormProps
	forwardRef: React.RefObject<HTMLDivElement>
	highlight: boolean
}

type DispatchProps = {
	toggleFullScreenField: (id: string | null) => void
}
type StateProps = {
	fullScreenField: string | null
}

type State = {
	tableFieldShowColumns: number
}

type Props = OwnProps & StateProps & DispatchProps & GlobalContextProps

class Field extends Component<Props, State> {
	private row: HTMLDivElement | null

	state = {
		tableFieldShowColumns: 0,
	}

	constructor(props: Props) {
		super(props)
		this.row = null

	}

	componentDidMount() {
		this.checkTableFieldShowColumns()
		window.addEventListener('resize', this.checkTableFieldShowColumns)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkTableFieldShowColumns)
	}

	componentDidUpdate() {
		this.checkTableFieldShowColumns()
	}

	checkTableFieldShowColumns = () => {
		if(!(this.props.field.type === 'table')) return
		const tableFieldShowColumns = this.getTableFieldShowColumns()
		if(tableFieldShowColumns != this.state.tableFieldShowColumns) {
			this.setState({...this.state, tableFieldShowColumns})
		}
	}

	getTableFieldShowColumns = () => {
		// hide columns if there are too many to fit
		let showColumns = this.props.field.table_fields.length
		if(this.props.fullScreenField) {
			return showColumns
		}
		if(this.row) {
			const totalMinWidth = this.props.field.table_fields.length * MIN_COL_WIDTH
			const extraSpace = this.row.clientWidth-60 - totalMinWidth
			if(extraSpace < 0) {
				const hideCount = Math.ceil(-extraSpace/MIN_COL_WIDTH)
				showColumns -= hideCount
			}
		}
		return showColumns
	}

	toggleFullScreenField = () => {
		this.props.toggleFullScreenField(this.props.fullScreenField ? null : this.props.field.id)
	}

	render() {
		const field = this.props.field
		const inline = (field.type == "boolean" || field.type == "radio")
		const rowClass = c({[style.inlineFieldRow]: inline})
		return (
		<div className={style.formField} ref={this.props.forwardRef}>
			{ field.subsection_heading && 
				<h3 className={style.fieldSubsectionHeading}>{field.subsection_heading}</h3>
			}

			<div className={rowClass}>
				<div>
					<div className={style.fieldTitleContainer}>
						{ this.props.highlight && 
							<div style={{marginLeft: -30, marginRight: 10}}>
								<HighlightCircle showFor={2000} size={20} />
							</div>
						}
						<div className={style.fieldTitle}>{field.title}</div>
						{ field.type=='date' && this.props.user &&
							<div style={{marginLeft: -8}}>
								<FieldNotification
									recordId={this.props.formProps.record.id}
									formId={this.props.formProps.form.id}
									fieldType="field"
									fieldId={field.id}
									publicFormCode={this.props.formProps.publicFormCode}
								/>
							</div>
						}
						{ this.props.field.help_info && 
							<div style={{marginRight: -15}}>
								<HelpInfo field={this.props.field} />
							</div>
						}
						{ this.props.field.type == 'table' &&
								<SimpleIconButton
									onClick={this.toggleFullScreenField}
									iconClass={this.props.fullScreenField ? "icon-shrink" : "icon-expand"}
								/>
						}
					</div>
					{field.subtitle && <p className={style.fieldSubtitle}>{field.subtitle}</p>}
				</div>

				<div className={style.fieldContentContainer}>
					<div className={style.fieldContent}>
						{ this.props.field.type== 'table' &&
							<div className={answerTableStyle.answerTable}>
								<div
									className={answerTableStyle.answerTableRow}
									ref={r => this.row=r}
								>
									{ this.props.field.table_fields.map((tf, i) => {
										if(i+1 > this.state.tableFieldShowColumns) return null

										return (
											<div
												className={`${answerTableStyle.cell} ${answerTableStyle.tableFieldTitle}`}
												key={tf.id}
												style={ANSWER_SIZE_LOOKUP[tf.type]}
											>
												{tf.title}
												{ tf.help_info && 
													<HelpInfo field={tf} />
												}
												{
													tf.type == 'date' && this.props.user &&
														<FieldNotification
															recordId={this.props.formProps.record.id}
															formId={this.props.formProps.form.id}
															fieldType={TABLE_FIELD_CONTENT_TYPE}
															fieldId={tf.id}
															publicFormCode={this.props.formProps.publicFormCode}
														/>
												}
											</div>
										)
									})}
								</div>
							</div>
						} 

						<Answer 
							fieldId={this.props.field.id}
							formProps={this.props.formProps}
							tableFieldShowColumns={this.state.tableFieldShowColumns}
						/>
					</div>
					{ this.state.tableFieldShowColumns < this.props.field.table_fields.length &&
						<div className={style.more} onClick={this.toggleFullScreenField}>
							<button className={c(style.moreButton, "button-primary-solid button-small")}>More</button>
						</div>
					}
				</div>
			</div>
		</div>
		)
	}
}

const mapStateToProps = (state: ReduxState) => {
	return {
		fullScreenField: state.userInterface.fullScreenField,
	}
}

const mapDispatchToProps = (dispatch: AnyThunkDispatch): DispatchProps => {
	return {
		toggleFullScreenField: (id) => {
			dispatch(toggleFullScreenField(id))
		},
	}
}

export default wrapWithContext<OwnProps>(connect(
	mapStateToProps, 
	mapDispatchToProps
)(Field))
import React, { Component } from 'react'
import c from 'classnames'

import * as style from './index.module.sass'
import ErrorBoundary from '../error-boundary'
import { useSelector } from 'react-redux'
import { ReduxState } from 'types/redux'


type PanelType = 'thin' | 'full-width'

export type SinglePanelTypeProps = {
	type?: PanelType
}

type SinglePanelProps = SinglePanelTypeProps & {
	children: React.ReactNode | React.ReactNode[]
	noPadding?: boolean
	// loaded?: boolean
	// className?: string
}

export function singlePanelWrapper<P>(Component: React.ComponentType<P>) {
	const wrappedComponent: React.FC<P> = (props) => (
		<SinglePanel>
			<Component {...props} />
		</SinglePanel> 
	)
	return wrappedComponent
}

export const SinglePanel = React.forwardRef((props: SinglePanelProps, ref:React.Ref<HTMLDivElement>) => {
	const printableDashboard = useSelector((state: ReduxState) => state.userInterface.printableDashboard);
	const containerClass = props.type == 'thin'
		? style.panelContainerThin
		: props.type == 'full-width'
		? style.panelContainerFullWidth
		: style.panelContainer

	return (
		<ErrorBoundary>
			<div
				ref={ref}
				style={printableDashboard ? {height: 'auto'}: {}}
				className={c(
					style.panelScrollContainer,
					{[style.panelBoxShadow]: props.type=='full-width'}
				)}
			>
				<div className={containerClass}>
					<div className={style.panel}>
						<div className={c(style.panelInner, {[style.noPadding]: props.noPadding})}>
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</ErrorBoundary>
	)
})

import { Answer } from 'resources/resource'
import { showErrorModal } from './modal'

export function answerChanged(answer) {
	return {
		type: 'ANSWER_CHANGED',
		answer: answer,
	}
}

export function savedAnswers(answers, toDeleteAnswers) {
	return {
		type: 'SAVED_ANSWERS',
		answers,
		toDeleteAnswers,
	}
}

export function saveAnswers({ answers, ...saveDetails }, code) {
	return dispatch => {
		const toSave = [
			...Object.keys(answers.created).map(id => answers.created[id]),
			...Object.keys(answers.updated).map(id => answers.updated[id]),
		]
		return new Answer().bulkSave({ answers: toSave, ...saveDetails }, code).then(response => {
			if (response.status === 200) {
				return response.json().then(answers => {
					dispatch(savedAnswers(answers))
				})
			} else if (response.status === 409) {
				return response.json().then((body) => {
					dispatch(showErrorModal({title: "Save conflict!", content: body.error}))
				})
			} else {
				dispatch(showErrorModal())
			}
		}).catch(() => {
			dispatch(showErrorModal())
		})
	}
}

export function deactivateAnswers(answers) {
	return {
		type: 'DEACTIVATE_ANSWERS',
		answers,
	}
}

export function undoDeactivateAnswers(answers) {
	return {
		type: 'UNDO_DEACTIVATE_ANSWERS',
		answers,
	}
}
const ANSWER_TEMP_ID_PREFIX = 'temp-answer'

export function addAnswer(answer) {
	return {
		type: "ADD_ANSWER",
		answer: {
			...answer,
			tempId: 'temp-' + Math.random().toString(36).substring(7),
		},
	}
}

export function reorderAnswers(answers, isTable) {
	const newAnswers = []
	answers.forEach((a, i) => {
		if(isTable) {

			a.forEach(tableAnswer => {
				newAnswers.push({...tableAnswer, rank: i})
			})
		}
		else {
			newAnswers.push({...a, rank: i})
		}
	})
	return {
		type: 'MULTIPLE_ANSWERS_CHANGED',
		answers: newAnswers,
	}
}

import React, { Component } from 'react'
import NavBar from 'components/common/public-navbar'

function PublicAppHoc<P>(Component: React.ComponentType<P>) {
	return (props: P) => (
		<>
			<NavBar />
			<div className="navbar-page">
				<Component {...props} />
			</div>
		</>
	)

}

export default PublicAppHoc
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { DRF } from 'types/drf'
import { signupUrl, getDjangoDomain } from 'utils/url'

type PlanFeature = {
	text: string
}

type Plan = {
	title: string
	cost: string
	costSuffix: string
	subText?: string
	buttonText: string
	contactUsEmail?: string
	planFeatures: PlanFeature[]
}

type Props = {
	color: string
	deselected?: boolean
	buttonTextOverride?: string
	plan: Plan
}

export default function PricingBox({ plan, color, deselected }: Props) {
	const button = (
		<button
			type="button"
			className="action-button"
		>
			<b>{plan.buttonText}</b>
		</button>
	)
	return (
		<div className="pricing-box-container">
			<div className={classNames({'pricing-box': true, [color]: !deselected, deselected: deselected})}>
				<div className="title-container">
					<div className="main-title">{plan.title}</div>
					<div className="secondary-title">
						{ plan.cost &&
							<span className="price">{plan.cost} </span>
						}
						<span className="period">{plan.costSuffix}</span>
					</div>
				</div>
				<div className="content-container">
					<div className="subtext">{plan.subText}</div>
					<div className="items-container">
						{ plan.planFeatures.map((f) => {
							return <div key={f.text} className="item">
								<div className="icon-tick"></div>
								<div className="text">{f.text}</div>
							</div>
						})}
					</div>
					{plan.contactUsEmail
						? <a href={`mailto:${plan.contactUsEmail}`}>{button}</a>
						: <a href={getDjangoDomain() + signupUrl()}>{button}</a>
					}
				</div>
			</div>
		</div>
	)
}

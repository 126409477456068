import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import gql from "graphql-tag"

import { useGlobalContext } from 'components/context'
import { showModal } from 'state/actions/modal'
import { MarkWelcomeModalSeenVariables, MarkWelcomeModalSeen } from './gql-types/MarkWelcomeModalSeen'

const UPDATE_USER = gql`
	mutation MarkWelcomeModalSeen($id: ID!) {
		user_Update(
			input: {
				id: $id,
				seenWelcomeMessage: true
			}
		) {
			object {
				id
				seenWelcomeMessage
			}
		}
	}
`

const WelcomeModal = () => {
	const { user, organisation } = useGlobalContext()
	const [shown, updateShown] = useState(false)
	const [mutate, {}] = useMutation<MarkWelcomeModalSeen, MarkWelcomeModalSeenVariables>(UPDATE_USER)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!shown && user && !user.seenWelcomeMessage) {
			updateShown(true)
			mutate({variables: {id:user.id}})
			if(organisation.suite.welcomeMessageHtml) {
				dispatch(showModal({
					content: <div
						dangerouslySetInnerHTML={{
							__html: organisation.suite.welcomeMessageHtml
						}}
					/>,
					cancelText: 'OK',
				}))
			}
		}
	}, [user && user.id])

	return null
}

export default WelcomeModal
import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { STATIC_BASE } from 'utils/constants'

interface IProps {
	images: string[];
}

interface IState {
	images: string[];
}

const SHOW_TIME = 3000

export default class ImageCarousel extends Component<IProps, IState> {
	_ismounted = true
	constructor(props: IProps) {
		super(props)
		this.state = {
			images: [...props.images]
		}
	}

	rotate() {
		setTimeout(_ => {
			if(!this._ismounted) return
			const newImages = [...this.state.images]
			const front = newImages.shift()!
			newImages.push(front)

			this.setState({
				images: newImages
			})
			this.rotate()
		}, SHOW_TIME)
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	componentDidMount() {
		this.rotate()
	}

	render() {
		const images = this.state.images
		const [back] = this.state.images.slice(-1)

		return (
			<div className="image-carousel">
				<TransitionGroup>
					<CSSTransition 
						classNames="screen-animation" 
						timeout={{enter: 500, exit: 500}}
						key={images[0]} 
					>
						<img className="rotate-image" src={STATIC_BASE + images[0]} style={{zIndex: 2}}/>
					</CSSTransition>
				</TransitionGroup>

				<img className="rotate-image" src={STATIC_BASE + back}/>
			</div>
		)
	}
}


import React from 'react'
import _ from 'lodash'
import gql from "graphql-tag"
import c from 'classnames'
import { useDispatch } from 'react-redux'

import { createNotification } from 'state/actions/userInterface'
import { apolloClient } from 'state/apollo-client'
import { SmallSpinner } from 'components/common/loading'
import { useMutation } from '@apollo/react-hooks'
import * as style from './team-item.module.sass'
import { createUserTeam as CreateType, createUserTeamVariables as CreateVariables } from './gql-types/createUserTeam'
import { removeUserTeam as RemoveType, removeUserTeamVariables as RemoveVariables } from './gql-types/removeUserTeam'
import { teams_team_List_objects as TeamType } from './gql-types/teams'
import { getUser_user as UserType } from '../gql-types/getUser'
import { useGlobalContext } from 'components/context'


const CREATE_USER_TEAM = gql`
	mutation createUserTeam(
		$team: ID!,
		$user: ID!,
	) {
		userTeam_Update(input: {
			team: $team,
			user: $user
		}) {
			object {
				id
				active
				team {
					id
				}
				user {
					id
				}
			}
		}
	}
`

const DELETE_USER_TEAM = gql`
	mutation removeUserTeam(
		$id: ID!
	) {
		userTeam_Update(input: {
			id: $id,
			active: false
		}) {
			object {
				id
				active
			}
		}
	}
`

type Props = {
	editable: boolean
	team: TeamType
	user: UserType
}


export default (props: Props) => {
	const [remove, removeDetails] = useMutation<RemoveType, RemoveVariables>(DELETE_USER_TEAM)
	const [add, addDetails] = useMutation<CreateType, CreateVariables>(CREATE_USER_TEAM)
	const loading = removeDetails.loading || addDetails.loading
	const dispatch = useDispatch()
	const globalContext = useGlobalContext();

	const updateCache = (memberId: string | null) => {
		apolloClient.writeFragment({
			id: 'TeamType:' + props.team.id,
			fragment: gql`
				fragment _ on TeamType {
					memberId(userId: "${props.user.id}")
				}
			`,
			data: {
				memberId,
				__typename: 'TeamType'
			},
		})
	}

	if(!props.editable && !props.team.memberId) return null

	return (
		<div className={style.teamItem}>
			{loading
				? <SmallSpinner />
				: props.team.memberId
					? <div
						className={c(
							"icon-tick",
							style.icon,
							{
								[style.notEditable]: !props.editable,
								[style.matched]: props.editable,
							},
						)}
						onClick={ async () => {
							await remove({variables: {id: props.team.memberId as string}})
							dispatch(createNotification({
								content: `Removed ${props.user.firstName} from ${props.team.name}`,
							}))
							updateCache(null)
						}}
					/>
					: <div
						className={c("icon-tick", style.icon)}
						onClick={ async () => {
							const r = await add({variables: {user: props.user.id, team: props.team.id}})
							dispatch(createNotification({
								content: `Added ${props.user.firstName} to ${props.team.name}`,
							}))
							updateCache(r.data!.userTeam_Update!.object!.id)
						}}
					/>
			}
			{globalContext.organisation.suite.moduleSetItems.length >= 2 &&
				`${props.team.module.name}: `
			}
			<b>&nbsp;{props.team.name}</b>
		</div>
			
	)
}
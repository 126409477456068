import gql from 'graphql-tag'
import { useMutation, MutationResult } from 'react-apollo'

import { publicFormSuccessUrl } from 'utils/url'
import history from 'utils/history'
import { showModal } from 'state/actions/modal'
import { useDispatch } from 'react-redux'
import { publicFormSubmit, publicFormSubmitVariables } from './gql-types/publicFormSubmit'

const SUBMIT_PUBLIC_FORM = gql`
	mutation publicFormSubmit($publicFormCode: String!) {
		publicForm_Submit(publicFormCode: $publicFormCode) {
			success
		}
	}
`

type ReturnType = [
	() => void,
	MutationResult<publicFormSubmit>,
]

export const useSubmitPublicFormMutation: (publicFormCode: string | null) => ReturnType = (publicFormCode) => {
	const dispatch = useDispatch()
	
	const [publicSubmitMutation, mutationResult] = useMutation< publicFormSubmit, publicFormSubmitVariables>(SUBMIT_PUBLIC_FORM)

	let publicFormSubmit: (() => void) | null = null

	publicFormSubmit = () => {
		if (!publicFormCode) {
			alert("Error: You're submitting a public form without a code")
			return
		}
		dispatch(showModal({
			title: 'Are you sure?',
			content: "When you submit this form you won't be able to make any changes afterwards.",
			confirmClass: 'button-green',
			confirmText: 'Submit',
			confirmAction: async () => {
				try {
					const response = await publicSubmitMutation({variables: {publicFormCode: publicFormCode}})
					history.push(publicFormSuccessUrl())
				} catch(err) {
					console.error(err)
					dispatch(showModal({
						title: 'Unable to submit form',
						content: `An error occured when submitting your form. If this issue persists, please contact support`,
						cancelText: 'Cancel'
					}))
				}
			}
		}))
	}
	return [publicFormSubmit, mutationResult]
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Events from './events'
import ActiveExternalLinks from './active-external-links'
import Metrics from './metrics'
import { useGlobalContext } from 'components/context'
import * as style from './index.module.sass'


const TeamDashboard = () => {
	const globalContext = useGlobalContext()
	return (
		<div className={style.container}>
			<Metrics />
			<div className={style.eventLists}>
				{globalContext.currentModule.showActiveLinksOnDashboard &&
					<ActiveExternalLinks
						currentModule={globalContext.currentModule}
						currentTeam={globalContext.currentTeam}
					/>
				}
				<Events />
			</div>
		</div>
	)
}

export default TeamDashboard
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { adviceItemUrl } from 'utils/url'
import {
	AdviceSection_adviceSection_List_objects_categories as Category,
} from '../gql-types/AdviceSection'
import * as style from './category.module.sass'


type Props = {
	category: Category
	currentCategoryId: string | null
	currentSubcategoryId: string | null
}

export default function AdviceSection(props: Props) {
	const selected = props.category.id == props.currentCategoryId
	const [open, setOpen] = useState(selected)
	const categoryClass = classNames(style.category, {[style.categorySelected]: selected})

	return (
		<div className={style.adviceListItem}>
			<h5
				className={categoryClass}
				onClick={() => setOpen(!open)}
			>
				{props.category.title}
			</h5>
			{ open && props.category.subcategories.map((s) => {
				const subcategoryClass = classNames(
					style.subcategory,
					{[style.subcategorySelected]: props.currentSubcategoryId == s.id}
				)
				return (
					<Link
						key={s.id}
						className={subcategoryClass}
						to={adviceItemUrl(props.category.id, s.id)}
					>
						{s.title}
					</Link>
				)
			})}
		</div>
	)
}

import React from 'react'
import c from 'classnames'

type Props = {
	selected: boolean
	semiSelected?: boolean
	onClick?: (...any: any) => any
}

const TickButton : React.FC<Props> =  ({ selected, semiSelected, onClick }) => (
	<div
		className="tick-container"
		onClick={onClick}
	>
		<div 
			className={c(
				'tick-select-button',
				'icon-tick',
				{'matched':selected, 'matched-parent': semiSelected},
			)}
		/>
	</div>
)

export default TickButton

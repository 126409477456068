import { DRF } from 'types/drf'

type Props = {
	field: DRF.FieldBase
	record: { archived: boolean}
	publicFormCode: string | null
}

export const isFieldDisabled = ({ field, record, publicFormCode }: Props) => {
	const publicDisabled = field.disable_when_public && publicFormCode
	const disabled = publicDisabled || record.archived || field.disabled
	return disabled
}
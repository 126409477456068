import React, { useRef } from 'react'

import * as style from './index.module.sass'

interface Props {
	title?: string;
	children: React.ReactNode;
	onClose?: () => void;
}

const StaticModal = (props: Props) => {
	const blockerRef = useRef(null);
	const close = (e: React.SyntheticEvent) => {
		if(e.target == blockerRef.current) {
			props.onClose && props.onClose()
		}
	}
	return (
		<div className={style.modalBlocker} onClick={close} ref={blockerRef}>
			<div className={style.modal}>
				<h4 className={style.title}>{props.title}</h4>
				<div className={style.content}>{props.children}</div>
			</div>
		</div>
	)
}

export default StaticModal
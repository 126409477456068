import React from 'react'
import c from 'classnames'

import * as styles from './index.module.sass'
import { VIDEO_SCROLL_ID } from 'components/common/public-navbar/light'

type Props = {
	url: string
}

const Video = ({url}: Props) => (
	<div className={styles.container} id={VIDEO_SCROLL_ID}>
		
		<iframe
			src="//fast.wistia.net/embed/iframe/f34c871mvy" 
			scrolling="no"
			className={c("wistia_embed", styles.iframe)}
			frameBorder="0"
			name="wistia_embed"
			width="100%"
		/>
	</div>
)

export default Video
import React, { useState } from 'react'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import { Menu, Dropdown, Icon } from 'antd'
import { Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-apollo'

import EmailModal from 'components/email/modal'
import { emailModalUrl, emailModalSendUrl, editPdfNamelUrl } from 'utils/url'
import { showModal } from 'state/actions/modal'
import { PDF_CONTENT_TYPE } from 'utils/constants'
import { useGlobalContext } from 'components/context'
import * as style from './pdf.module.sass'
import { Pdf as PdfType } from './gql-types/Pdf'
import { EditName } from './edit-name'

type Props = {
	pdf: PdfType
	recordId: string
	formId: string
}

export const PDF_FRAGMENT = gql`
	fragment Pdf on PdfType {
		id
		name
		created
		fromPublicLink
		isActive
	}
`

const DELETE_PDF = gql`
	mutation deletePdf(
		$id: ID!
	) {
		pdf_Update(
			input: {
				id: $id
				isActive: false
			}
		) {
			object {
				id
				isActive
			}
		}
	}
`

{/* <EmailList
	tooltipDirection="left"
	filter={{
		regardingObjectId: pdf.id,
		regardingContentTypeString: PDF_CONTENT_TYPE,
	}}
/> */}

const Pdf = ({ pdf, recordId, formId }: Props) => {
	const globalContext = useGlobalContext()
	const dispatch = useDispatch()
	const [deletePdfMutation, { loading }] = useMutation(DELETE_PDF)

	const showDeleteModal = () => dispatch(showModal({
		title: 'Are you sure?',
		content: 
			<p>
				You will no longer have access to this pdf
			</p>,
		confirmClass: 'button-red',
		confirmText: 'Delete pdf',
		confirmAction: async () => {
			await deletePdfMutation({variables: {id: pdf.id}, refetchQueries: ['pdfGenerators', 'pdfGenerator_List']})

		},
	}))

	const emailProps = {
		recordId,
		formId,
		regardingObjectId: pdf.id,
		regardingObjectContentType: PDF_CONTENT_TYPE
	}

	const menu = (
		<Menu>
			<Menu.Item key="0">
				<Link to={emailModalSendUrl({...globalContext, ...emailProps})}>Email</Link>
			</Menu.Item>
			<Menu.Item key="1">
				<Link to={editPdfNamelUrl({...globalContext, pdfId: pdf.id, recordId, formId})}>Rename</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3" onClick={showDeleteModal}>Delete</Menu.Item>
		</Menu>
	)

	return (
		<div className={style.pdfContainer} key={pdf.id}>
			<div className={style.pdf}>
				<a target="_blank" className={style.link} href={`/api/v1/pdf/download/${pdf.id}.pdf`}>
					<div className={style.pdfTextContainer}>
						<div className={`${style.icon} icon-medium-page`}></div>
						<div>
							<div>{pdf.name}</div>
							<div className="muted">{format(new Date(pdf.created), 'MMM do yyyy h:m a')}</div>
							{pdf.fromPublicLink && <div className={style.fromShareLink}>From share link</div>}
						</div>
					</div>
				</a>
				<Dropdown overlay={menu} trigger={['click']}>
					<Icon type="more" style={{fontSize: '1.5rem'}} />
				</Dropdown>
				
			</div>
			<Route
				path={emailModalUrl({...globalContext, ...emailProps})}
				component={() => <EmailModal {...emailProps} />}
			/>
			<Route
				path={editPdfNamelUrl({...globalContext, pdfId: pdf.id, recordId, formId })}
				component={() => <EditName pdf={pdf} recordId={recordId} formId={formId} />}
			/>
		</div>
	)
}

export default Pdf
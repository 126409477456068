import React from 'react'
import gql from "graphql-tag"
import { useQuery } from '@apollo/react-hooks'
import { RouteComponentProps } from 'react-router-dom'

import { GetAnswerFile, GetAnswerFileVariables } from './gql-types/GetAnswerFile'
import Placeholder from 'components/common/placeholder'
import * as style from './index.module.sass'
import { Answer } from 'resources/resource'

export const GET_ANSWER_FILE = gql`
	query GetAnswerFile($id: ID!) {
		answerFile(id: $id) {
			id
			name
			modified
			field {
				id
				title
			}
		}
	}
`

type Props = RouteComponentProps<{fileId: string}>

const DownloadAnswerFile = ({ match }: Props) => {
	const {data, loading} = useQuery<GetAnswerFile, GetAnswerFileVariables>(
		GET_ANSWER_FILE,
		{variables: {id: match.params.fileId}},
	)
	return (
		<div className={style.container}>
			<div className={style.card}>
				{loading
					? <Placeholder style={{height: 200}} />
					: ((!data || !data.answerFile)
						?	<div>error</div>
						: (
							<>
								<h5>{data.answerFile.field.title}</h5>
								<div><span className="muted">Name: </span>{data.answerFile.name}</div>
								<br/>
								<a href={Answer.getFileUrl(data.answerFile, {code: undefined})}>
									<button className="button-primary-solid">Download file</button>
								</a>
							</>
						)
					)
				}
			</div>
		</div>
	)
}

export default DownloadAnswerFile
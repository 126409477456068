import React from 'react'

export function Spinner() {
	return (
		<div className="spinner-container">
			<div className="spinner">
			</div>
		</div>
	)
}

export function SmallSpinner() {
	return (
		<div className="small-spinner-container">
			<div className="spinner">
			</div>
		</div>
	)
}

export function LargeSpinner() {
	return (
		<div className="large-spinner-container">
			<div className="spinner">
			</div>
		</div>
	)
}

export function CenteredSpinner() {
	return (
		<div className="centered-spinner-container">
			<div className="spinner">
			</div>
		</div>
	)
}

export function LoadingBar({small}: {small?: boolean}) {
	return (
		<div className={small ? "loading-bar-small" : "loading-bar"} />
	)
}
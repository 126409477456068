import { combineReducers } from 'redux'


function fullScreenField(state=null, action) {
	if(action.type == "EXPAND_CENTER_PANEL") {
		return state ? null : action.fieldId
	}
	return state
}

function printableDashboard(state=false, action) {
	if(action.type == "TOGGLE_PRINTABLE_VERSION") {
		return !state
	} else if (action.type == "DISABLE_PRINTABLE_VERSION") {
		return false
	}
	return state
}

function notifications(state = [], action) {
	if(action.type == 'CREATE_NOTIFICATION') {
		return [...state, action.notification]
	}
	else if(action.type == 'REMOVE_NOTIFICATION') {
		return state.filter(n => {
			return n.id !== action.notification.id
		})
	}
	return state
}

export default combineReducers({
	fullScreenField,
	printableDashboard,
	notifications,
})
import React, { useEffect, useState, RefObject } from 'react'
import gql from 'graphql-tag'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import _ from 'lodash'
import { Icon } from 'antd'

import { useGlobalContext, useRouter } from 'components/context'
import { ConcatenatedTable as ConcatenatedTableType } from './gql-types/concatenatedTable'
import { getLoadingCards } from './common'
import * as style from './index.module.sass'
import { useDebounce } from 'utils/hooks'
import Rows, { ASC, DESC, ColumnOrderItem } from './rows'

const COLUMN_QUERY = gql`
	query ConcatenatedTable($id: ID!) {
		concatenatedTable(id: $id) {
			id
			name
			includeFields {
				id
					field {
					id
					title
				}
			}
			includeTableFields {
				id
				tableField {
					id
					title
				}
			}
		}
	}
`

const updateOrderColumns = (index: number, currentOrderColumns: ColumnOrderItem[]) => {
	let next: ColumnOrderItem[]
	if (currentOrderColumns[0]?.columnIndex === index) {
		// is primary order : switch direction or reset
		const currDirection = currentOrderColumns[0]?.direction
		if (currDirection == ASC) {
			next = [
				{
					columnIndex: index,
					direction: DESC
				},
				...currentOrderColumns.filter(o => o.columnIndex !== index)
			]
		}
		else {
			next = []
		}
	}
	else {
		// make primary and remove if in order
		next = [
			{
				columnIndex: index,
				direction: ASC,
			},
			...currentOrderColumns.filter(o => o.columnIndex !== index)
		]
	}
	return next
}

const OrderIcon = ({ index, orderColumns }: {index: number, orderColumns: ColumnOrderItem[]}) => {
	const primary = orderColumns[0]?.columnIndex === index
	const direction = orderColumns.filter(o => o.columnIndex == index)[0]?.direction
	return (
		<div className={style.orderIcon}>
			{direction && <Icon
				type={direction == ASC ? "arrow-down" : "arrow-up"}
				style={primary ? {color: '#00b7ff'} : {}}
			/>}
		</div>
	)
}

type Props = {scrollContainerRef: RefObject<HTMLDivElement>} & RouteComponentProps<{id: string}>

const ConcatenatedTable = ({ match, scrollContainerRef}: Props) => {
	const globalContext = useGlobalContext()
	const [orderColumns, setOrderColumns] = useState<ColumnOrderItem[]>([])
	const [search, setSearch] = useState("")
	const debouncedSearch = useDebounce(search, 200)
	const {data: columnData, loading: mainLoading } = useQuery<ConcatenatedTableType>(COLUMN_QUERY, {variables: {id: match.params.id}})

	if (mainLoading) {
		return (
			<>
				<br/>
				{getLoadingCards(15)}
			</>
		)
	} else if (!columnData?.concatenatedTable) {
		return <div>error</div>
	}

	const columns = [
		{
			name: globalContext.currentModule.recordWord + " name",
			key: 'record_name',
		},
		...columnData.concatenatedTable.includeFields.map((f, i) => ({
			name: f.field.title,
			key: f.field.title,
		})),
		...columnData.concatenatedTable.includeTableFields.map((f, i) => ({
			name: f.tableField.title,
			key: f.tableField.title,
		})),
	]

	const columnStyle = columns.map(c => ({flexBasis: 100, flexGrow: 1}))

	return (
		<div className={style.container}>
			<div className={style.searchContainer}>
				<Icon type="search" />
				<input
					type="text"
					className={style.searchInput}
					placeholder="Search..."
					value={search}
					onChange={v => setSearch(v.target.value)}
				/>
			</div>
			<div className={style.headerRow}>
				{columns.map((c, i) => (
					<div
						key={c.key}
						className={style.cell}
						style={columnStyle[i]}
						onClick={() => {setOrderColumns(updateOrderColumns(i, orderColumns))}}
					>
						{c.name}
						<OrderIcon index={i} orderColumns={orderColumns} />
					</div>
				))}
			</div>
			<Rows
				concatenatedTableId={match.params.id}
				columnStyle={columnStyle}
				search={debouncedSearch}
				orderColumns={orderColumns}
			/>
		</div>
	)
}

export default ConcatenatedTable
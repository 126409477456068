import React from 'react'

import { SinglePanel } from 'components/common/panel'

export default function() {
  	return (
		<SinglePanel>
			<h1>Sorry!</h1>
			<h4>This link has either been deactivated, or does not exist.</h4>
		</SinglePanel>
  	)
}

import React, { useMemo } from 'react'

import {
	AdviceSection_adviceSection_List_objects_categories_subcategories as Subcategory,
	AdviceSection_adviceSection_List_objects as SectionType,
} from '../gql-types/AdviceSection'
import * as style from './index.module.sass'

type Props = {
	sections: SectionType[]
	currentSubcategoryId: string | null
}

const AdviceRightPanel = (props: Props) => {
	let match = null
	for(const section of props.sections) {
		for(const cat of section.categories) {
			if (!match) {
				match = cat.subcategories.find(sub => sub.id === props.currentSubcategoryId) || null
			}
		}
	}

	if (!match) return <div>Error: Category doesn't exist</div>

	return (
		<div className={style.container}>
			<div className={style.inner}>
				<h5>{match.title}</h5>
				<div dangerouslySetInnerHTML={{__html: match.content}} />
			</div>
		</div>
	)
}

export default AdviceRightPanel
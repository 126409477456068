import React from 'react'
import { Link } from 'react-router-dom'
import * as style from './index.module.sass'
import { formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'

type Props = {
    description: string
    recordId: string
    formId: string
}

const OPEN_TAG = '<FormLink:'
const CLOSE_TAG = '</FormLink>'


const Description = ({ description, recordId, formId }: Props) => {
    const globalContext = useGlobalContext()
    const reduceLink = (accumulator: React.ReactNode[], text: string) => {
        const matches = text.match(/^[0-9]+/g)
        const id = matches && matches[0]
        const split_text = text.split(CLOSE_TAG)
        const linkText = split_text[0].replace(/^[0-9]+./g, '')
    
        const nodes = [
            (split_text.length > 0 && id)
                ? (
                    <Link className="classic-anchor" to={formUrl({recordId, formId: id, ...globalContext})}>
                        {linkText}
                    </Link>
                )
                : null,
            split_text.length > 1
                ? <span dangerouslySetInnerHTML={{__html: split_text[1]}} />
                : null,
        ]
    
        return [...accumulator, ...nodes]
    }


    const linky_bits = description.split(OPEN_TAG)

    const start = linky_bits.length > 0 ? <span dangerouslySetInnerHTML={{__html: linky_bits.shift() || ""}} /> : null
    const html = linky_bits.reduce(reduceLink, [start])

    return (
        <div className={style.description}>
            {html.map((h, i) => <React.Fragment key={i}>{h}</React.Fragment>)}
        </div>
    )
}

export default Description
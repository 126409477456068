import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { signupUrl, getDjangoDomain } from 'utils/url'
import Footer from '../../common/footer'
import ScreenDemo from '../common/screen-demo'
import IconFeatureList from '../common/icon-feature-list'

import IMG_PLATFORMS from '../common/platforms.png'
import { STATIC_BASE } from 'utils/constants'

const LANDING = [
	{
		color: "color-questos-grey",
		images: [
			require('./screens/board-agenda-form.png'),
			require('./screens/board-minutes-form.png'),
			require('./screens/board-minutes-pdf.png'),
		],
		title: "Simplified Information Capture",
		text: "Relevant information fields designed to efficiently meet compliance obligations.",
	},
	{
		color: "color-questos-blue",
		images: [
			require('./screens/agm-agenda-pdf.png'),
			require('./screens/agm-notification-and-agenda-form.png'),
			require('./screens/agm-proxy-form.png'),
			require('./screens/agm-proxy-pdf.png'),
		],
		title: "Centralised Document Storage",
		text: "Key information consolidated and easily accessible - protected for retrieval over several years.",
	},
	{
		color: "color-questos-grey",
		images: [
			require('./screens/resolution-register-form.png'),
			require('./screens/resolution-register-pdf.png'),
		],
		title: "Minimise Potential Compliance Breaches",
		text: "Minimise penalties and fines with key date tracking and workflow tools.",
	},
	{
		color: "color-questos-blue",
		images: [
			require('./screens/consent-to-act-as-director-pdf.png'),
			require('./screens/company-details-form.png'),
			require('./screens/company-secretary-details-form.png'),
		],
		title: "Prevent Loss of Your Statutory Records",
		text: "Professional and comprehensive responses to regulator queries with historical information at your fingertips.",
	},
	{
		color: "color-questos-grey",
		images: [
			require('./screens/dashboard.png')
		],
		title: "Automated Dashboard Reporting",
		text: "Simple and insightful presentation of key compliance performance indicators.",
	},
]

const ICONS =  {
	show: [
		{
			'title': 'REGISTER OF DIRECTORS',
			'icon': 'icon-managers',
			'text': 'Capture and easily maintain current information'
		},
		{
			'title': 'SHAREHOLDER INFORMATION',
			'icon': 'icon-info',
			'text': 'Centralised storage and reporting'
		},
		// {
		// 	'title': 'CHARGES REGISTER',
		// 	'icon': 'icon-dollar',
		// 	'text': 'Record and easily track charges'
		// },
		// {
		// 	'title': 'DEBENTURES REGISTER',
		// 	'icon': 'icon-money',
		// 	'text': 'Detailed historical and current position'
		// },
		// {
		// 	'title': 'OPTIONS REGISTER',
		// 	'icon': 'icon-doc-2',
		// 	'text': 'Detailed recording, tracking and reporting'
		// },
		{
			'title': 'ALLOTMENT JOURNAL',
			'icon': 'icon-graph',
			'text': 'Accurately track allocations'
		},
		{
			'title': 'AUDIT TRAIL',
			'icon': 'icon-history',
			'text': 'A permanent audit trail of changes to company records'
		},
		{
			'title': 'EXTERNAL LINK',
			'icon': 'icon-external',
			'text': 'Increased productivity and cost savings through ‘outsourced’ data collection'
		},
		{
			'title': 'SECURE INFORMATION MANAGEMENT',
			'icon': 'icon-lock',
			'text': 'Hosted on the security of Amazon Web Services (AWS) '
		},
	],
	hide: [
	]
}

export default function Landing() {
	return (
		<div className="landing questos-landing">
			<div className="color-questos-blue top-content">
				<div className="main-text">Company regulatory <br/>obligations in one place</div>
				<a href={getDjangoDomain() + signupUrl()}><button className="get-started-button">Get Started For Free</button></a>
			</div>

			{ LANDING.map((demo, i) => {
				return <ScreenDemo key={i} {...demo}/>
			})}

			<IconFeatureList showItems={ICONS.show} hideItems={ICONS.hide} />

			<div className="color-questos-blue platforms">
				<div className="text">
					Secure, anytime, anywhere
				</div>
				<img className="image" src={STATIC_BASE + IMG_PLATFORMS} />
			</div>
			<Footer />
		</div>
	)
}

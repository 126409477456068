import { getCookie } from 'utils/functions'

export const ROOT_URL = '/api/v1/'


export function getCsrfToken() {
  return getCookie('csrftoken')
}

const getQueryParamString = (object) => {
	const filterdObject = _.pickBy(object, (v, k) => ((v !== undefined) && (v!== null)))
	var str = filterdObject
		? Object.keys(filterdObject).map(k => `${k}=${filterdObject[k]}`).join("&") 
		: ""

	str = str.length > 0 
		? "?" + str 
		: ""
	return str
}

class Resource {
	get(id, params) {
		const queryParams = getQueryParamString(params)
		return fetch(ROOT_URL + `${this.name}/${id}${queryParams}`, {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
	}

	query(params) {
		return fetch(ROOT_URL + `${this.name}${getQueryParamString(params)}`, {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
	}

	create(data, params) {
		return fetch(ROOT_URL + `${this.name}${getQueryParamString(params)}`, {
			method: 'POST',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			}),
			body: JSON.stringify(data)
		})
	}

	save(obj, params) {
		return fetch(ROOT_URL + `${this.name}/${obj.id}${getQueryParamString(params)}`, {
			method: 'PATCH',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			}),
			body: JSON.stringify(obj)
		})
	}

	saveFormData(obj, file) {
		var data = new FormData()

		Object.keys(obj).forEach(k => {
			if(obj[k] != undefined) {
				data.append(k, obj[k])
			}
		})
		// data.append("data", JSON.stringify(obj))
		
		if(file) {
			data.append("file", file)
		}

		return fetch(ROOT_URL + `${this.name}/${obj.id}`, {
			method: 'PUT',
			credentials: 'include',
			headers: new Headers({
				'X-CSRFToken': getCsrfToken()
			}),
			body: data
		})
	}

	createFormData(obj, file) {
		var data = new FormData()

		Object.keys(obj).forEach(k => {
			if(obj[k] != undefined) {
				data.append(k, obj[k])
			}
		})	
		
		if(file) {
			data.append("file", file)
		}

		return fetch(ROOT_URL + `${this.name}`, {
			method: 'POST',
			credentials: 'include',
			headers: new Headers({
				'X-CSRFToken': getCsrfToken()
			}),
			body: data
		})
	}

	delete(id) {
		return fetch(ROOT_URL + `${this.name}/${id}`, {
			method: 'DELETE',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			})
		})
	}

	detailAction(detailName, id, data, method) {
		if (!method) {
			method = 'POST'
		}
		const queryStringParams = method == 'get' ? getQueryParamString(data) : ''

		return fetch(ROOT_URL + `${this.name}/${id}/${detailName}${queryStringParams}`, {
			method: method,
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			}),
			body: JSON.stringify(data)
		})

	}
}


const standardResource = (name) => {
	return class _ extends Resource {
		constructor() {
			super()
			this.name = name
		}
	}
}


export class Form extends Resource {
	constructor() {
		super()
		this.name = 'forms'
	}
	reset({formId, recordId}) {
		return fetch(ROOT_URL + `forms/${formId}/reset?record_id=${recordId}`, {
			method: 'post',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken(),
			}),
		})
	}
}


export const Folder  = standardResource('folders')
export const File  = standardResource('files')
export const FormFileLink  = standardResource('form_file_links')
export const FormFolderLink  = standardResource('form_folder_links')
export const Record  = standardResource('records')
export const ActiveExternalLink  = standardResource('public_form_analytics')
export const Event  = standardResource('events')
export const TabularRecordMetric  = standardResource('tabular_record_metric')

export const FieldNotification  = standardResource('field_notifications')


export class Csv extends Resource {
	getAvailableData(queryString) {
		return fetch(ROOT_URL + 'csv_available_data' + queryString, {
			method: 'get',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
		  	})
		})
	}
}


export class PublicForm extends Resource {
	constructor() {
		super()
		this.name = 'public_forms'
	}

	getData(code) {
		return fetch(ROOT_URL + `get_public_form/${code}`, {
			method: 'get',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
			})
		})
	}

	send(data) {
		return fetch(ROOT_URL + 'send_public_form/', {
			method: 'post',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken(),
			}),
			body: JSON.stringify(data)
		})
	}
}

export class Answer extends Resource {
	bulkSave({ answers, record, form }, code) {
		var data = new FormData()

		answers.forEach(a => {
			if(a.content && a.content.size) {
				data.append(a.id || a.tempId, a.content)
			}
		})

		const saveData = {
			record,
			form,
			answers: answers.map(a => {
				const newA = {...a}
				if(a.content && a.content.size) {
					delete newA.content
				}
				return newA
			})
		}

		data.append("data", JSON.stringify(saveData))
		
		return fetch(ROOT_URL + 'bulk_answer' + (code ? '?code=' +code : ""), {
			method: 'post',
			credentials: 'include',
			headers: new Headers({
				'X-CSRFToken': getCsrfToken()
			}),
			body: data
		})
	}

	static getFileUrl(answer, {code}) {
		const params = getQueryParamString({modified: answer.modified, code})
		return ROOT_URL + `answer_files/${answer.id}/get_file${params}`
	}
}


import React, { useState } from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import { Formik, Field, FormikProps } from 'formik'
import { TextField, ImageField, SelectField } from 'components/common/formik'
import { SpinnerButton } from 'components/common/button'
import { Options } from './gql-types/Options'
import { Obj } from 'types/utils'
import { GlobalContextProps, useGlobalContext } from 'components/context'
import { MutateRecord, MutateRecordVariables } from './gql-types/MutateRecord'


type RecordOption = Obj<Options["recordOptions"]>
type TemplateOption = Obj<Options["templateOptions"]>

export type Values = {
	id: string | null
	position: string
	template: TemplateOption | null
	copyRecord?: RecordOption | null
	team: { id: string } | null
	name: string
	image: string | File | null
}

type Props = {
	initialValues: Values
	dedicatedCopyUrl?: boolean
	recordOptions: RecordOption[]
	templateOptions: TemplateOption[]
	onSubmit: (variables: MutateRecordVariables) => Promise<any>
}

const getValidationSchema = () => {
	const ERROR_MESSAGE = 'This field cannot be blank'
	const idObj = Yup.object().shape({id: Yup.string()})

	return Yup.object().shape({
		id: Yup.string().nullable(true),
		name: Yup.string(),
		position: Yup.string(),
		image: Yup.mixed(),
		formSet: idObj.typeError(ERROR_MESSAGE),
		template: idObj.nullable(false).typeError(ERROR_MESSAGE),
		team: idObj.typeError(ERROR_MESSAGE),
	})
}

const EditForm: React.FC<Props> = ({ initialValues, templateOptions, recordOptions, onSubmit, dedicatedCopyUrl}) => {
	const { currentTeam, currentModule, organisation } = useGlobalContext()
	const [copyFromRecord, setcopyFromRecord] = useState(!!initialValues.copyRecord)
	const [customisePositionWord, setCustomisePositionWord] = useState(
		initialValues.template && initialValues.id
		&& (initialValues.position != initialValues.template.position)
	)
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={getValidationSchema()}
			onSubmit={(values, actions) => {
				const ids = _.pick(values, ['formSet', 'template', 'team', 'copyRecord'])
				const aIds  = _.mapValues(ids, 'id') as {
					formSet: string
					template: string
					team: string
				}
				
				const saveData: MutateRecordVariables = {
					..._.pick(values, ['id', 'position', 'name', 'image']),
					...aIds
				}
				onSubmit(saveData).then(r => {
					// actions.resetForm()
				}).catch((r: Error) => {
					console.error(r)
					actions.setFieldError('all', 'There was a problem saving data')
				}).finally(() => {
					actions.setSubmitting(false)
				})
			}}
		>
			{({ values, errors, isSubmitting, handleSubmit, setFieldValue }: FormikProps<Values>) => {
				const namePlaceholder = (
					(values.template && values.template.namePlaceholder)
					|| (values.copyRecord && values.copyRecord.template.namePlaceholder)
				)
				return (
					<form onSubmit={handleSubmit} className="common-form">
						{ ! copyFromRecord &&
							<Field
								name="template"
								placeholder={`Select ${currentModule.positionWord.toLowerCase()}...`}
								component={SelectField}
								isDisabled={
									(initialValues.id && !currentModule.allowRecordTemplateChange)
									|| (templateOptions.length == 1 && values.template == templateOptions[0])
								}
								options={templateOptions}
								onChange={(t: TemplateOption) => {
									if (t.copyPositionText) {
										setCustomisePositionWord(false)
										setFieldValue("position", t.position)
									} else {
										setCustomisePositionWord(true)
										setFieldValue("position", '')
									}
								}}
								formatOptionLabel={(r: TemplateOption) => (
									<React.Fragment>
										<b>{r.position}</b>
									</React.Fragment>
								)}
								getOptionValue={(o: TemplateOption) => o.id}
								maxMenuHeight={220}
							/>
						}
						
						{ copyFromRecord &&
							<Field
								name="copyRecord"
								placeholder={`Copy ${currentModule.recordWord.toLowerCase()}...`}
								component={SelectField}
								isDisabled={
									(initialValues.id && !currentModule.allowRecordTemplateChange) ||
									(dedicatedCopyUrl && values.template?.id && values.copyRecord?.template.id === values.template.id)
								}
								options={recordOptions}
								onChange={(r: RecordOption) => {
									setFieldValue("position", r.position)
									setFieldValue("template", r.template)
								}}
								formatOptionLabel={(r: RecordOption) => (
									<React.Fragment>
										<b>{r.position}</b>
										{r.position && r.name && ' - '}
										{r.name}
									</React.Fragment>
								)}
								getOptionValue={(r: RecordOption) => r.id}
								maxMenuHeight={220}
							/>
						}
						{ recordOptions.length > 0
							&& currentModule.allowRecordTemplateChange
							&& !dedicatedCopyUrl &&
							<button
								className="edit-form-small-button button-small button-grey button-no-outline"
								onClick={() => {
									setcopyFromRecord(!copyFromRecord)
									setFieldValue("template", null)
									setFieldValue("copyRecord", null)
								}}
								type="button"
							>
								{values.id
									? copyFromRecord
										? `Change ${currentModule.positionWord.toLowerCase()}`
										: `Change ${currentModule.positionWord.toLowerCase()}
											to existing ${currentModule.recordWord.toLowerCase()}`
									: copyFromRecord 
										? `Or create from ${currentModule.positionWord.toLowerCase()} template`
										: `Or copy from existing ${currentModule.recordWord.toLowerCase()}`
								}
							</button>
						}

						<Field
							name="name"
							placeholder={namePlaceholder || "Name"}
							component={TextField}
						/>

						{ customisePositionWord && 
							<Field
								name="position"
								placeholder={currentModule.positionWord}
								component={TextField}
							/>
						}

						{ currentModule.allowPositionTextChange &&
							(values.template || values.copyRecord) &&
							<button
								className="edit-form-small-button button-small button-grey button-no-outline"
								onClick={() => {
									setCustomisePositionWord(!customisePositionWord)
									setFieldValue(
										"position",
										(values.template && values.template.position) || (values.copyRecord && values.copyRecord.position),
									)
								}}
								type="button"
							>
								{ customisePositionWord
									? `Use default ${currentModule.positionWord.toLowerCase()} title`
									: `Customise ${currentModule.positionWord.toLowerCase()} title`
								}
							</button>
						}
						
						{ (currentModule.teams.length > 1 || !values.team) &&
							<Field
								placeholder={currentModule.teamWord}
								name="team"
								component={SelectField}
								options={currentModule.teams}
								formatOptionLabel={(t: NonNullable<GlobalContextProps['currentTeam']>) => <span>
									<b>{t.name}</b>
								</span>}
								getOptionValue={(t: NonNullable<GlobalContextProps['currentTeam']>) => t.id}
								maxMenuHeight={160}
							/>
						}
						{ currentModule.recordImages &&
							<Field
								title="Image"
								name="image"
								component={ImageField}
							/>
						}
						
						{/* 
						//@ts-ignore */}
						<div className="error">{errors.all}</div>
						<SpinnerButton loading={isSubmitting} type="submit">
							{values.id ? 'Save' : 'Create'}
						</SpinnerButton>
					</form>
				)
			}}
		</Formik>
	)
}
export default EditForm
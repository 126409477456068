import _ from 'lodash'
import { combineReducers } from 'redux'
import { canDeleteAnswerWithoutUndo } from 'utils/functions'


function form(state = {}, action) {
	if(action.type=="GOT_FORM") {
		return {...action.form}
	}
	else if(action.type == 'FORM_LOADING') {
		return {}
	}
	return state
}

function fields(state = {}, action) {
	if(action.type=="GOT_FORM") {
		const newState = {}
		Object.keys(action.fields).map(fieldId => {
			newState[fieldId] = {
				...action.fields[fieldId],
			}
		})
		return newState

	}
	else if(action.type == "ADD_ANSWER") {
		return {
			...state,
			[action.answer.field]: {
				...state[action.answer.field],
				createdAnswers: [...state[action.answer.field].createdAnswers, action.answer.tempId]
			}
		}
	}
	else if(action.type == "SAVED_ANSWERS") {
		const newState = {}
		Object.keys(state).map(fieldId => {
			newState[fieldId] = {...state[fieldId]}
			if(newState[fieldId].createdAnswers.length > 0) {
				newState[fieldId].createdAnswers = []
				const newSavedAnswers = [...action.answers.filter(returnedAnswer => {
					return returnedAnswer.field == fieldId && !newState[fieldId].answers.find(
						oldAnswerId => oldAnswerId === returnedAnswer.id
					)
				})]
				newState[fieldId].answers.push(...newSavedAnswers.map(a => a.id))
			}
		})
		return newState
	}
	return state
}

function fieldNotifications(state = {}, action) {
	if(action.type == 'GOT_FIELD_NOTIFICATIONS') {
		const newState = {}
		action.notifications.forEach(n => newState[n.id] = n)
		return {
			...state,
			...newState,
		}
	}
	else if(action.type == 'CREATED_FIELD_NOTIFICATION') {
		return {
			...state,
			[action.notification.id]: action.notification
		}
	}
	else if(action.type == 'DELETED_FIELD_NOTIFICATION') {
		const newState = {...state}
		delete newState[action.notification.id]
		return {
			...newState,
		}
	}
	return state
}

function helpInfoField(state = {}, action) {
	if(action.type == 'SET_HELP_INFO') {
		return {
			...action.helpInfoField
		}
	}
	return state
}


export default combineReducers({
	form,
	fields,
	fieldNotifications,
	helpInfoField,
})
import React from 'react'
import c from 'classnames'
import * as styles from './index.module.sass'

type Props = {
	features: {
		title: string
		subtitle: string
		items: {
			title: string
			body: string
			icon: string
		}[]
	}[]
}

const LargeFeatureList = ({features}: Props) => (
	<div className={styles.outerContainer}>
		{features.map((f, i) => (
			<div className={styles.container}>
				<div className={styles.title}>{f.title}</div>
				<div className={styles.subtitle}>{f.subtitle}</div>
				{f.items.map(i => (
					<div key={i.title} className={styles.itemContainer}>
						<div className={c(styles.icon, "icon-" + i.icon)}/>
						<div className={styles.textContainer}>
							<div className={styles.itemTitle}>{i.title}</div>
							<div className={styles.itemBody}>{i.body}</div>
						</div>
					</div>
				))}
			</div>
		))}
	</div>
)

export default LargeFeatureList
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { signupUrl, loginUrl, teamMetricsDefaultUrl, landingPageUrl, getDjangoDomain, getPublicSiteDomain } from 'utils/url'
import BASTION from './icons/bastion.svg'
import { useGlobalContext } from 'components/context'
import { SITE_NAME, IS_INCIDENT_WATCH, STATIC_BASE } from 'utils/constants'
import { NavProps } from '../common'
import * as styles from './index.module.sass'


const LOGOS = {
	BASTION,
	PHAROS: null,
	QUESTOS: null,
	SCHOOL: null,
	INCIDENT: null,
}

const scrollToId = (id: string) => {
	const el = document.getElementById(id)
	el && el.scrollIntoView()
}

export const VIDEO_SCROLL_ID = 'video'
export const PRICING_SCROLL_ID = 'pricing'

const Nav = (props: NavProps) => {
	return (
		<div
			className={styles.container}
			style={props.printableDashboard ? {display: 'none', height: 0} : {}}
		>
			<div className={styles.navGroup}>
				<a href={getPublicSiteDomain()}>
					<div className={styles.navLink}>
						<img className={styles.img} src={STATIC_BASE + LOGOS[SITE_NAME]}/>
					</div>
				</a>
			</div>
			<div className={styles.navGroup}>
				<Link to={landingPageUrl()} onClick={() => scrollToId("video")}><div className={styles.navLink}>Video</div></Link>
				<Link to={landingPageUrl()} onClick={() => scrollToId("pricing")}><div className={styles.navLink}>Pricing</div></Link>
				<a href="https://bastionsystems.blogspot.com" target="_blank"><div className={styles.navLink}>Blog</div></a>
				<a href={getDjangoDomain() + signupUrl()}><div className={styles.navLink}>Signup</div></a>
				<a href={getDjangoDomain() + loginUrl()}><div className={styles.navLink}>Login</div></a>
			</div>
		</div>
	)
}

export default Nav

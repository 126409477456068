// Note: These are global variables that are set by Django
// They are defined, renamed and rexported in ./constants so
// they play well with typescript
import { SiteNameType } from 'types/common'
declare const SITE_NAME: SiteNameType
declare const IS_INCIDENT_WATCH: boolean
declare const STATIC_BASE: string
declare const GOOGLE_ANALYTICS_ID: string

export const DJANGO_SITE_NAME = SITE_NAME
export const DJANGO_IS_INCIDENT_WATCH = IS_INCIDENT_WATCH
export const DJANGO_STATIC_BASE = STATIC_BASE
export const DJANGO_GOOGLE_ANALYTICS_ID = GOOGLE_ANALYTICS_ID
import history from 'utils/history'
import { Form, PublicForm, FieldNotification } from 'resources/resource'
import { showErrorModal } from './modal'
import { publicFormInvalidUrl } from 'utils/url'
import { getInitialAnswers } from '../utils'


export function gotForm(form, recordId) {
	const answers = {}
	const fields = {}
	let allInitialAnswers = {}
	const fieldIds = []
	form.fields.forEach(field => {
		const answerIds = []

		fieldIds.push(field.id)
		fields[field.id] = field

		field.answers.saved.forEach(answer => {
			answers[answer.id] = answer
			answerIds.push(answer.id)
		})

		// only take initial answers if there are NO answers for the field
		const initialAnswers = answerIds.length === 0 ? getInitialAnswers(field, recordId) : {}

		allInitialAnswers = { ...allInitialAnswers, ...initialAnswers }
		field.createdAnswers = Object.entries(initialAnswers).map(([_, ia]) => (ia.tempId))
		field.answers = answerIds
	})
	form.fields = fieldIds

	return {
		type: 'GOT_FORM',
		form,
		fields,
		initialAnswers: allInitialAnswers,
		answers,
		recordId,
	}
}

export function formLoading(loading) {
	return {
		type: 'FORM_LOADING',
		loading
	}
}

export function gotPublicForm(publicForm) {
	return {
		type: 'GOT_PUBLIC_FORM',
		publicForm
	}
}

export function sendPublicForm(email, code) {
	return dispatch => {
		return new PublicForm().send({email, code})
	}
}

export function getForm({recordId, formId}) {
	return dispatch => {
		dispatch(formLoading(true))
		return new Form().get(formId, { 'record_id': recordId }).then(response => {
			response.json().then(responseForm => {
				dispatch(gotForm(responseForm, recordId))
			})
		})
	}
}

export function resetForm({formId, recordId}) {
	return dispatch => {
		dispatch(formLoading(true))
		new Form().reset({formId, recordId}).then(response => {
			if(response.status!=200) {
				dispatch(showErrorModal())
			}
			dispatch(getForm({formId, recordId}))
		})
	}
}

export function getPublicFormData(code) {
	return dispatch => {
		dispatch(formLoading(true))
		new PublicForm().getData(code).then(response => {
			if(response.status == 406) {
				history.push(publicFormInvalidUrl())
			}
			else {
				response.json().then(data => {
					dispatch(gotForm(data.form, data.record.id))
				})
			}
		})
	}
}

export function createdFieldNotification(notification) {
	return {
		type: 'CREATED_FIELD_NOTIFICATION',
		notification,
	}
}

export function deletedFieldNotification(notification) {
	return {
		type: 'DELETED_FIELD_NOTIFICATION',
		notification
	}
}

export function saveFieldNotification(n) {
	return dispatch => {
		return new FieldNotification().create(n).then(response => {
			return response.json().then(notification => {
				dispatch(createdFieldNotification(notification))
			})
		})
	}
}

export function deleteFieldNotification(n) {
	return dispatch => {
		return new FieldNotification().delete(n.id).then(r => {
			dispatch(deletedFieldNotification(n))
		})
	}
}

function gotFieldNotifications(notifications) {
	return {
		type: "GOT_FIELD_NOTIFICATIONS",
		notifications
	}
}

export function getFieldNotifications({field_id, field_content_type}) {
	return dispatch => {
		return new FieldNotification().query({field_id, field_content_type}).then(response => {
			return response.json().then(response => {
				dispatch(gotFieldNotifications(response))
			})
		})
	}
}

export function setHelpInfo(helpInfoField) {
	return {
		type: 'SET_HELP_INFO',
		helpInfoField,
	}
}
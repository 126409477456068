import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import NavBar from 'components/common/public-navbar'
import RouteNotFound from 'components/common/route-not-found'
import { SiteNameType } from 'types/common'
import { WrappedRouteNotFound } from 'components/common/route-not-found'
import { contactUrl, termsUrl, loginUrl, getDjangoDomain} from 'utils/url'
import TermsPharos from './terms/pharos'
import LandingPharos from './landing/pharos'
import LandingQuestos from './landing/questos'
import LandingSchool from './landing/school'
import LandingBastion from './landing/bastion'
import Contact from './contact'
import { SITE_NAME } from 'utils/constants'

const RedirectToLogin = () => {
	window.location.href = getDjangoDomain() + loginUrl();
	return null
}

const PAGES = {
	"PHAROS" : {
		'landing': LandingPharos,
		'terms': TermsPharos,
	},
	"QUESTOS" : {
		'landing': LandingQuestos,
		'terms': TermsPharos,
	},
	"SCHOOL" : {
		'landing': LandingSchool,
		'terms': TermsPharos,
	},
	"BASTION" : {
		'landing': LandingBastion,
		'terms': TermsPharos,
	},
	"INCIDENT" : {
		'landing': RedirectToLogin,
		'terms': RedirectToLogin,
	}
}

export default () => {
	return (
		<>
			<NavBar />
			<div className="navbar-page">
				<Switch>
					<Route path="/" exact component={PAGES[SITE_NAME]['landing']} />
					<Route path={contactUrl()} component={Contact} />
					<Route path={termsUrl()} component={PAGES[SITE_NAME]['terms']} />
					<Route path="*" component={WrappedRouteNotFound} />
				</Switch>
			</div>
		</>
	)
}
import { combineReducers } from 'redux'

import analytics from './reducers/analytics'
import answers from './reducers/answer'
import file from './reducers/file'
import record from './reducers/record'
import general from './reducers/general'
import loading from './reducers/loading'
import mainForm from './reducers/mainForm'
import publicOrganisations from './reducers/publicOrganisations'
import userInterface from './reducers/userInterface'
import modal from './reducers/modal'

const appReducer = combineReducers({
	analytics,
	answers,
	file,
	general,
	loading,
	mainForm,
	modal,
	publicOrganisations,
	record,
	userInterface,
})

const rootReducer = (state, action) => {
	return appReducer(state, action)
}

export default rootReducer
import React, { Component } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { RouteComponentProps } from 'react-router-dom'
import gql from "graphql-tag"

import { useRouter } from 'components/context'
import Placeholder from 'components/common/placeholder'
import EditUserForm from './form'
import UserTeamEdit from './user-team-edit'
import * as style from './index.module.sass'
import { getUser as QueryType, getUserVariables as QueryVariables } from './gql-types/getUser'
import DeleteUserButton from './delete-button'


export const GET_USER = gql`
	query getUser($id: ID!) {
		user(id: $id) {
			id
			firstName
			lastName
			email
			topLevelManager
			teams {
				id
				name
			}
		}
	}
`

type Props = RouteComponentProps<{userId: string}>

export default (props: Props) => {
	const { data, error, loading } = useQuery<QueryType, QueryVariables>(
		GET_USER,
		{ variables: {id: props.match.params.userId} }
	)

	return (
		<div className={style.container}>
			{loading
				? (
					<>
						<Placeholder />
						<Placeholder style={{height: 230}} />
						<Placeholder />
						<Placeholder style={{height: 200}} />
					</>
				)
				: (!data || error)
					? <div>Error</div>
					: (
						<>
							<div className={style.titleRow}>
								<h3>User details</h3>
								<DeleteUserButton user={data.user} />
							</div>
							<EditUserForm user={data.user} />
							<UserTeamEdit user={data.user} />
						</>
					)
			}
		</div>
	)
}
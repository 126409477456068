import { combineReducers } from 'redux'
import { FILE_CONTENT_TYPES } from 'utils/constants'


const INITIAL_STATE = {}
FILE_CONTENT_TYPES.forEach(c => INITIAL_STATE[c] = {})


const updateFileOrFolder = (folder, updated, isFile, created) => {
	if(updated.parent_folder == folder.id) {
		const oldSet = folder[isFile ? 'files' : 'folders']
		let newSet = (created ? 
			[...oldSet, updated] 
			: oldSet.map(f => f.id==updated.id ? updated : f).filter(f => f.active)
		)

		return {
			...folder,
			[isFile ? 'files' : 'folders']: newSet
		}
	}
	return {
		...folder,
		folders: folder.folders.map(f => updateFileOrFolder(f, updated, isFile, created))
	}
}


function folders(state = INITIAL_STATE, action) {
	if(action.type == "GOT_FOLDERS") {
		return { 
			...state,
			[action.content_type]: {
				[action.object_id]: {
					id: null,
					folders : [...action.folders],
					files : (state[action.content_type][action.object_id] || {})['files'],
				}
			}
		}
	}
	if(action.type == "GOT_FILES") {
		return {
			...state,
			[action.content_type]: {
				[action.object_id]: {
					id: null,
					folders : (state[action.content_type][action.object_id] || {})['folders'],
					files : [...action.files],
				}
			}
		}
	}
	else if(action.type == 'UPDATED_FILE' || action.type == 'UPDATED_FOLDER' || action.type == "CREATED_FILE" || action.type == 'CREATED_FOLDER') {
		const created = action.type == "CREATED_FILE" || action.type == 'CREATED_FOLDER'
		const isFile = !!action.file
		const updatedObj = isFile ? action.file : action.folder
		const rootFolder = state[updatedObj.content_type][updatedObj.object_id]
		const newRootFolder = updateFileOrFolder(rootFolder, updatedObj, isFile, created)
		return {
			...state,
			[updatedObj.content_type]: {
				[updatedObj.object_id]: newRootFolder
			}
		}
	}

	return state
}


function formLinks(state = {}, action) {
	if(action.type == "GOT_FORM_FOLDER_LINKS") {
		return {
			...state,
			[action.form]: {
				formFileLinks: (state[action.form] && state[action.form].formFileLinks) || [],
				formFolderLinks: action.formFolderLinks,
			}
		}
	}
	else if(action.type == "GOT_FORM_FILE_LINKS") {
		return {
			...state,
			[action.form]: {
				formFolderLinks: (state[action.form] && state[action.form].formFolderLinks) || [],
				formFileLinks: action.formFileLinks,
			}
		}
	}
	else if(action.type =="REMOVED_FORM_LINK") {
		let formFolderLinks = [...state[action.formLink.form].formFolderLinks]
		let formFileLinks = [...state[action.formLink.form].formFileLinks]

		if(action.formLink.file) {
			formFileLinks = formFileLinks.filter(f => f.id != action.formLink.id)
		}
		else {
			formFolderLinks = formFolderLinks.filter(f => f.id != action.formLink.id)
		}

		return {
			...state,
			[action.formLink.form]: {
				formFolderLinks,
				formFileLinks,
			}
		}
	}

	else if(action.type == 'CREATED_FORM_LINK') {
		return {
			...state,
			[action.formLink.form]: {
				formFolderLinks: [...state[action.formLink.form].formFolderLinks, ...(action.formLink.folder ? [action.formLink] : [])],
				formFileLinks: [...state[action.formLink.form].formFileLinks, ...(action.formLink.file ? [action.formLink] : [])],
			}
		}
	}
	return state
}


export default combineReducers({
	folders,
	formLinks,
})


import React, { Component } from 'react'
import { Query, Mutation } from "react-apollo"
import gql from "graphql-tag"

import { Spinner } from 'components/common/loading'
import history from 'utils/history'
import { userEssentials } from 'fragments'
import { apolloClient } from 'state/apollo-client'
import { withRouter } from 'react-router-dom'
import { teamUrl, teamMetricsDefaultUrl } from 'utils/url'


const SWAP_ORG = gql`
	mutation swapOrg($orgId: ID!, $teamId: ID) {
		user_SwapOrgAdmin(organisation: $orgId, team: $teamId) {
			user {
				...UserEssentials
			}
		}
	}
	${userEssentials}
`

class Switch extends Component {
	swap = (swapOrg) => {
		let variables = {}
		if(this.props.orgId) {
			variables.orgId = this.props.orgId
		}
		if(this.props.teamId) {
			variables.teamId = this.props.teamId
		}
		swapOrg({variables})
	}
	onCompleted = (data) => {
		apolloClient.resetStore()
		history.push(teamMetricsDefaultUrl({
			organisation: data.user_SwapOrgAdmin.user.organisation
		}))
	}
	render() {
		return (
			<Mutation mutation={SWAP_ORG} onCompleted={this.onCompleted}>
				{(swapOrg, { loading, error }) => {
					if(loading) return <Spinner />
					return (
						<button onClick={() => this.swap(swapOrg)}>
							Switch
						</button>
					)
				}}
			</Mutation>
						
		)
	}
}

export default withRouter(Switch)
export function getPersistentValue(name) {
	return localStorage.getItem(name)
}

export function setPersistentValue(name, value) {
	return localStorage.setItem(name, value)
}

export function getCookie(name) {
  var value = "; " + document.cookie
  var parts = value.split("; " + name + "=")
  if (parts.length == 2) return parts.pop().split(";").shift()
}

export function hasExtension(fileName, exts) {
    fileName = fileName.toLowerCase()
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName)
}
import React from 'react'
import * as style from './index.module.sass'

type Props = {
	style?: React.CSSProperties
}

export default (props: Props) => {
	return (
		<div className={style.placeholder} style={props.style}>
		</div>
	)
}
import React, { Component } from 'react'
import { Mutation } from "react-apollo"
import * as Yup from 'yup'
import { Formik, Field, FormikProps } from 'formik'
import gql from "graphql-tag"
import { apolloClient } from 'state/apollo-client'
import { TextField } from 'components/common/formik'
import { SpinnerButton } from 'components/common/button'
import { LoginUser, LoginUserVariables } from './gql-types/LoginUser'
import { useMutation } from '@apollo/react-hooks'
import * as style from '../common.module.sass'

const UPDATE_USER = gql`
	mutation LoginUser(
		$email: String!,
		$password: String!
	) {
		user_Login(
			email: $email,
			password: $password
		) {
			user {
				id
			}
		}
	}
`
type FormValues = {
	email: string
	password: string
}


const LogIn = () => {
	const [loginUser, {loading}] = useMutation<LoginUser, LoginUserVariables>(UPDATE_USER)	
	return (
		<div className="page-form-container">
			<div className="page-form">
				<Formik
					initialValues={{email: '', password: ''}}
					validationSchema={Yup.object().shape({
						email: Yup.string().email().required('Required'),
						password: Yup.string().required('Required'),
					})}
					onSubmit={ async(values, actions) => {
						const r = await loginUser({variables: values})
						if(r && r.data!.user_Login!.user) {
							apolloClient.resetStore()
						}
						else {
							actions.setSubmitting(false)
							actions.setStatus('Email or password incorrect')
						}
					}}
				>
					{(props: FormikProps<FormValues>) => {
						const { status, isSubmitting ,handleSubmit } = props
						return (
							<form onSubmit={handleSubmit} className="common-form">
								<h3 className="common-form-title">Log in</h3>

								<Field
									name="email"
									placeholder="Email"
									className={style.input}
									component={TextField}
								/>
								<Field
									name="password"
									placeholder="Password"
									className={style.input}
									component={TextField}
									type="password"
								/>

								<div className="error">{status}</div>
								<SpinnerButton loading={isSubmitting} type="submit">
									Login
								</SpinnerButton>
								<a href="/stock_auth/password_reset/">
									<button
										type="button"
										className="button-no-outline button-small button-grey"
									>
										Reset Password
									</button>
								</a>
							</form>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}

export default LogIn

import React from 'react'
import DarkNav from './dark'
import LightNav from './light'
import { SITE_NAME } from 'utils/constants'
import { SiteNameType } from 'types/common'
import { NavProps } from './common'

type T = Record<SiteNameType, React.FC<NavProps>>

const THEME: T = {
	'PHAROS' : DarkNav,
	'QUESTOS' : DarkNav,
	'SCHOOL' : DarkNav,
	'INCIDENT' : DarkNav,
	'BASTION' : LightNav,
}

const Navbar = (props: NavProps) => {
	const Nav = THEME[SITE_NAME]

	return <Nav {...props} />
}

export default Navbar
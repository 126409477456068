import React, { Component } from 'react'
import { Query, Mutation } from "react-apollo"
import gql from "graphql-tag"
import { Link } from 'react-router-dom'
import { Record } from './gql-types/Record'
import { wrapWithContext, GlobalContextProps } from 'components/context'


type Props = {
    record: Record["record"]
    refreshRecordList: () => void
} & GlobalContextProps


export default wrapWithContext(({ record, refreshRecordList, currentModule }: Props) => (
    <div className="profile-container">
        { record.image && 
            <div
                className="picture"
                style={{ backgroundImage: `url(${record.image})` }}
            />
        }
        <div className="name-container">
            <div className="name">{record.name}</div>
            <div className="position">
                {record.position}
                {/*record.position != record.template!.position &&
                    <span style={{opacity: 0.5}}> - {record.template!.position}</span>
                */}
            </div>
        </div>
    </div>
))
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Footer from '../common/footer'

export default () => (
	<div>
		<div className="contact-container">
			<div className="contact">
				<div className="terms-container">
					<h3 className="terms-title">Terms of Service - Subscription Service</h3>

					<p className="terms-larger">This is the full version of the Terms of Service of Pharos HR.</p>
					<p>These Terms are binding on any use of the Pharos HR System and apply to You from the time that Pharos HR provides You with access to the Pharos HR System. Upon registration, you must read and accept the terms and conditions set out below (Terms) and be aware that Pharos HR may modify and/or supplement at any time after publication on www.pharoshr.com. Any changes will be effective upon the next renewal period. Your continued use of the Pharos HR System and/or the online products and Services following such renewal will represent an agreement by you to be bound by the terms and conditions as amended.
					</p>
					<p>By registering to use the Pharos HR System, You acknowledge that You have read and understood these Terms and have the authority to act on behalf of any person for whom You are using the Pharos HR System. You are deemed to have agreed to these Terms on behalf of any entity for whom you use the Pharos HR System. These Terms were last updated on 22 February 2013.</p>

					<h6>DEFINITIONS</h6>
					<ul>
						<li><strong>Agreement</strong> means these Terms of Use.</li>
						<li><strong>Access Fee</strong> means the monthly fee (excluding any taxes and duties) payable by You in accordance with the fee schedule set out on the Pharos HR System (which Pharos HR may change from time to time on notice to You).</li>
						<li><strong>Data</strong> means any data inputted by You into the Pharos HR System.</li>
						<li><strong>Intellectual Property Right</strong> means any patent, trade mark, copyright, moral right, right in a design, know-how and any other intellectual or industrial property rights, anywhere in the world whether or not registered.</li>
						<li><strong>Service</strong> means the online human resources management system that Pharos HR has made available (as may be changed or updated from time to time by Pharos HR) via the Pharos HR System.</li>
						<li><strong>Pharos HR System</strong> means the Internet site at the domain www.pharoshr.com.</li>
						<li><strong>Pharos HR</strong> means Pharos HR Proprietary Limited (ACN 161 981 228), registered in Australia</li>
						<li><strong>Subscriber</strong> means the person who registers to use the Service, and, where the context permits, includes any entity on whose behalf that person registers to use the Service.</li>
						<li><strong>You</strong> means the Subscriber. </li>
						<li><strong>Your</strong> has a corresponding meaning.</li>
					</ul>

					<h6>USE OF SOFTWARE</h6>
					<p>Pharos HR grants You the right to access and use the Service via the Pharos HR System with the particular functionality available to You according to Your subscription type. This right is non-exclusive, non-transferable, and limited by and subject to this Agreement.</p>

					<h6>SUBSCRIPTIONS AND PAYMENTS</h6>
					<p>The Services may be purchased only after express and total acceptance of these Terms and only upon payment of the Access Fee when the Service is accepted.
					</p>
					<p>If the Service is being offered to the Customer free of charge, the Company reserves the right to terminate at any moment, also without prior notice, or to request payment for continued use of the Service.
					</p>
					<p>The Service is provided on the basis of a monthly subscription which commences on the day you make your first Access Fee payment and continues until you elect not to renew you subscription or Pharos HR terminates your subscription. You authorise Pharos HR to process Access Fee payments to Your nominated credit card for all subscription charges including renewals, unless you cancel your subscription with effect from the next renewal date.  All of our prices are in Australian dollars (AUD) and include GST (Goods and services tax) as of March 2013. Product prices are occasionally updated when price changes are received from our suppliers. GST
					</p>
					<p>All fees and charges disclosed on the Platform are inclusive of GST at the current rate and may be increased as a result of any changes in the rate of GST. GST has the meaning used in the A New Tax System (Goods and Services Tax) Act 1999 and any applicable rulings issued by the Australian Commissioner of Taxation.
					</p>

					<h6>TERMINATION</h6>
					<p>When You first sign up for access to the Services, You can evaluate the Services under the defined trial usage conditions, with no obligation to continue to use the Services. If You choose to continue using the Services thereafter, You will be billed from the day You first added Your billing details into the System. If You choose not to continue using the Services, You may delete Your organisation in the 'Account Details' section of the System.
					</p>
					<p>Pharos HR will not provide any refund for any remaining prepaid period for a prepaid Access Fee subscription. REFUND POLICY</p>
					<p>Pharos HR reserves the right to terminate your subscription at any time for non-payment of any Access Fee or for breach of any of these terms and conditions. If your subscription is terminated, or you choose to deactivate Your access to the Pharos HR System, Pharos HR will be under no obligation to refund any part, or all, of the Access Fee paid.
					</p>
					<p>Please choose carefully. We do not normally give refunds if you simply change your mind or make a wrong decision. You can choose between a refund, exchange or credit where the product has been wrongly described, or doesn't perform as advertised.
					</p>
					
					<h6>CUSTOMER SERVICE POLICY</h6>
					<p>Pharos HR is committed to providing exceptional customer service and quality products. We endeavour to make sure that all functionality on our site is working. In the event that any part of the site is not working, we will notify you within 2 business days to arrange an agreeable alternative or a full refund of the time that the system was out.</p>
					
					<h6>PRIVACY</h6>
					<p>In providing the Services and Pharos HR System to you, Pharos HR undertakes to comply with the terms of its Privacy Policy.  Pharos HR records, collects, stores and discloses various information from you in accordance with its Privacy Policy which can be found <a href="http://www.iubenda.com/privacy-policy/407946">here</a>.  It does so as an agent on your behalf.</p>

					<h6>YOUR INFORMATION</h6>
					<p>All documents, forms and other Data created by You through the use of the Services and Pharos HR System are maintained on secure servers. Pharos HR and its suppliers will take all reasonable steps to maintain the confidentiality of, and restrict access to, Your Data and will not disclose any of Your Data to anyone unless required to do so by law. Unfortunately, no data transmission over the Internet can be guaranteed as totally secure. Pharos HR does not warrant, and cannot ensure, the security of any information that You transmit to Pharos HR and any information which You transmit to us is therefore transmitted at Your own risk.
					</p>
					<p>Subject to the intellectual property attributable to the underlying forms, documents, agreements and templates, the Data provided by You will remain Your property. A record of Your information will be maintained on the Pharos HR System after the termination of Your subscription and can be accessed via written request. 
					</p>
					
					<h6>SECURITY POLICY </h6>
					<p>When purchasing from Pharos HR your financial details are passed through a secure server using the latest 128-bit SSL (secure sockets layer) encryption technology.128-bit SSL encryption is the current industry standard. If you have any questions regarding our security policy, please contact our customer support centre <a href="mailto:info@pharoshr.com">info@pharoshr.com</a></p>

					<h6>ACCESS CONDITIONS</h6>
					<p>You must ensure that all usernames and passwords required to access the Service are kept secure and confidential. You must immediately notify Pharos HR of any unauthorised use of Your passwords or any other breach of security and Pharos HR will reset Your password and You must take all other actions that Pharos HR reasonably deems necessary to maintain or enhance the security of Pharos HR's computing systems and networks and Your access to the Services and Pharos HR System.</p>
					<p>As a condition of these Terms, when accessing and using the Services, You must:</p>
					<ul>
						<li>
							not attempt to undermine the security or integrity of Pharos HR's computing systems or networks or, where the Services are hosted by a third party, that third party's computing systems and networks;
						</li>
						<li>
							not use, or misuse, the Services in any way which may impair the functionality of the Services or Pharos HR System, or other systems used to deliver the Services or impair the ability of any other user to use the Services or Pharos HR System;
						</li>
						<li>
							not attempt to gain unauthorised access to any materials other than those to which You have been given express permission to access or to the computer system on which the Pharos HR System is hosted;
						</li>
						<li>
							not transmit, or input into the Pharos HR System, any: files that may damage any other person's computing devices or software, content that may be offensive, or material or Data in violation of any law (including Data or other material protected by copyright or trade secrets which You do not have the right to use); and
						</li>
						<li>
							not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer any computer programs used to deliver the Services or to operate the Pharos HR System except as is strictly necessary to use either of them for normal operation.
						</li>
					</ul>
					<br/>

					<h6>WARRANTIES AND ACKNOWLEDGEMENTS</h6>
					<p>Authority: You warrant that where You have registered to use the Service on behalf of another person, You have the authority to agree to these Terms on behalf of that person and agree that by registering to use the Service You bind the person on whose behalf You act to the performance of any and all obligations that You become subject to by virtue of these Terms, without limiting Your own personal obligations under these Terms.
					</p>
					<p>Acknowledgement: You acknowledge that:</p>
					<p>You are authorised to use the Services and the Pharos HR System and to access the information and Data that You input into the Pharos HR System. Pharos HR has no responsibility to any person other than You and nothing in this Agreement confers, or purports to confer, a benefit on any person other than You.
					</p>
					<p>If you use the Services or access the Pharos HR System on behalf of or for the benefit of anyone other than yourself, You agree that:</p>
					<ul>
						<li>You are responsible for ensuring that You have the right to do so.</li>
						<li>The provision of, access to, and use of, the Services is on an "as is" basis and at Your own risk.</li>
					</ul>
					<p>Pharos HR does not warrant that the use of the Service will be uninterrupted or error free. Among other things, the operation and availability of the systems used for accessing the Service, including public telephone Services, computer networks and the Internet, can be unpredictable and may from time to time interfere with or prevent access to the Services or Pharos HR System. Pharos HR is not in any way responsible for any such interference or prevention of Your access or use of the Services.
					</p>

					<h6>INDEMNITY </h6>
					<p>You indemnify Pharos HR against: all claims, costs, damage and loss arising from Your breach of any of these Terms or any obligation You may have to Pharos HR.
					</p>

					<h6>HELP DESK</h6>
					<p>Technical Problems: In the case of technical problems You must make all reasonable efforts to investigate and diagnose problems before contacting Pharos HR. If You still need technical help, please check the support provided online by Pharos HR on the Pharos HR System or failing that email us at <a href="mailto:support@pharoshr.com">support@pharoshr.com.</a> Service availability: Whilst Pharos HR intends that the Services and Pharos HR System should be available 24 hours a day, seven days a week, it is possible that on occasions the Service or Pharos HR System may be unavailable to permit maintenance or other development activity to take place. If for any reason Pharos HR has to interrupt the Services for longer periods than Pharos HR would normally expect, Pharos HR will use reasonable endeavours to publish in advance details of such activity on the Pharos HR System.</p>

					<h6>DISCLAIMER</h6>
					<p>In using the Services and Pharos HR System, You acknowledge that Pharos HR is an information Service provider and not a law firm. Pharos HR provides expert advice, but does not provide legal advice. While content created by Pharos HR and included on the Pharos HR System has been legally reviewed in Australia, You will not rely on Your use of this website as a substitute for legal advice. Pharos HR cannot guarantee that all material is compliant with all applicable State and Commonwealth legislation. Further, You are responsible for ensuring all content and data inputted by You is compliant with relevant laws.
					</p>
					<p>In providing the Services to You, Pharos HR will rely on the information provided by You. You acknowledge that the content of the documents created using the Services will depend on the answers You provide to questions during the interactive document creation processes and that documents created using the Services are intended for use in ordinary situations only and are not appropriate for use in special or unusual circumstances. If You are unsure whether certain circumstances are special or unusual, Pharos HR recommends that You seek legal advice. Pharos HR does not make any representation concerning, nor warrant the accuracy, adequacy or completeness of, the information provided through the Services or on the Pharos HR System. Pharos HR does not accept any responsibility whatsoever for any losses suffered as a result of reliance, either wholly or in part, by You or any other party upon the accuracy or currency of any content provided through the Services or on the Pharos HR System.</p>
				</div>
			</div>
		</div>
		<Footer />
	</div>
)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import _ from 'lodash'
import c from 'classnames'
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc'

import { deactivateAnswers, undoDeactivateAnswers, addAnswer, answerChanged, reorderAnswers } from 'state/actions/answer'
import { DRF } from 'types/drf'
import { ReduxState, AnyThunkDispatch } from 'types/redux'
import { fieldInputTypes } from 'types/common'
import * as style from './answer.module.sass'
import DRAGABLE_SVG from './drag-texture.svg'
import { isFieldDisabled } from './utils'
import { ANSWER_LOOKUP } from './answer-types/lookup'
import { STATIC_BASE } from 'utils/constants'
import { FormProps } from '../hooks/form-context'


type IOwnProps = {
	fieldId: string;
	tableFieldShowColumns: number;
	formProps: FormProps
}
type IStateProps = {
	answers: DRF.AnswerAny[],
	createdAnswers: DRF.AnswerAny[],
	field: DRF.Field,
}
type IDispatchProps = {
	onAnswerChanged: (answer: DRF.AnswerAny) => any;
	deactivateAnswers: (answers: DRF.AnswerAny[]) => any;
	undoDeactivateAnswers: (answers: DRF.AnswerAny[]) => any;
	addAnswer: (answer: DRF.AnswerAny) => any;
	reorder: (answers: any, isTable: boolean) => any;
}
type InnerProps = {
	listAnswers: DRF.AnswerAny[]
}
type IProps = IOwnProps & IDispatchProps & IStateProps

const styles = {
	dragHandle: {
		backgroundImage: `url("${STATIC_BASE + DRAGABLE_SVG}")`,
	}
}

const DragHandleInner = () => <div className={style.sortHandle} style={styles.dragHandle}/>

const DragHandle = SortableHandle(() => <DragHandleInner />);

type SortableRowProps = {
	dragable: boolean
	innerComponent: React.ReactNode
}

const SortableRow = SortableElement((props: SortableRowProps) => (
	<div className={style.sortableAnswerCard}>
		{props.dragable &&
			<DragHandle />
		}
		{props.innerComponent}
	</div>
))


class Answer extends Component<IProps & InnerProps> {
	componentWillMount() {
		if(this.props.answers.length == 0 && this.props.createdAnswers.length == 0) {
			this.addAnswerRow()
		}
}

	getObjectId() {
		switch(this.props.field.content_type) {
			case 'organisation':
				return this.props.formProps.organisation.id
			case 'team':
				return this.props.formProps.record.team.id
			case 'record':
				return this.props.formProps.record.id
		}
		console.error('ERRRRROR! no content type map found')
		return "0"
	}

	nextRank() {
		const ranks = [
			...this.props.createdAnswers.map(a => a.rank),
			...this.props.answers.map(a => a.rank),
		]

		if(ranks.length > 0) {
			return Math.max(...ranks) + 1
		}
		return 1
	}

	addAnswerRow() {
		if(this.props.field.type == 'table') {
			this.props.field.table_fields.map(tf => {
				this.props.addAnswer(
					this.createNewAnswer(tf.id, tf.type)
				)
			})
		}
		else {
			this.props.addAnswer(
				this.createNewAnswer(null, this.props.field.type)
			)
		}
	}

	createNewAnswer(table_field: string | null, type: fieldInputTypes) {
		const rank = this.nextRank()
		const newAnswer: DRF.AnswerAny = {
			content: type === 'boolean' ? false : null,
			content_type: this.props.field.content_type,
			field: this.props.field.id,
			object_id: this.getObjectId(),
			rank,
			table_field,
			type: type,
		}
		return newAnswer
	}


	deactivateAnswerRow(answer: DRF.AnswerAny | DRF.AnswerAny[]) {
		if(answer instanceof Array) {
			this.props.deactivateAnswers(answer)
		}
		else {
			this.props.deactivateAnswers([answer])
		}
	}

	undoDeleteAnswerRow(answer: DRF.AnswerAny | DRF.AnswerAny[]) {
		if(answer instanceof Array) {
			this.props.undoDeactivateAnswers(answer)
		}
		else {
			this.props.undoDeactivateAnswers([answer])
		}
	}

	isDeactivated(answer: DRF.AnswerAny | DRF.AnswerAny[]) {
		return !!(answer instanceof Array ? answer[0].deactivated : answer.deactivated)
	}

	render() {
		const Component = ANSWER_LOOKUP[this.props.field.type]

		const disabled = isFieldDisabled({
			field: this.props.field,
			record: this.props.formProps.record,
			publicFormCode: this.props.formProps.publicFormCode,
		}) || (this.props.formProps.publicFormCode && this.props.formProps.form.publicLinkIsReadOnly)

		const activeAnswersCount = _.reduce(
			this.props.listAnswers,
			(sum, la) => (this.isDeactivated(la) ? 0 : 1) + sum,
			0
		)

		const hidingTableColumns = this.props.field.type=='table' &&
			this.props.tableFieldShowColumns < this.props.field.table_fields.length

		const editRowDisabled = (
			(this.props.field.disable_editing_rows_when_public && this.props.formProps.publicFormCode)
			|| disabled

		)
			
		return (
			<div>
				<TransitionGroup>
					{this.props.listAnswers.map((answer: DRF.AnswerAny, i: number) => {
						const keyAnswer = answer instanceof Array ? answer[0] : answer

						const isDeactivated = this.isDeactivated(answer)
						const key = `${keyAnswer.tempId || keyAnswer.oldTempId || keyAnswer.id}-${isDeactivated}`
						return (
							<CSSTransition
								classNames="answer-animation"
								timeout={{enter: 300, exit: 300}}
								key={key}
							>
								<SortableRow
									index={i}
									dragable={!editRowDisabled && activeAnswersCount > 1 && !isDeactivated}
									innerComponent={
										!isDeactivated ? 
											<div className={style.answerAnimationContainer}>
												<div className={style.answer} >
													<Component
														onAnswerChanged={this.props.onAnswerChanged}
														answer={answer}
														disabled={disabled}
														record={this.props.formProps.record}
														field={this.props.field}
														tableFieldShowColumns={this.props.tableFieldShowColumns}
														publicFormCode={this.props.formProps.publicFormCode}
													/>
													<div className={style.deleteAnswerButtonContainer}>
														{!editRowDisabled && activeAnswersCount > 1 && !hidingTableColumns &&
															<button
																className={c("icon-cross", style.deleteAnswerButton)}
																onClick={() => this.deactivateAnswerRow(answer)}
															/>
														}
													</div>
												</div>
											</div>
										:
											<div className={style.answerAnimationContainer}>
												<div className={style.deletedAnswer} onClick={() => this.undoDeleteAnswerRow(answer)}>
													Undo delete
												</div>
											</div>
									}
								/>
							</CSSTransition>
						)
					})}
				</TransitionGroup>
				{ this.props.field.allow_multiple_answers &&
					!editRowDisabled &&
					<button className={`button-secondary ${style.iconAdd} icon-add`} onClick={() => {this.addAnswerRow()}}></button>
				}
			</div>
		)
	}
}



const SortableAnswer = SortableContainer(Answer)

class SortableAnswerContainer extends Component<IProps> {
	state = {
		refreshId: 1
	}

	listAnswers() {
		const sortFunctions = [(a: DRF.AnswerAny) => a.rank]
		const answers = [...this.props.answers, ...this.props.createdAnswers]

		if(this.props.field.type == 'table') {
			sortFunctions.unshift(a => {
				const tableField = _.find(
					this.props.field.table_fields,
					tf => tf.id == a.table_field
				)
				return tableField ? tableField.rank : 999999
			})
		}
		const sortedAnswers = _.sortBy(answers, sortFunctions)

		if(this.props.field.type != 'table') {
			return sortedAnswers
		}
		else {
			var obj: {[rank: number]: any} = {}
			sortedAnswers.map((a: DRF.AnswerAny) => {
				if(obj[a.rank]) {
					obj[a.rank] = obj[a.rank].concat(a)
				}
				else {
					obj[a.rank] = [a]
				}
			})
			var out: any = []
			Object.keys(obj).map((k: any) => {
				out = [...out, obj[k]]
			})
			return out
		}
	}

	onSortEnd({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) {
		const answers = arrayMove(this.listAnswers(), oldIndex, newIndex)
		this.props.reorder(answers, this.props.field.type=='table')
		this.setState({...this.state, refreshId: Math.random()})
	}

	render() {
		return <SortableAnswer
			key={this.state.refreshId}
			listAnswers={this.listAnswers()}
			onSortEnd={(obj) => this.onSortEnd(obj)}
			useDragHandle
			{...this.props} 
		/>
	}
}


const mapStateToProps = (state: ReduxState, ownProps: IOwnProps): IStateProps => {
	const field = state.mainForm.fields[ownProps.fieldId]
	return {
		answers: _.filter(
			field.answers.map((answerId) => state.answers.updated[answerId] || state.answers.saved[answerId]),
			a => a !== undefined
		),
		createdAnswers: field.createdAnswers.map((newAnswerId: string) => state.answers.created[newAnswerId]),
		field: field,
	}
}

const mapDispatchToProps = (dispatch: AnyThunkDispatch): IDispatchProps => (
	{
		onAnswerChanged: (answer) => {
			dispatch(answerChanged(answer))
		},
		deactivateAnswers: (answers) => {
			dispatch(deactivateAnswers(answers))
		},
		undoDeactivateAnswers: (answers) => {
			dispatch(undoDeactivateAnswers(answers))
		},
		addAnswer: (answer) => {
			dispatch(addAnswer(answer))
		},
		reorder: (answers, isTable) => {
			dispatch(reorderAnswers(answers, isTable))
		},
	}
)

export default connect(
	mapStateToProps, 
	mapDispatchToProps
)(SortableAnswerContainer)
import { combineReducers } from 'redux'


function events(state = {more: true, objects:[]}, action) {
	if(action.type=="GOT_EVENTS") {
		return {
			objects: action.events.previous ? [...state.objects, ...action.events.results] : [...action.events.results],
			more: !!action.events.next,
		}
	}
	return state
}


function activeExternalLinks(state = {more: true, objects:[]}, action) {
	if(action.type=="GOT_ACTIVE_EXTERNAL_LINKS") {
		return {
			objects: action.links.previous ? [...state.objects, ...action.links.results] : [...action.links.results],
			more: !!action.links.next,
		}
	}
	return state
}

function aggregatedTable(state = {}, action) {
	switch (action.type) {
		case "GOT_TABULAR_EMPLOYEE_METRIC":
			return {
				...state,
				[action.id]: {
					...state[action.id],
					...action.table
				}
			}
		case "GOT_TABULAR_EMPLOYEE_METRICS":
			const newState = {}
			action.tables.forEach(t => {
				newState[t.id] = t
			})
			return newState
	}
	return state
}


export default combineReducers({
	events,
	activeExternalLinks,
	aggregatedTable,
})
import React, { Component } from 'react'
import { AnswerNumberBaseProps } from './types'
import * as style from './answer-decimal.module.sass'
import * as commonStyle from './common.module.sass'
import { IconTextInput } from 'components/common/inputs/icon-text-input'

const REGEX = /\d{0,12}(\.\d{0,2})?/

export default class AnswerDecimal extends Component<AnswerNumberBaseProps> {
	onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value

		const regexResult = REGEX.exec(value)
		
		if(regexResult) {
			const regexValue = regexResult[0]
			const validValue = regexValue === '' ? null : regexValue

			this.props.onAnswerChanged({
				...this.props.answer,
				content: validValue,
			})
		}
	}

	render() {
		return (
			<div className={style.answerDecimal}>
				
				{this.props.disabled ?
						<div>
							<span className={`${style.iconDollar} icon-dollar`} />
							{this.props.answer.content || <i className={commonStyle.noContent}>No content</i>}
						</div>
					:
						<IconTextInput 
							iconClass="icon-dollar"
							placeholder={this.props.field.placeholder}
							onChange={this.onChange}
							value={this.props.answer.content || ""}
						/>
				}
			</div>
		)
	}
}


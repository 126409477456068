import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import c from 'classnames'
import querystring from 'query-string'

import FormLinks from 'components/file/FormLinks'
import _ from 'lodash'
import Field from './Field'
import { useGlobalContext, useRouter } from 'components/context'
import HelpInfoModal from './help-info/modal'
import { ReduxState } from 'types/redux'
import { DRF } from 'types/drf'
import ShrinkButton from './shrink-button'
import * as style from './index.module.sass'
import Description from './description'
import { FormProps } from '../hooks/form-context'


type Props = {
	scrollContainerRef: React.RefObject<HTMLDivElement>
	formProps: FormProps
}

const FormInputPanelContainer = (props: Props) => {
	const globalContext = useGlobalContext()
	const router = useRouter()
	const fullScreenField = useSelector((state: ReduxState) => state.userInterface.fullScreenField)
	const fields = useSelector((state: ReduxState) => state.mainForm.fields)
	const drfForm = useSelector((state: ReduxState) => state.mainForm.form)

	let fieldRefs: {[key: string]: React.RefObject<HTMLDivElement>} = {}
	drfForm.fields.map(field => {
		fieldRefs[field] = useRef<HTMLDivElement>(null)
	})
	const scrollToField = querystring.parse(router.location.search).highlightFieldId || fullScreenField
	
	const scrollToFieldRef = fieldRefs[scrollToField]

	useEffect(() => {
		// Need to add a short delay for the container to fill before scrolling
		// (it works some of the time without so be careful)
		const scrollFunc = setTimeout(() => {
			if(scrollToField && props.scrollContainerRef?.current && scrollToFieldRef?.current) {
				props.scrollContainerRef.current.scrollTo(0, scrollToFieldRef.current.offsetTop - 100)
			}
		}, 500);
		return () => clearTimeout(scrollFunc);
	  }, []);
	

	return (
		<div className={c({[style.fullScreenForm]: fullScreenField})}>
			{ props.formProps.record.archived &&
				<div className={style.archived}>
					<div className={`icon-lock ${style.iconLock}`}/>
					Archived
				</div>
			}
			<div className={style.titleRow}>
				<ShrinkButton />
				<h3 className={style.formTitle}>
					{props.formProps.form.title} · <span className="muted">{props.formProps.record.name}</span>
				</h3>
			</div>

			<Description
				description={props.formProps.form.description}
				recordId={props.formProps.record.id}
				formId={props.formProps.form.id}
			/>

			{ globalContext.user
			&& "showTeamFiles" in props.formProps.form
			&& props.formProps.form.showTeamFiles
			&& globalContext.organisation.showTeamFiles &&
				<div>
					<br/>
					{globalContext.organisation.usingFormFileLinks ?
						<FormLinks
							form={props.formProps.form}
							record={props.formProps.record}
							publicMode={props.formProps.publicFormCode}
							publicFormCode={props.formProps.publicFormCode}
						/>
					:
						<Link
							className="classic-anchor"
							to={`/public_team_files/${props.formProps.record.team.id}`}
						>
							{globalContext.currentModule.teamWord} files
						</Link>
					}
				</div>
			}

			{ drfForm.fields.map(fieldId => {
				const field = fields[fieldId]
				return <Field
					key={field.id}
					forwardRef={fieldRefs[field.id]}
					highlight={field.id == scrollToField}
					field={field}
					formProps={props.formProps}
				/>
			})}
			<HelpInfoModal />
		</div>
	)
}

export default FormInputPanelContainer
import React, { Component } from 'react'
import { useRouteMatch, Route, Redirect, RouteComponentProps } from 'react-router-dom'

import LeftPanel from './left-panel'
import { useQuery } from '@apollo/react-hooks'
import gql from "graphql-tag";
import { Spinner } from 'components/common/loading'
import {
	AdviceSection,
	AdviceSection_adviceSection_List_objects_categories as Category,
	AdviceSection_adviceSection_List_objects_categories_subcategories as Subcategory,
	AdviceSection_adviceSection_List_objects as Section
} from './gql-types/AdviceSection'
import { adviceItemUrl } from 'utils/url'
import AdviceRightPanel from './right-panel'
import * as style from './index.module.sass'
import { Switch } from 'react-router';


const ADVICE_SECTION_QUERY = gql`
	query AdviceSection {
		adviceSection_List(limit: 4) {
			objects {
				id
				categories(orderBy: {field: rank}) {
					id
					title
					rank
					subcategories(orderBy: {field: rank}) {
						id
						title
						content
						rank
					}
				}
			}
		}
	}
`
type Props = {
	sections: Section[]
}

const AdviceInner = (props: Props) => {
	const match = useRouteMatch({
		path: adviceItemUrl(':categoryId', ':subcategoryId'),
	}) || { params: {}}

	const currentCategoryProps = {
		// @ts-ignore this is an easy fix...
		currentCategoryId: match.params.categoryId || props.sections[0].categories[0].id,
		// @ts-ignore this is an easy fix...
		currentSubcategoryId: match.params.subcategoryId || props.sections[0].categories[0].subcategories[0].id,
	}

	return (
		<React.Fragment>
			<LeftPanel
				sections={props.sections}
				{...currentCategoryProps}
				
			/>
			<Switch>
				<Route
					path={adviceItemUrl(':categoryId', ':subcategoryId')}
					component={() => <AdviceRightPanel
						sections={props.sections}
						{...currentCategoryProps}
					/>}
				/>
				{/* hack to default home screen */}
				<Route
					path="*"
					component={() => <AdviceRightPanel
						sections={props.sections}
						{...currentCategoryProps}
					/>}
				/>
		
			</Switch>
		</React.Fragment>
	)
}

const Advice = (props: any) => {
	const {data, loading} = useQuery<AdviceSection>(ADVICE_SECTION_QUERY)

	return (
		<div className={style.container}>
			{loading
				? <Spinner />
				: !data
					? <div>Error</div>
					: <AdviceInner sections={data.adviceSection_List!.objects} />
			}
		</div>
	)
}

export default Advice
import React, { Component, SyntheticEvent } from 'react'
import { AnswerBooleanBaseProps } from './types'
import * as style from './answer-boolean.module.sass'

export default class AnswerBoolean extends Component<AnswerBooleanBaseProps> {
	onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: event.target.checked
		})
	}

	render() {
		return <div className={style.answerBooleanContainer}>
			<input
				type="checkbox"
				checked={this.props.answer.content || false}
				onChange={this.onChange}
				disabled={this.props.disabled}
			/>
		</div>
	}
}


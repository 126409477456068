import React, { Component } from 'react'
import cx from 'classnames'

import * as style from './icon-text-input.module.sass'


type InputWithoutClashProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">

type Props = InputWithoutClashProps & {
	iconClass: string
} 

export const IconTextInput = React.forwardRef(({iconClass, ...inputProps}: Props, ref: React.Ref<HTMLInputElement>) => {
	return (
		<div className={style.container}>
			<div className={cx(style.icon, iconClass)} />
			<input type="text" ref={ref} {...inputProps} className={style.input}/>
		</div>
	)
})

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { wrapWithContext } from 'components/context'
import { formUrl } from 'utils/url'

import * as style from './link-item.module.sass'

const ActiveExternalLink = ({ link, ...context }) => (
	<Link
		className={style.container}
		to={formUrl({recordId: link.record.id, formId: link.form.id, ...context})}
	>
		<div className={style.primaryContent}>{link.record.name}</div>
		<div className={style.secondaryContent}>{link.form.title}</div>
	</Link>
)

export default wrapWithContext(ActiveExternalLink)
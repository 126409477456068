import React, { useState } from 'react'
import _ from 'lodash'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import gql from "graphql-tag"
import { useMutation } from '@apollo/react-hooks'

import { SpinnerButton } from 'components/common/button'
import { TextField } from 'components/common/formik'
import { apolloClient } from 'state/apollo-client'
import { useGlobalContext } from 'components/context'
import { UpdatePassword, UpdatePasswordVariables } from './gql-types/UpdatePassword'

const UPDATE_PASSWORD = gql`
	mutation UpdatePassword(
		$id: ID!,
		$password: String
	) {
		user_Update(input: {
			id: $id,
			password: $password
		}) {
			object {
				id
			}
			errors {
				field
				messages
			}
		}
	}
`

type ValueType = { password: string, passwordConfirm: string }
type ErrorType = { passwordConfirm?: string, password?: string }

const UpdateUserPassword = () => {
	const { user } = useGlobalContext()
	const [mutate, {}] = useMutation<UpdatePassword, UpdatePasswordVariables>(UPDATE_PASSWORD)
	const [saved, setSaved] = useState(false)
	return (	
		<Formik<ValueType>
			initialValues={{
				password: '',
				passwordConfirm: '',
			}}
			validationSchema={Yup.object().shape({
				password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
				passwordConfirm: Yup.string()
					.oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
			})}
			validate={values => {
				let errors: ErrorType = {}
				if((values.password || values.passwordConfirm)) {
					if(values.password != values.passwordConfirm) {
						errors.passwordConfirm = "passwords don't match"
					}
					if(values.password.length < 6) {
						errors.password = "password too short"
					}
				}
				return errors
			}}
			onSubmit={(values, actions) => {
				mutate({variables: {id: user.id, ...values}}).then(r => {
					if(!r || !r.data || !r.data.user_Update) {
						alert('There was an issue saving data')
						return
					}
					if(r.data.user_Update.errors) {
						const errors = _.mapValues(_.keyBy(r.data.user_Update.errors, 'field'), 'message')
						actions.setErrors(errors)
					}
					else {
						apolloClient.resetStore()
						setSaved(true)
						actions.setSubmitting(false)
					}
				})
			}}
		>
			{props => {
				const { dirty, isSubmitting, handleSubmit } = props
				return (
					<form onSubmit={handleSubmit} className="common-form">
						{[
							['password', 'Password', {type: 'password'}],
							['passwordConfirm', 'Password (again)', {type: 'password'}],
						].map(([name, placeholder, options={}]) => {
							return <Field
								key={name}
								name={name}
								placeholder={placeholder}
								component={TextField}
								{...options}
							/>
						})}
						
						<SpinnerButton loading={isSubmitting} type="submit">
							{(saved && !dirty) ? 'Saved!' : 'Save'}
						</SpinnerButton>
					</form>
				)
			}}
		</Formik>
	)
}

export default UpdateUserPassword

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { signupUrl, getDjangoDomain } from 'utils/url'
import Footer from '../../common/footer'
import ScreenDemo from '../common/screen-demo'
import Pricing from '../../pricing'

import IMG_PLATFORMS from '../common/platforms.png'
import { STATIC_BASE } from 'utils/constants'

const LANDING = [
	{
		color: "color-purple",
		images: [
			require('./screens/contract-add.png'),
			require('./screens/contract-form.png'),
			require('./screens/contract-doc.png'),
		],
		title: "Manage records, from hire to fire",
		text: "Input your data, and have it flow through to everywhere you need it",
	},
]


const PLANS = [
	{
		title: "Free Tier",
		buttonText: "Get started",
		cost: "0",
		costSuffix: "/month",
		contactUsEmail: "",
		planFeatures: [
			{ text: "Up to 2 employees" },
			{ text: "Standard employee templates" },
		]
	},
	{
		title: "Premium Package",
		buttonText: "Contact us",
		cost: "",
		costSuffix: "",
		contactUsEmail: "support@pharoshr.com",
		planFeatures: [
			{ text: "Unlimited Employees" },
			{ text: "Customised employee templates" },
			{ text: "Access to HR Advice" },
			{ text: "Personalised styling" },
			{ text: "Customised HR processes" },
		]
	}
]


export default function Landing() {
	return (
		<div className="landing pharos-landing">
			<div className="color-peach top-content">
				<div className="main-text">Simply managing<br/>your people</div>
				<a href={getDjangoDomain() + signupUrl()}><button className="get-started-button">Get Started For Free</button></a>
			</div>

			{ LANDING.map((demo, i) => {
				return <ScreenDemo key={i} {...demo}/>
			})}

			<Pricing plans={PLANS} />

			<div className="color-green platforms">
				<div className="text">
					Secure, anytime, anywhere
				</div>
				<img className="image" src={STATIC_BASE + IMG_PLATFORMS} />
			</div>
			<Footer />
		</div>
	)
}
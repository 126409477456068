import React, { Component } from 'react'
import Teams from './teams'
import Switch from './switch'

export default class Org extends Component {
	state = {
		show: false
	}
	render() {
		const o = this.props.org
		return (
			<div 
				key={o.id}
				className="org-container"
			>
				<div className="org-row">
					<div className="id">{o.id}</div>
					<div className="org-name" onClick={() => this.setState({show: !this.state.show})}>{o.name}</div>
					<Switch orgId={this.props.org.id} />
					
				</div>
				{this.state.show &&
					<Teams
						filter={{organisation: this.props.org.id}}
						changeOrg={this.props.changeOrg}
					/>
				}
			</div>
		)
	}
}
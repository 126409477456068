import React, { Component, useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Route } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import _ from 'lodash'

import { SinglePanel } from 'components/common/panel'
import Dashboard from './dashboard'
import AggregatedTable from './aggregated-table'
import ConcatenatedTable from './concatenated-table'
import { useGlobalContext } from 'components/context'
import { teamAggregatedTableUrl, teamConcatenatedTableUrl, teamMetricsUrl, GLOBAL_CONTEXT_PARAMS } from 'utils/url'
import { ReduxState } from 'types/redux'
import Placeholder from 'components/common/placeholder'
import * as style from './index.module.sass'
import TopRow from './top-row'


const Analytics = () => {
	const globalContext = useGlobalContext()
	return (
		<SinglePanel noPadding key={globalContext.currentTeam && globalContext.currentTeam.id || undefined} type="full-width"> 
			<TopRow />
			<div className={style.content}>
				<Route path={teamMetricsUrl(GLOBAL_CONTEXT_PARAMS)} component={Dashboard} />
				<Route path={teamConcatenatedTableUrl({ tableId: ':id', ...GLOBAL_CONTEXT_PARAMS})} component={ConcatenatedTable} />
				<Route path={teamAggregatedTableUrl({ tableId: ':id', ...GLOBAL_CONTEXT_PARAMS})} component={AggregatedTable} />
			</div>
		</SinglePanel>
	)
}

export default Analytics
import React, { Component } from 'react'
import { AnswerTextBaseProps } from './types'
import * as style from './answer-radio.module.sass'


export default class AnswerRadio extends Component<AnswerTextBaseProps> {
	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: e.target.value,
		})
	}

	render() {
		return (
			<div className={style.radioRow}>
				{this.props.disabled && <span className="muted"> (disabled)</span>}
				{this.props.field.select_options.map(selectOption => {
					return (
						<div className={style.radioItem} key={selectOption}>
							<input 
								type='radio'
								value={selectOption}
								checked={selectOption === this.props.answer.content}
								onChange={this.onChange}
								disabled={this.props.disabled}
							/>
							<span className={style.radioText}>{selectOption}</span>
						</div>
					)
				})}
			</div>
		)
	}
}


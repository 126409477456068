export default function loading(state = {}, action) {
	switch(action.type) {
		case "FORM_LOADING": return {
			...state,
			form: action.loading
		}
		case "LOADING_EMPLOYEES": return {
			...state,
			records: action.loading
		}
		case "LOADING_EMPLOYEE": return {
			...state,
			record: action.loading
		}
		case "GOT_EMPLOYEES": return {
			...state,
			records: false
		}
		case "GOT_EMPLOYEE": return {
				...state,
				record: false
			}
		case "GOT_FORM": return {
			...state,
			form: false
		}
	}
	return state
}
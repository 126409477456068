import React from 'react'
import { Modal } from 'antd'

import history from 'utils/history'
import { useGlobalContext } from 'components/context'
import { formUrl } from 'utils/url'
import { ModalProps } from '../types'
import NotificationList from './list'


const FieldNotificationModal = (props: ModalProps) => {
	const globalContext = useGlobalContext()

	return (
		<Modal
			title="Notifications"
			visible
			maskClosable
			onCancel={() => history.push(formUrl({recordId: props.recordId, formId: props.formId, ...globalContext}))}
			footer={null}
		>
			<p>
				When you set a notification you will be notified by email.
			</p>
				{globalContext.user.topLevelManager &&
					<p>
						If set to "Everyone" all users with access to the	
						{globalContext.currentModule.recordWord.toLowerCase()} will be notified.
					</p>
				}
			<NotificationList {...props} />
		</Modal>
	)
}

export default FieldNotificationModal

import React from 'react'
import gql from 'graphql-tag'
import { useGlobalContext } from 'components/context'
import { useQuery } from 'react-apollo'
import ValueMetric from '../../types/value'
import { SpecificMetric, SpecificMetricVariables } from './gql-types/SpecificMetric'
import MetricPlaceholder from '../metric-placeholder'

const QUERY = gql`
	query SpecificMetric($id: ID!, $teamId: ID) {
		specificMetric(id: $id) {
			id
			name
			result(teamId: $teamId) {
				value
				formLink {
					form {
						id
						title
					}
					record {
						id
						name
					}
					field {
						id
						title
					}
				}
			}
		}
	}
`


type Props = {
	id: string
}

const SpecificMetricWrapper = ({ id }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<SpecificMetric, SpecificMetricVariables>(QUERY, {variables: {id, teamId: globalContext.currentTeam?.id}})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.specificMetric) {
		return <div>error</div>
	}
	return (
		<ValueMetric {...data?.specificMetric} />
	)

}

export default SpecificMetricWrapper
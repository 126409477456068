import React from 'react'
import { useSelector } from 'react-redux'
import c from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { ReduxState } from 'types/redux'

import * as style from './index.module.sass'

const Notifications = () => {
	const notifications = useSelector((state: ReduxState) => state.userInterface.notifications)
	return (
		<div className={style.container}>
			<TransitionGroup>
				{notifications.reverse().map(n => 
					<CSSTransition
						classNames={"notification-animation"}
						timeout={{enter: 300, exit: 500}}
						key={n.id}
					>
						<div className={c(
							style.notification, 
							{
								[style.positive]: n.type != 'error' && n.type != 'neutral',
								[style.error]: n.type=='error',
								[style.neutral]: n.type=='neutral',
							},
						)}>
							{n.content}
						</div>
					</CSSTransition>
				)}
			</TransitionGroup>
		</div>
	)
}

export default Notifications

import React from 'react'
import gql from 'graphql-tag'
import { useGlobalContext } from 'components/context'
import { useQuery } from 'react-apollo'
import ListMetric from '../../types/list'
import { FieldDateRangeMetric, FieldDateRangeMetricVariables } from './gql-types/FieldDateRangeMetric'
import MetricPlaceholder from '../metric-placeholder'


const QUERY = gql`
	query FieldDateRangeMetric($id: ID!, $teamId: ID) {
		fieldDateRangeMetric(id: $id) {
			id
			name
			values(teamId: $teamId) {
				content
				contentObjectName
				formLink {
					form {
						id
						title
					}
					record {
						id
						name
					}
					field {
						id
						title
					}
				}
			}
		}
	}
`


type Props = {
	id: string
}

const FieldDateRangeMetricWrapper = ({ id }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<FieldDateRangeMetric, FieldDateRangeMetricVariables>(QUERY, {variables: {id, teamId: globalContext.currentTeam?.id}})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.fieldDateRangeMetric) {
		return <div>error</div>
	}
	return (
		<ListMetric {...data?.fieldDateRangeMetric} />
	)

}

export default FieldDateRangeMetricWrapper
import { combineReducers } from 'redux'


function availableCsvData(state={fields: [], positions: []}, action) {
	if(action.type == "GOT_AVAILABLE_CSV_DATA") {
		return {
			fields: action.fields,
			positions: action.positions,
		}
	}
	return state
}


export default combineReducers({
	availableCsvData,
})
import React from 'react'
import { Route } from 'react-router-dom'

import SimpleIconButton from 'components/common/simple-icon-button'
import { fieldContentType } from 'types/common'
import { useGlobalContext } from 'components/context'
import { formFieldNotificationUrl } from 'utils/url'
import FieldNotificationModal from './modal'
import { ModalProps } from './types'


type Props = ModalProps & {
	publicFormCode: string | null
}

const FieldNotification = (props: Props) => {
	if (props.publicFormCode) {
		return null
	}
	const globalContext = useGlobalContext()
	return (
		<>
			<SimpleIconButton
				to={formFieldNotificationUrl({...globalContext, ...props})}
				iconClass="icon-bell"
			/>
			<Route
				path={formFieldNotificationUrl({...globalContext, ...props})}
				component={() => <FieldNotificationModal {...props} />}
			/>
		</>
	)
}

export default FieldNotification
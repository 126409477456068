import { fieldTypes } from 'types/common'

import AnswerBoolean from './answer-boolean'
import AnswerDate from './answer-date'
import AnswerDropdown from './answer-dropdown'
import AnswerDecimal from './answer-decimal'
import AnswerFile from './answer-file'
import AnswerImage from './answer-image'
import AnswerInteger from './answer-integer'
import AnswerRadio from './answer-radio'
import AnswerEmail from './answer-email'
import AnswerTable from './answer-table'
import AnswerTextLong from './answer-text-long'
import AnswerTextShort from './answer-text-short'
import AnswerTime from './answer-time'


export const ANSWER_LOOKUP: { [i: string]: React.ComponentType<any>; } = {
	text_long:			AnswerTextLong,
	text_short:			AnswerTextShort,
	text_tiny:			AnswerTextShort,
	drop_down:			AnswerDropdown,
	drop_down_other:	AnswerDropdown,
	radio:				AnswerRadio,
	email:				AnswerEmail,
	boolean:				AnswerBoolean,
	date:					AnswerDate,
	time:					AnswerTime,
	integer:				AnswerInteger,
	currency:			AnswerDecimal,
	table:				AnswerTable,
	image:				AnswerImage,
	file:					AnswerFile,
}

export const ANSWER_SIZE_LOOKUP: { [i: string]: {flexBasis: number, flexGrow: number}; } = {
	// 0 should be 0.1 so that it will expand if they're all 0.1
	text_long:			{flexBasis: 130, flexGrow: 15},
	text_short:			{flexBasis: 100, flexGrow: 10},
	text_tiny:			{flexBasis: 60,  flexGrow: 0.1},
	drop_down:			{flexBasis: 100, flexGrow: 8},
	drop_down_other:	{flexBasis: 100, flexGrow: 8},
	radio:				{flexBasis: 100, flexGrow: 8},
	email:				{flexBasis: 100,  flexGrow: 8},
	boolean:				{flexBasis: 80,  flexGrow: 0.1},
	date:					{flexBasis: 140, flexGrow: 0.1},
	time:					{flexBasis: 100, flexGrow: 0.1},
	integer:				{flexBasis: 100, flexGrow: 0.1},
	currency:			{flexBasis: 120, flexGrow: 0.1},
	table:				{flexBasis: 100, flexGrow: 10},
	image:				{flexBasis: 100, flexGrow: 10},
	file:					{flexBasis: 120, flexGrow: 0.1},	
}
import React, { useContext } from 'react'
import _ from 'lodash'
import gql from "graphql-tag"
import { useMutation } from '@apollo/react-hooks'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import { useDispatch } from 'react-redux'

import { createNotification } from 'state/actions/userInterface'
import { TextField } from 'components/common/formik'
import { SpinnerButton } from 'components/common/button'
import { userManagementEditUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import { useRouter } from 'components/context'
import { saveUser as MutationType, saveUserVariables as MutationVariables } from './gql-types/saveUser'
import { UserListContext } from '../context'

const SAVE_USER = gql`
	mutation saveUser(
		$id: ID,
		$firstName: String!,
		$lastName: String!,
		$email: String!,
		$organisation: ID!,
	) {
		user_Update(input: {
			id: $id,
			firstName: $firstName,
			lastName: $lastName,
			email: $email,
			organisation: $organisation,
		}) {
			object {
				id
				firstName
				lastName
				email
			}
		}
	}
`

type Props = {
	user: {
		id?: string
		firstName: string
		lastName: string
		email: string
	}
}

export default ({ user }: Props) => {
	const [saveUser, {}] = useMutation<MutationType, MutationVariables>(SAVE_USER)
	const refetchList = useContext(UserListContext)
	const globalContext = useGlobalContext()
	const router = useRouter()
	const dispatch = useDispatch()

	return (
		<Formik
			initialValues={user}
			validationSchema={Yup.object().shape({
				email: Yup.string().required('Required'),
				firstName: Yup.string().required('Required'),
				lastName: Yup.string().required('Required'),
			})}
			onSubmit={(values, actions) => {
				saveUser({variables: {...values, organisation: globalContext.organisation.id}}).then((r) => {
					actions.setSubmitting(false)
					if(!values.id) {
						//redirect to edit user page
						router.history.push(userManagementEditUrl(r.data!.user_Update!.object!.id))
						refetchList()
						dispatch(createNotification({content: 'Invitation sent!'}))
					} else {
						dispatch(createNotification({content: 'User saved'}))

					}
				}).catch((r) => {
					console.error(r)
					actions.setSubmitting(false)
					actions.setFieldError('email', 'A user with that email already exists')
				}).finally(() => {
				})
			}}
		>
			{props => {
				const { errors ,isSubmitting ,handleSubmit } = props
				return (
					<React.Fragment>
						<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
							<form onSubmit={handleSubmit} className="common-form">
								<Field
									name="firstName"
									placeholder="First name"
									component={TextField}
								/>
								<Field
									name="lastName"
									placeholder="Last name"
									component={TextField}
								/>
								<Field
									name="email"
									placeholder="Email"
									component={TextField}
								/>
								<div className="error">{errors.email}</div>

								<div style={{display: 'flex', justifyContent: 'space-around', width: 200}}>

									<SpinnerButton loading={isSubmitting} type="submit">
										{user.id ? 	'Save' : 'Send invitation'}
									</SpinnerButton>
								</div>
								
							</form>
						</div>
					</React.Fragment>
				)
			}}
		</Formik>
	)
}

import React, { Component } from 'react'
import c from 'classnames'

import { AnswerTextBaseProps } from './types'
import * as style from './answer-text.module.sass'
import * as commonStyle from './common.module.sass'


export default class AnswerTextLong extends Component<AnswerTextBaseProps> {
	onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: event.target.value,
		})
	}

	render() {
		return this.props.disabled ?
			<div className={style.answerTextDisabled}>
				{this.props.answer.content || <i className={commonStyle.noContnet}>No content</i>}
			</div>
		:
			<textarea
				className={c(commonStyle.formInput, style.textarea)}
				placeholder={this.props.field.placeholder}
				onChange={this.onChange}
				value={this.props.answer.content || ""}
			/>
	}
}


import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { SITE_NAME } from 'utils/constants'

interface IDetail {
	title: string
	content: React.ReactNode
}

interface IContact {
	name: string
	details: IDetail[]
}

const CONTACTS: {[site: string]: IContact} = {
	PHAROS: {
		name: 'Pharos HR Pty Ltd',
		details: [
			{title: 'ABN', content: '60 485 246 546'},
			{title: 'ACN', content: '161 981 228'},
			{title: 'Trading address', content: '1/240 Waverley rd, Mount Waverley, VIC 3149'},
			{title: 'Country of domicile', content: 'Australia'},
			{title: 'Phone', content: '1300 887 692'},
			{title: 'Email', content: <a href="mailto:info@pharoshr.com"> info@pharoshr.com</a>},
		]
	},
	QUESTOS: {
		name: 'Questos',
		details: [
			{title: 'ABN', content: '60 485 246 546'},
			{title: 'ACN', content: '161 981 228'},
			{title: 'Trading address', content: '1/240 Waverley rd, Mount Waverley, VIC 3149'},
			{title: 'Country of domicile', content: 'Australia'},
			{title: 'Phone', content: '1300 887 692'},
			{title: 'Email', content: <a href="mailto:info@pharoshr.com"> info@pharoshr.com</a>},
		]
	},
	SCHOOL: {
		name: 'Pharos HR Pty Ltd',
		details: [
			{title: 'ABN', content: '60 485 246 546'},
			{title: 'ACN', content: '161 981 228'},
			{title: 'Trading address', content: '1/240 Waverley rd, Mount Waverley, VIC 3149'},
			{title: 'Country of domicile', content: 'Australia'},
			{title: 'Phone', content: '1300 887 692'},
			{title: 'Email', content: <a href="mailto:info@pharoshr.com"> info@pharoshr.com</a>},
		]
	},
}

export const CONTACT = CONTACTS[SITE_NAME]
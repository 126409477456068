import { matchPath } from 'react-router-dom'
import { GlobalContextProps } from "components/context"
import querystring from 'query-string'

type IdObj = {id: string}
type Context = {
	currentModule: IdObj
	currentTeam: IdObj | null
}

const ROOT_DOMAINS: {[k: string]: string} = {
	'bastionsystems': 'bastionsystems.com',
	'incident': 'incident.watch',
	'pharoshr': 'pharoshr.com',
	'questos': 'questos.com.au',
	'socialandemotionallearning': 'socialandemotionallearning.com.au',
	'staging-pharos': 'staging-pharos.com',
	'localhost:8000': 'localhost:8000',
}

export const getDjangoDomain = () => {
	const protocol = location.protocol + "//"
	const domain = location.host
	if (ROOT_DOMAINS[domain.split('.')[0]] && domain !== 'localhost:8000') {
		// we could be on a client specific subdomain ie laser.pharoshr.com
		// in which case we shouldn't redirect to app.laser.pharoshr.com
		return protocol + 'app.' + domain
	} else {
		return protocol + domain
	}
}

export const getPublicSiteDomain = () => {
	const protocol = location.protocol + "//"
	const domain = location.host
	for(const d of domain.split('.')) {
		// check all depths of subdomains and go to root
		if (ROOT_DOMAINS[d]) {
			return protocol + ROOT_DOMAINS[d]
		}
	}
	console.error("What domain are we on???")
}

export const ALL_TEAMS = 'all'

export const landingPageUrl = () => `/`
export const apiUrl = () => `/api`

export const dashboardUrl = () => `/dashboard`

export const accountUrl = () => `/account`
export const signupUrl = () => (
	`${accountUrl()}/signup`
)
export const loginUrl = (query?: {next?: string}) => (
	`${accountUrl()}/login${(query && query.next) ? '?' + querystring.stringify(query) : ''}`
)

export const publicUrl = () => `/public`
export const contactUrl = () => (
	`${publicUrl()}/contact`
)
export const termsUrl = () => (
	`${publicUrl()}/terms`
)

export const noTeamUrl = () => (
	`${dashboardUrl()}/no-team`
)

export const moduleUrl = (currentModule: IdObj) => (
	`${dashboardUrl()}/module/${currentModule.id}`
)

export const teamNoListUrl = ({currentModule, currentTeam}: Context) => (
	`${moduleUrl(currentModule)}/team/${currentTeam ? currentTeam.id : ALL_TEAMS}`
)
export const teamUrl = (ctx: Context) => (
	`${teamNoListUrl(ctx)}/list`
)
export const teamSettingsUrl = (context: Context) => (
	`${teamUrl(context)}/settings`
)
export const teamFilesUrl = (context: Context) => (
	`${teamUrl(context)}/files`
)
export const teamAnalyticsBaseUrl = (context: Context) => (
	`${teamUrl(context)}/analytics`
)
export const teamMetricsUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/metrics`
)
type AnalyticsTableProps = Context & {tableId: string}
export const teamAggregatedTableUrl = ({tableId, ...context}: AnalyticsTableProps) => (
	`${teamAnalyticsBaseUrl(context)}/aggregated-table/${tableId}`
)
export const teamConcatenatedTableUrl = ({tableId, ...context}: AnalyticsTableProps) => (
	`${teamAnalyticsBaseUrl(context)}/concatenated-table/${tableId}`
)
export const teamMetricsDefaultUrl = ({organisation}: {organisation: GlobalContextProps["organisation"]}) => {
	const firstModuleSetItemWithTeam = organisation.suite.moduleSetItems.find(msi => msi.module.teams.length > 0)
	const currentModule = (firstModuleSetItemWithTeam || organisation.suite.moduleSetItems[0]).module
	const currentTeam = currentModule.teams[0]
	return teamMetricsUrl({
		currentModule,
		currentTeam
	})
}

export const recordAddUrl = (context: Context) => (
	`${teamUrl(context)}/record-add`
)
type RecordUrl = Context & {recordId: string}
export const recordUrl = ({recordId, ...context} :RecordUrl) => (
	`${teamUrl(context)}/record/${recordId}`
)
export const recordEditUrl = (params: RecordUrl) => (
	`${recordUrl(params)}/edit`
)
export const recordCopyUrl = (params: RecordUrl) => (
	`${recordUrl(params)}/copy`
)
export const recordDashboardUrl =(params: RecordUrl) => (
	`${recordUrl(params)}/dashboard`
)

type FormUrl = RecordUrl & {formId: string, highlightFieldId?: string}
export const formUrl = ({formId, highlightFieldId, ...params}: FormUrl) => (
	`${recordUrl(params)}/form/${formId}${highlightFieldId ? ('?highlightFieldId='+highlightFieldId) : ''}`
)
export const formLinksEditUrl = (params: FormUrl) => (
	`${formUrl(params)}/form-links-edit`
)

type NotiProps = FormUrl & {fieldType: string, fieldId: string}
export const formFieldNotificationUrl = ({fieldType, fieldId, ...params}: NotiProps) => (
	`${formUrl(params)}/field-notifications/${fieldType}/${fieldId}`
)

export const publicFormSuccessUrl = () => `/public-form-success`
export const publicFormInvalidUrl = () => `/public-form-invalid`
export const publicFormUrl = (publicFormCode: string) => (
	`/public_form/${publicFormCode}`
)

export const publicCreateRecordUrl = (code: string) => (
	`/public_create/${code}`
)

type EmailModalUrl = FormUrl & {
	regardingObjectContentType: string,
	regardingObjectId: string,
}
export const emailModalUrl = ({
	regardingObjectContentType,
	regardingObjectId,
	...formParams
}: EmailModalUrl) => (
	`${formUrl(formParams)}/email/${regardingObjectContentType}/${regardingObjectId}`
)
export const emailModalSendUrl: typeof emailModalUrl = (...params) => (
	`${emailModalUrl(...params)}/send`
)
export const emailModalHistoryUrl: typeof emailModalUrl = (...params) => (
	`${emailModalUrl(...params)}/history`
)

type EmailPdfNameUrl = FormUrl & {pdfId: string}
export const editPdfNamelUrl = ({pdfId, ...formParams}: EmailPdfNameUrl) => (
	`${formUrl(formParams)}/rename_pdf/${pdfId}`
)

export const exportDataUrl = (context: Context) => (
	`${teamUrl(context)}/export`
)

export const addModuleUrl = () => (
	`${dashboardUrl()}/add-team`
)

export const userManagementUrl = () => `${dashboardUrl()}/user-management`
export const userManagementAddUrl = () => `${userManagementUrl()}/add`
export const userManagementEditUrl = (id: string) => `${userManagementUrl()}/edit/${id}`

export const pharosAdminUrl = (search?: string) => `${dashboardUrl()}/pharos-admin${search ? `/${search}` : ''}`
export const adviceUrl = () => `${dashboardUrl()}/advice`
export const adviceItemUrl = (categoryId: string, subcategoryId: string) => `${adviceUrl()}/category/${categoryId}/subcategory/${subcategoryId}`
export const userSettingsUrl = () => `${dashboardUrl()}/user/settings`
export const organisationSettingsUrl = () => `${dashboardUrl()}/organisation/settings`
export const downloadAnswerFileUrl = (fileId: string) => `${dashboardUrl()}/download-answer-file/${fileId}`

export const GLOBAL_CONTEXT_PARAMS = {currentModule: {id: ':moduleId'}, currentTeam: {id: ':teamId'} }

type TeamParams = {
	teamId: string
	moduleId: string
}
export const getTeamParams = (url: string) => {
	const maybeTeamParams = matchPath<TeamParams>(url, {
		path: teamNoListUrl(GLOBAL_CONTEXT_PARAMS),
	})
	if (maybeTeamParams) {
		return {
			teamId: maybeTeamParams.params.teamId,
			moduleId: maybeTeamParams.params.moduleId
		}
	}
	return {}
}

type FormParams = {
	formId: string
}
export const getFormParam = (url: string) => {
	const maybeRecordParams = matchPath<FormParams>(url, {
		path: formUrl({ recordId: ':recordId', formId: ':formId', ...GLOBAL_CONTEXT_PARAMS }),
	})
	if (maybeRecordParams) {
		return {
			formId: maybeRecordParams.params.formId
		}
	}
	return {}
}
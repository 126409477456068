import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'

import Placeholder from 'components/common/placeholder'
import history from 'utils/history'
import { getAggregatedTable } from 'state/actions/analytics'
import { wrapWithContext } from 'components/context'
import { formUrl } from 'utils/url'
import { getParams } from '../common'

class AggregatedTable extends Component {
	state = {
		orderField: {
			index: null,
			reverse: false
		},
		loading: true
	}

	async getTable () {
		await this.props.getTable(this.props.match.params.id, getParams(this.props))
		this.setState({ ...this.state, loading: false })
	}

	componentDidMount() {
		this.getTable()
	}
	
	componentDidUpdate(prevProps) {
		if(prevProps.match.params.id != this.props.match.params.id) {
			this.getTable()
		}
	}

	async orderByField(orderFieldIndex) {
		const currentDirection = this.state.orderField.index == orderFieldIndex
			? this.state.orderField.reverse
			: true

		this.setState({
			...this.state,
			orderField: {
				index: orderFieldIndex,
				reverse: !currentDirection
			},
			loading: true
		})

		await this.props.getTable(
			this.props.match.params.id,
			{
				order_field_index: orderFieldIndex+1,
				order_field_reverse: !currentDirection,
				...getParams(this.props),
			}
		)
		this.setState({ ...this.state, loading: false })


	}

	render() {
		if(!this.props.table?.table) return (
			<div className="aggregated-table">
				{Array.apply(null, {length: 10}).map((_, i) => (
					<Placeholder key={i} />
				))}
			</div>
		)

		return (
			<div className="aggregated-table">
				{ this.props.table?.table &&
					<>
						<table className="table" cellSpacing="0" style={this.state.loading ? {opacity: 0.5, pointerEvents: 'none'} : {}}>
							<thead>
								<tr className="row header-row">
									{this.props.table.table[0].slice(1).map((cell, i) => (
										<td
											key={i}
											className="cell"
											onClick={() => this.orderByField(i)}
										>
											{cell}
											<span 
												className={cx(
													"order-direction",
													{
														"hide-icon": i != this.state.orderField.index,
														"icon-carrot-down": this.state.orderField.reverse,
														"icon-carrot-up": !this.state.orderField.reverse,
													}
												)}
											/>
										</td>
									))}
								</tr>
							</thead>
							<tbody>
								{this.props.table.table.slice(1).map((row, i) => (
									<tr
										key={i}
										className={cx("row", "body-row", {"dark-row": i%2==0})}
										onClick={() => history.push(
											formUrl({recordId: row[0], formId: this.props.table.link_to_form, ...this.props})
										)}
									>
										{row.slice(1).map((cell, j) => (
											<td className="cell" key={j}>
												{cell}
											</td>
										))}
									</tr>
								))}
								
							</tbody>
						</table>
						{this.props.table.table.slice(1).length == 0 &&
							<centered className="muted"><br/><br/><br/>No data to display</centered>			
						}
					</>
				}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		table: state.analytics.aggregatedTable[ownProps.match.params.id],
  	}
}

const mapDispatchToProps = (dispatch) => {
  	return {
		getTable: (id, params) => dispatch(getAggregatedTable(id, params)),
  	}
}


export default wrapWithContext(connect(
  	mapStateToProps, 
  	mapDispatchToProps
)(AggregatedTable))

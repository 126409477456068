import { ApolloClient } from "apollo-client"
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getCookie } from 'utils/functions'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { default as reduxStore } from './redux-store'
import { createNotification } from './actions/userInterface'

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
	},
	query: {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	},
	mutate: {
		errorPolicy: 'all',
	},
}

const httpLink = createUploadLink({
	uri: '/graphql',
	credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			'X-CSRFToken': getCookie('csrftoken')
		}
	}
})

const errorLink = onError(({ networkError }) => {
	reduxStore.dispatch(createNotification({
		'type': 'error',
		'content': `Oops, it appears something has gone wrong`
	}))
 })


export const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: errorLink.concat(authLink.concat(httpLink)),
	defaultOptions,
})


import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'

import { useGlobalContext } from 'components/context'
import * as style from './team-details.module.sass'
import { organisationSettingsUrl } from 'utils/url'

export default () => {
	const globalContext = useGlobalContext()
	const logo = (
		(globalContext.currentTeam && globalContext.currentTeam.logo)
		|| globalContext.organisation.logo
	)
	return (
		<div className={style.container}>
			{ logo && 
				<div
					className={style.logo}
					style={{backgroundImage: `url(${logo})`}} 
				/>
			}
			{ !logo && !globalContext.organisation.logo &&
				<Link className={style.uploadLogoContainer} to={organisationSettingsUrl()}>
					<span className={c(style.icon, "icon-medium-building")}></span>Upload logo
				</Link>
			}
			<div className={style.teamNameRow}>
				<div className={style.teamName}>
					{globalContext.currentTeam
						? globalContext.currentTeam.name
						: `All ${globalContext.currentModule.teamWord}s`
					}
				</div>
			</div>
		</div>
	)
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import c from 'classnames'

import { setHelpInfo } from 'state/actions/mainForm'
import { AnyThunkDispatch } from 'types/redux';
import { DRF } from 'types/drf';
import SimpleIconButton from 'components/common/simple-icon-button'


interface IOwnProps {
	field: DRF.FieldBase
}
interface IDispatchProps {
	setHelpInfo: (helpInfo: DRF.FieldBase) => void
}

const Field = ({ field, setHelpInfo }: IOwnProps & IDispatchProps) => {
	return (
		<SimpleIconButton
			small
			iconClass="icon-info"
			onClick={() => setHelpInfo(field)} 
		/>
	)
}

const mapDispatchToProps = (dispatch: AnyThunkDispatch): IDispatchProps => {
	return {
		setHelpInfo: (field) => {
			dispatch(setHelpInfo(field))
		},
	}
}

export default connect(
	() => ({}), 
	mapDispatchToProps
)(Field)
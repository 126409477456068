import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'

import { ReduxState } from 'types/redux'
import { formUrl } from 'utils/url'
import ErrorBoundary from 'components/common/error-boundary'
import { SinglePanel } from 'components/common/panel'
import FormInputPanel from './form-input-panel'
import FormControlPanel from './form-control-panel'
import { FormProps } from './hooks/form-context'
import * as style from './form.module.sass'
import { useGlobalContext } from 'components/context'

type Props = {formProps: FormProps}

const Form = ({formProps}: Props) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const globalContext = useGlobalContext()

	const unsavedAnswers = useSelector((state: ReduxState) => (
		Object.keys(state.answers.updated).length > 0
		|| Object.keys(state.answers.created).filter(id => (
			state.answers.created[id].hasUserEditedData
			&& !state.answers.created[id].deactivated
		)).length > 0
	))
	
	return (
		<div className={style.container}>
			<Prompt
				when={unsavedAnswers}
				message={(location) => {
					const currentFormRoute = formUrl({...globalContext, recordId: formProps.record.id, formId: formProps.form.id})
					return location.pathname.startsWith(currentFormRoute)
						? true
						: `You have unsaved changes! Are you sure you want to navigate away?`
					}}
			/>
			<SinglePanel ref={scrollContainerRef} type="full-width">
				<ErrorBoundary>
					<FormInputPanel formProps={formProps} scrollContainerRef={scrollContainerRef} />
				</ErrorBoundary>
			</SinglePanel>
			{!formProps.form.readOnly &&
				<div className={style.rightPanelContainer}>
					<ErrorBoundary>
						<FormControlPanel formProps={formProps} />
					</ErrorBoundary>
				</div>
			}
		</div>
	)
}

export default Form
import React from 'react'

import { DropzoneInput } from 'components/common/dropzone-input'
import { Answer } from 'resources/resource'
import { AnswerFileBaseProps } from './types'
import * as style from './answer-image.module.sass'
import * as commonStyle from './common.module.sass'

const AnswerImage = (props: AnswerFileBaseProps) => {
	let value = null

	if(props.answer.content) {
		value = props.answer.content.size
			? props.answer.content
			: Answer.getFileUrl(props.answer, {code: props.publicFormCode})
	}

	return (
		<div className={style.answerImageContainer}>
			<div className={style.answerImage}>
				{ props.disabled ?
					props.answer.content ?
						<div
							className={style.disabledImage}
							style={{backgroundImage: `url(${value})`}}
						/>
					:
						<i className={commonStyle.noContent}>No content</i>
				:
					<DropzoneInput
						name="image"
						humanName="photo"
						icon="icon-picture"
						cancelIcon="icon-reset"
						input={{
							onChange: (value: File) => {
								// file size validation handled by DropzoneInput
								props.onAnswerChanged({
									...props.answer,
									content: value,
									name: value ? value.name : null,
								})
							},
							value,
						}}
					/>
				}
			</div>
		</div>
	)
}

export default AnswerImage
import React, { Component } from 'react'
import { DRF } from 'types/drf'

import { ANSWER_LOOKUP, ANSWER_SIZE_LOOKUP } from './lookup'
import { isFieldDisabled } from '../utils'
import * as style from './answer-table.module.sass'

interface IProps {
	disabled: boolean
	field: DRF.Field
	tableFieldShowColumns: number
	publicFormCode: string
	answer: DRF.AnswerTableAny[]
	record: {id: string, archived: boolean}
	onAnswerChanged: (answer: DRF.AnswerTableAny) => void
}

interface IState {
	tableFields: { [id: string]: DRF.TableField}
}

export default class AnswerTable extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		var tableFields:  { [id: string]: DRF.TableField}  = {}
		this.props.field.table_fields.map((tf: DRF.TableField) => {
			tableFields[tf.id] = tf
		})
		this.state = {
			tableFields,
		}
	}

	render() {
		return <div className={style.answerTable}>
			<div className={style.answerTableRow}>
				{ this.props.answer.map((a,i) => {
					const tableField = this.state.tableFields[a.table_field]
					const Component = ANSWER_LOOKUP[tableField.type]

					const hide = i+1 > this.props.tableFieldShowColumns

					const disabled = isFieldDisabled({
						field: tableField,
						record: this.props.record,
						publicFormCode: this.props.publicFormCode,
					}) || this.props.disabled

					return (
						<div
							className={style.cell}
							key={a.table_field}
							style={{display: hide ? 'none' : 'inherit', ...ANSWER_SIZE_LOOKUP[tableField.type]}}
						>
							<Component
								onAnswerChanged={this.props.onAnswerChanged}
								disabled={disabled}
								answer={a}
								field={this.state.tableFields[a.table_field]}
								publicFormCode={this.props.publicFormCode}
								placeholder={this.state.tableFields[a.table_field].placeholder}
								selectOptions={this.state.tableFields[a.table_field].select_options}
							/>
						</div>
					)
				})}
			</div>
		</div>
	}
}


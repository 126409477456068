import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './root-reducer'

const loggerMiddleware = createLogger({
	collapsed: true,
	duration: false,
	timestamp: false,
})

function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		compose(
			applyMiddleware(
				thunkMiddleware,
				loggerMiddleware,
			),
		)
	)
}

export default configureStore()
import React from 'react'
import { Link } from 'react-router-dom'

import TeamForm from './team-form'
import { SinglePanel } from 'components/common/panel'
import { useGlobalContext } from 'components/context'

export default () => {
	const globalContext = useGlobalContext()
	const team =globalContext.currentTeam
	if (!team) {
		return <div>Error: no team</div>
	}
	return (
		<SinglePanel type="thin"> 
			<div className="page-form-container">
				<TeamForm team={team} />
			</div>
		</SinglePanel>
	)
}
import React from 'react'
import gql from 'graphql-tag'
import { useGlobalContext } from 'components/context'
import { useQuery } from 'react-apollo'
import ListMetric from '../../types/list'
import { UpcomingNotificationsMetric, UpcomingNotificationsMetricVariables } from './gql-types/UpcomingNotificationsMetric'
import MetricPlaceholder from '../metric-placeholder'


const QUERY = gql`
	query UpcomingNotificationsMetric($id: ID!, $teamId: ID) {
		upcomingNotificationsMetric(id: $id) {
			id
			name
			values(teamId: $teamId) {
				content
				contentObjectName
				formLink {
					form {
						id
						title
					}
					record {
						id
						name
					}
					field {
						id
						title
					}
				}
			}
		}
	}
`


type Props = {
	id: string
}

const UpcomingNotificationsMetricWrapper = ({ id }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<UpcomingNotificationsMetric, UpcomingNotificationsMetricVariables>(QUERY, {variables: {id, teamId: globalContext.currentTeam?.id}})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.upcomingNotificationsMetric) {
		return (
			<div className="muted">
				We're having trouble fetching this metric.
				If this issue persists, please contact support
			</div>
		)
	}
	return (
		<ListMetric {...data?.upcomingNotificationsMetric} useFieldTitle />
	)

}

export default UpcomingNotificationsMetricWrapper
import { Dispatch } from 'redux'


export function toggleFullScreenField(fieldId: string | null) {
	return {
		type: "EXPAND_CENTER_PANEL",
		fieldId,
	}
}

export function togglePrintableDashboard() {
	return {
		type: "TOGGLE_PRINTABLE_VERSION",
	}
}

export function disablePrintableDashboard() {
	return {
		type: "DISABLE_PRINTABLE_VERSION",
	}
}

type Notification = {
	type?: 'error' | 'neutral' | 'positive'
	content: string
}

export function createNotification (n: Notification) {
	return (dispatch: Dispatch) => {

		const notification = {
			...n,
			id: Math.floor(Math.random() * 9999999),
		}

		dispatch({
			type: 'CREATE_NOTIFICATION',
			notification,
		})

		setTimeout(
			() => dispatch({
				type: 'REMOVE_NOTIFICATION',
				notification,
			}),
			3000,
		)
	}
}
import React, { useMemo, useEffect } from 'react'
import _ from 'lodash'
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'
import gql from "graphql-tag"
import { useQuery } from '@apollo/react-hooks'

import Notifications from './general/notifications'
import { userEssentials } from 'fragments'
import MainModal from './general/modal/main'
import { GlobalContext, RouterContext, GLOBAL_CONTEXT_EMPTY } from './context'
import WelcomeModal from 'components/general/welcome-modal'
import PublicRecordCreate from 'components/public-record-create'
import PublicFormInvalid from 'components/general/full-page-messages/PublicFormInvalid'
import PublicFormSuccess from 'components/general/full-page-messages/PublicFormSuccess'
import Public from 'components/public'
import Dashboard from 'components/dashboard'
import LoadingDashboard from 'components/dashboard/loading'
import Account from 'components/auth'
import PublicAppHoc from 'components/public/hoc'
import Form from 'components/form'
import { WrappedRouteNotFound } from 'components/common/route-not-found'
import { 
	dashboardUrl,
	publicUrl,
	accountUrl,
	getTeamParams,
	publicFormUrl,
	publicCreateRecordUrl,
	publicFormSuccessUrl,
	publicFormInvalidUrl,
	loginUrl,
} from 'utils/url'
import { IS_INCIDENT_WATCH } from 'utils/constants'

declare const FS: any

const GET_CURRENT_USER = gql`
	query currentUser {
		user_List(isSelf: true) {
			objects {
				...UserEssentials
				
			}
		}
	}
	${userEssentials}
`

type Props = RouteComponentProps

const AppContainer = (props: Props) => {
	// const { data, loading } = useQuery(GET_CURRENT_USER)
	const data = null
	const loading = null
	const params = getTeamParams(props.location.pathname)

	// useMemo so that the globalContext only changes if it needs to
	// thus avoiding unecessary rerenders
	const globalContext = useMemo(() => {
		
			return GLOBAL_CONTEXT_EMPTY
	}, [params.moduleId, params.teamId]);

	return (
		<RouterContext.Provider value={_.pick(props, ['history', 'location', 'match'])}>
			<GlobalContext.Provider value={globalContext}>
				{ loading
					? <LoadingDashboard />
					: (
						<>
							<Switch>
								<Route path='/' exact component={Public} />
								<Route path={publicUrl()} component={Public} />
								<Route path={accountUrl()} component={Account} />
								{/* Redirc old login route, as some people have bookmarked, (changed on the 20th July 2019) */}
								<Redirect from="/login" to={{ pathname: loginUrl() }} /> 
								<Route path={dashboardUrl()} component={Dashboard} />
								<Route path={publicFormUrl(':publicFormCode')} component={PublicAppHoc(Form)} />
								<Route path={publicFormInvalidUrl()} component={PublicAppHoc(PublicFormInvalid)} />
								<Route path={publicFormSuccessUrl()} component={PublicAppHoc(PublicFormSuccess)} />
								<Route path={publicCreateRecordUrl(':code')} component={PublicAppHoc(PublicRecordCreate)} />
								{ !(props.location.pathname === '/' || props.location.pathname.startsWith(publicUrl())) &&
									<Route path="*" component={PublicAppHoc(WrappedRouteNotFound)} />
								}
							</Switch>
							<MainModal />
							<Notifications />
							<WelcomeModal />
						</>
					)
				}
			</GlobalContext.Provider>
		</RouterContext.Provider>
	)
}

export default AppContainer
import React, { useContext } from 'react'
import gql from "graphql-tag"
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'

import { useRouter } from 'components/context'
import { createNotification } from 'state/actions/userInterface'
import { deleteUser as MutationType, deleteUserVariables as MutationVariables } from './gql-types/deleteUser'
import { userManagementAddUrl } from 'utils/url'
import { UserListContext } from '../context'
import { getUser_user as UserType } from './gql-types/getUser'


const DELETE_USER = gql`
	mutation deleteUser(
		$id: ID!,
	) {
		user_Update(input: {
			id: $id,
			isActive: false
		}) {
			object {
				id
				isActive
			}
		}
	}
`

type Props = {
	user: UserType
}

export default (props: Props) => {
	const [deleteUser, {}] = useMutation<MutationType, MutationVariables>(DELETE_USER)
	const dispatch = useDispatch()
	const router = useRouter()
	const refetchList = useContext(UserListContext)

	return (
		<div>
			<button
				type="button"
				className="button-small button-grey"
				onClick={() => deleteUser({variables: {id: props.user.id}}).then(r => {
					if(r.errors) {
						dispatch(createNotification({content: `Error`, type: 'error'}))
					} else {
						router.history.push(userManagementAddUrl())
						refetchList()
						dispatch(createNotification({content: `User deleted`}))
					}
				})}
			>
				delete
			</button>
		</div>
	)
}
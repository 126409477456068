import React from 'react'
import gql from 'graphql-tag'

import Placeholder from 'components/common/placeholder'
import { useQuery } from 'react-apollo'
import { useGlobalContext } from 'components/context'
import { MetricsQuery, MetricsQueryVariables } from './gql-types/MetricsQuery'
import MetricContainer from './types/container'
import * as styles from './index.module.sass'
import FieldGroupMetric from './wrappers/field-group-metric'
import FormStatusMetric from './wrappers/form-status-metric'
import SpecificMetric from './wrappers/specific-metric'
import FieldAggregationMetric from './wrappers/field-aggregation-metric'
import FieldDateRangeMetric from './wrappers/field-date-range-metric'
import UpcomingNotificationsMetric from './wrappers/upcoming-notifications-metric'

const METRICS_QUERY = gql`
	query MetricsQuery($moduleId: ID!) {
		fieldDateRangeMetric_List(limit:100, module: $moduleId) {
			objects {
				id
				name
				order
			}
		}
		fieldAggregationMetric_List(limit:100, module: $moduleId) {
			objects {
				id
				name
				order
			}
		}
		fieldGroupMetric_List(limit:100, module: $moduleId) {
			objects {
				id
				name
				order
			}
		}
		specificMetric_List(limit:100, module: $moduleId) {
			objects {
				id
				name
				order
			}
		}
		formStatusMetric_List(limit:100, module: $moduleId) {
			objects {
				id
				name
				order
			}
		}
		upcomingNotificationsMetric_List(limit:100, module: $moduleId) {
			objects {
				id
				name
				order
			}
		}
	}
`

const Metrics = () => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery< MetricsQuery, MetricsQueryVariables>(
		METRICS_QUERY,
		{
			variables: {
				moduleId: globalContext.currentModule.id,
			}
		}
	)

	if (loading) {
		return (
			<div className={styles.container}>
				{[...Array(6).keys()].map((i) => (
					<Placeholder key={i} style={{width: 270, height: 320, margin: 20}} />
				))}
			</div>
		)
	}
	if (!data) {
		return <div>Error loading dashboard!</div>
	}
	let components: { component: React.ReactNode, order: number, name: string}[] = []

	data.formStatusMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FormStatusMetric id={metric.id} />,
		})
	})
	data.fieldGroupMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FieldGroupMetric id={metric.id} />,
		})
	})
	data.fieldDateRangeMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FieldDateRangeMetric {...metric} />,
		})
	})
	data.fieldAggregationMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FieldAggregationMetric id={metric.id} />,
		})
	})
	data.specificMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <SpecificMetric id={metric.id} />,
		})
	})
	data.upcomingNotificationsMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <UpcomingNotificationsMetric id={metric.id} />,
		})
	})

	components.sort((a, b) => a.order-b.order)

	return (
		<div className={styles.container}>
			{components.map((c, i) => (
				<MetricContainer name={c.name} key={i}>
					{c.component}
				</MetricContainer>
			))}
		</div>
	)
}

export default Metrics



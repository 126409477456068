import React from 'react'
import gql from 'graphql-tag'
import { useGlobalContext } from 'components/context'
import { useQuery } from 'react-apollo'
import PieMetric from '../../types/pie'
import { FormStatusMetric, FormStatusMetricVariables } from './gql-types/FormStatusMetric'
import MetricPlaceholder from '../metric-placeholder'


const QUERY = gql`
	query FormStatusMetric($id: ID!, $teamId: ID) {
		formStatusMetric(id: $id) {
			id
			name
			values(teamId: $teamId) {
				name
				formLinks {
					form {
						id
						title
					}
					record {
						id
						name
					}
					field {
						id
						title
					}
				}
			}
		}
	}
`


type Props = {
	id: string
}

const FormStatusMetricWrapper = ({ id }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<FormStatusMetric, FormStatusMetricVariables>(QUERY, {variables: {id, teamId: globalContext.currentTeam?.id}})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.formStatusMetric) {
		return <div>error</div>
	}
	return (
		<PieMetric {...data?.formStatusMetric} />
	)

}

export default FormStatusMetricWrapper
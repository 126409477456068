import React, { Component } from 'react'
import Placeholder from 'components/common/placeholder'

export const FormLoading = () => (
	<>
		<Placeholder />
		<Placeholder />
		<Placeholder />
		<Placeholder style={{height: 50, width: 100}}/>
	</>
)
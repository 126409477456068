import { createBrowserHistory } from "history"
import ReactGA from 'react-ga'
import { GOOGLE_ANALYTICS_ID } from 'utils/constants'


const history = createBrowserHistory()


ReactGA.initialize(GOOGLE_ANALYTICS_ID)
function logPageView() {
	ReactGA.set({ page: window.location.pathname })
	ReactGA.pageview(window.location.pathname)
}
history.listen(logPageView)


export default history
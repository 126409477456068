import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import { Answer } from 'resources/resource'
import { AnswerFileBaseProps } from './types'
import * as style from './answer-file.module.sass'
import * as commonStyle from './common.module.sass'


const AnswerFile = (props: AnswerFileBaseProps) => {
	const [error, setError] = useState<string | null>(null)

	const checkFile = (f: File) => {
		const MAX_SIZE = 6 //Mb
		if(f.size/1024/1024 > MAX_SIZE) {
			setError(`Please select a file under ${MAX_SIZE}Mb`)
		}
		else {
			props.onAnswerChanged({
				...props.answer,
				content: f,
				name: f.name,
			})
		}
	}
	
	return (
		<div className={style.answerFile}>
			{ props.disabled && !props.answer.content ?
					<i className={commonStyle.noContent}>No content</i>
			:
				props.answer.content ?
					<div className={style.file}>
						<a
							href={
								props.answer.content.preview ||
								Answer.getFileUrl(props.answer, {code: props.publicFormCode})
							}
							className={`icon-doc ${style.filename}`} target="_blank"
						>
							{props.answer.name}
						</a>
						
						{ !props.disabled &&
							<div
								className={`${style.removeIcon} icon-reset`}
								onClick={() => props.onAnswerChanged({...props.answer, content: null, name: null})}
							/>
						}
					</div>
				:
					<Dropzone
						onDrop={( filesToUpload, e ) => checkFile(filesToUpload[0])}
						multiple={false}
						className={style.dropzoneAnswer}
					>
						<div>
							<button className="icon-doc button-grey-solid">
								Select file
							</button>
						</div>
					</Dropzone>
			}
			<div className="error">{error}</div>
		</div>
	)
}

export default AnswerFile
import React from 'react'

import * as style from './index.module.sass'

type Props = {
	title?: React.ReactNode,
	content?: React.ReactNode,
}

const DEFAULT_MESSAGE = <>
	<p>Looks like something has gone wrong</p>
	<p>Please contact support if this issue persists</p>
</>

export const Error = ({title = "Error", content = DEFAULT_MESSAGE}: Props) => (
	<div className={style.container}>
		<div className={style.inner}>
			<h1><span className="icon-warning" />{title}</h1>
			{content}
		</div>
	</div>
)

class ErrorBoundary extends React.Component {
	state = {hasError: false}
	componentDidCatch() {
		this.setState({ hasError: true })
	}
	render() {
		if (this.state.hasError) {
			return <Error />
		}
		return this.props.children
	}
}

export default ErrorBoundary
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { signupUrl, getDjangoDomain } from 'utils/url'
import Footer from '../../common/footer'
import ScreenDemo from '../common/screen-demo'

const LANDING = [
	{
		color: "color-green-light",
		images: [
			require('./screens/sel.png'),
		],
		title: "Social and Emotional Learning (SEL)",
		text: "SEL is essential in building resilience. It is the ability to identify emotional experiences and to control emotional responses to external events",
	},
	{
		color: "color-green",
		images: [
			require('./screens/venn.png'),
		],
		title: "Elements to be Ready to Learn",
		text: "The overlap of these core elements focuses on the pre-requisite or ‘Ready to Learn’ skills essential to SEL for students at Yarra Me School",
	},
	{
		color: "color-green-light",
		images: [
			require('./screens/form1.png'),
			require('./screens/form2.png'),
			require('./screens/pdf.png'),
		],
		title: "Get students back on track",
		text: "Develop the knowledge, attitudes and skills necessary to manage their emotions and handle challenging situations",
	},
]

export default function Landing() {
	return (
		<div className="landing school-landing">
			<div className="color-green top-content">
				<div className="main-text">Social and emotional learning</div>
				<a href={getDjangoDomain() + signupUrl()}>
					<button className="get-started-button">
						Get Started
					</button>
				</a>
			</div>

			{ LANDING.map((demo, i) => {
				return <ScreenDemo key={i} {...demo}/>
			})}

			<Footer />
		</div>
	)
}

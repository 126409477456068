import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Footer from '../common/footer'
import { CONTACT } from './data'

export default function Landing() {
	return (
		<div className="contact-container">
			<div className="contact">
				<h4>{CONTACT.name}</h4>
				<div className="details-container">
					{ CONTACT.details.map((d, i) => {
						return <p key={i}><strong>{d.title}:</strong> {d.content}</p>
					})}
				</div>
			</div>
			<Footer />
		</div>
	)
}

import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { hasExtension } from 'utils/functions'


export class DropzoneInput extends Component {
	constructor(props) {
		super(props) 
		this.state = {
			error: "",
		}
	}

	checkFile(f) {
		const MAX_SIZE = 4 //Mb
		if(!hasExtension(f.name, ['png', 'jpg', 'jpeg'])){
			this.setState({...this.state, error: "Please select a valid image file (png or jpg)"})
		}
		else if(f.size/1024/1024 > MAX_SIZE) {
			this.setState({...this.state, error: `Please an image under ${MAX_SIZE}Mb`})
		}
		else {
			this.props.input.onChange(f)
		}
	}

	render() {
		return (
			<div className="dropzone-container">
				{ this.state.error &&
					<span className="error">{this.state.error}</span>
				}
				{ this.props.input.value ?
					<div className="image-container">
						<div
							className="image"
							style={{
                				backgroundImage: `url(${this.props.input.value.preview || this.props.input.value})`,
                			}}
                		/>
                		<div
							className={`cancel-icon ${this.props.cancelIcon || 'icon-cross'}`}
							onClick={() => this.props.input.onChange(null)}
						/>
					</div>
				:
					<Dropzone
						name={this.props.name}
						onDrop={( filesToUpload, e ) => this.checkFile(filesToUpload[0])}
						multiple={false}
						className="dropzone"
					>
						<div className="dropzone-inner">
							<div className="dropzone-text">Add {this.props.humanName}</div>
							<div className={`icon ${this.props.icon}`}/>
							<div className="dropzone-text muted">(optional)</div>
						</div>
					</Dropzone>
				}
			</div>
		)
	}
}
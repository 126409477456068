import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Placeholder from 'components/common/placeholder'
import { getActiveExternalLinks } from 'state/actions/analytics'
import { getParams } from '../../common'
import LinkItem from './link-item'
import * as style from './index.module.sass'


class ActiveExternalLinks extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
	}

	componentDidMount() {
		this.props.getActiveExternalLinks({offset: 0, limit: 11, ...getParams(this.props)}).then(() => {
			this.setState({...this.state, loading: false})
		})
	}

	loadMore() {
		this.setState({...this.state, loading: true})
		this.props.getActiveExternalLinks({offset: this.props.links.length, limit: 40, ...getParams(this.props)}).then(() => {
			this.setState({...this.state, loading: false})
		})
	}

	render() {
		return (
			<div className={style.container}>
				<h5>Active Share Links</h5>
				{
					this.props.links.map(link => {
						return <LinkItem key={link.id} link={link} />
					})
				}
				{ this.props.links.length == 0 && !this.state.loading &&
					<div className="muted">No active links</div>
				}
				{this.state.loading &&
					[...Array(5).keys()].map((i) => (
						<Placeholder key={i} style={{width: 600, height: 30, margin: 7}} />
					))
				}
				<div className={style.loadMoreContainer}>
					{this.props.more && !this.state.loading && 
						<button className={style.loadMoreButton} onClick={() => this.loadMore()}>more</button>
					}
				</div>
			</div>
		)
	}

}

const mapStateToProps = (state, ownProps) => {
	var links
	if(ownProps.record && ownProps.record.id) {
		links = _.filter(state.analytics.activeExternalLinks.objects, e => e.record && e.record.id == ownProps.record.id)
	}
	else {
		links = state.analytics.activeExternalLinks.objects
	}

	return {
		links,
		more: state.analytics.activeExternalLinks.more,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getActiveExternalLinks: (params) => dispatch(getActiveExternalLinks(params)),
	}
}

export default connect(
	mapStateToProps, 
	mapDispatchToProps
)(ActiveExternalLinks)

import _ from 'lodash'
import { combineReducers } from 'redux'
import { DRF } from 'types/drf'
import { ReduxState } from 'types/redux'

export function saved(state = {}, action: any) {
	if(action.type=="GOT_FORM") {
		return action.answers
	}
	else if(action.type=="SAVED_ANSWERS") {
		const newState = {..._.pickBy(state, (a: DRF.AnswerAny) => !a.deactivated)}
		action.answers.forEach((answer: DRF.AnswerAny) => {
			if(answer.deactivated) {
				delete newState[answer.id!]
			}
			else {
				newState[answer.id!] = answer
			}
		})
		return newState
	}
	return state
}

export function updated(state: ReduxState['answers']['updated'] = {}, action: any) {
	if(action.type =='ANSWER_CHANGED' && action.answer.id) {
		return {
			...state,
			[action.answer.id]: {...action.answer}
		}
	}
	else if(action.type =='MULTIPLE_ANSWERS_CHANGED') {
		const newState = {...state}
		action.answers.forEach((a: DRF.AnswerAny) => {
			if(a.id) {
				newState[a.id!] = {...a}
			}
		})
		return newState
	}
	else if(action.type =='SAVED_ANSWERS') {
		return {}
	}
	else if(action.type == 'FORM_LOADING') {
		return {}
	}
	else if(action.type == 'DEACTIVATE_ANSWERS') {
		const newState = {...state}
		action.answers.forEach((a: DRF.AnswerAny) => {
			if(a.id) {
				newState[a.id] = {
					...a,
					deactivated: new Date().toISOString(),
				}
			}
		})
		return newState
	}
	else if(action.type == 'UNDO_DEACTIVATE_ANSWERS') {
		const newState = {...state}
		action.answers.forEach((a: DRF.AnswerAny) => {
			newState[a.id!] = {
				...a,
				deactivated: null,
			}
		})
		return newState
	}

	return state
}

export function created(state: ReduxState['answers']['created'] = {}, action: any) {
	if(action.type=="GOT_FORM") {
		return action.initialAnswers
	}
	else if(action.type == 'ADD_ANSWER') {
		return {
			...state,
			[action.answer.tempId]: {
				...action.answer,
			}
		}
	}
	else if(action.type == 'ANSWER_CHANGED' && action.answer.tempId) {
		return {
			...state,
			[action.answer.tempId]: {
				...action.answer,
				hasUserEditedData: true,
			}
		}
	}
	else if(action.type =='MULTIPLE_ANSWERS_CHANGED') {
		const newState = {...state}
		action.answers.forEach((a: DRF.AnswerAny) => {
			if(a.tempId) {
				newState[a.tempId] = {
					...a,
					hasUserEditedData: true,
				}
			}
		})
		return newState
	}
	else if(action.type == 'DEACTIVATE_ANSWERS') {
		const newState = {...state}
		action.answers.forEach((a: DRF.AnswerAny) => {
			if(!a.id) {
				newState[a.tempId!] = {
					...a,
					hasUserEditedData: true,
					deactivated: new Date().toISOString(),
				}
			}
		})
		return newState
	}
	else if(action.type == 'UNDO_DEACTIVATE_ANSWERS') {
		const newState = {...state}
		action.answers.forEach((a: DRF.AnswerAny) => {
			newState[a.tempId!] = {
				...a,
				deactivated: null,
			}
		})
		return newState
	}
	else if(action.type == 'SAVED_ANSWERS') {
		return {}
	}
	else if(action.type == 'FORM_LOADING') {
		return {}
	}
	return state
}

export default combineReducers({
	saved,
	updated,
	created,
})
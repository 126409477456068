import React, { useState } from 'react'
import { Modal } from 'antd'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import history from 'utils/history'
import { formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import { Pdf as PdfType } from './gql-types/Pdf'
import * as style from './edit-name.module.sass'

type Props = {
	pdf: PdfType
	recordId: string
	formId: string
}

const CREATE_PDF = gql`
	mutation renamePdf(
		$id: ID!
		$name: String!
	) {
		pdf_Update(
			input: {
				id: $id
				name: $name
			}
		) {
			object {
				id
				name
			}
		}
	}
`

export const EditName = ({ pdf, recordId, formId }: Props) => {
	const [name, setName] = useState(pdf.name)
	const [renamePdfMutation, { loading }] = useMutation(CREATE_PDF)
	const globalContext = useGlobalContext()
	return (
		<Modal
			title="Rename this pdf"
			visible
			maskClosable
			onCancel={() => history.push(formUrl({recordId, formId, ...globalContext}))}
			okText="Save"
			onOk={() => {
				renamePdfMutation({variables: {id: pdf.id, name}})
				history.push(formUrl({recordId, formId, ...globalContext}))
			}}
		>
			<div className={style.container}>
				<input autoFocus className={style.input} type="text" value={name} maxLength={64} onChange={(e) => setName(e.target.value)} />
			</div>
		</Modal>
	)
}
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import _ from 'lodash'

import { getAggregatedTablesList } from 'state/actions/analytics'
import { Tabs } from 'components/common/tabs'
import { togglePrintableDashboard, disablePrintableDashboard } from 'state/actions/userInterface'
import { useGlobalContext } from 'components/context'
import { teamAggregatedTableUrl, teamConcatenatedTableUrl, teamMetricsUrl, GLOBAL_CONTEXT_PARAMS } from 'utils/url'
import { ReduxState } from 'types/redux'
import { ConcatenatedTables } from './gql-types/concatenatedTables'
import * as style from './index.module.sass'


const CONCATENATED_TABLES_QUERY = gql`
	query ConcatenatedTables($moduleId: ID!) {
		concatenatedTable_List(module: $moduleId) {
			objects {
				id
				name
			}
		}
	}

`

const TopRow = () => {
	const globalContext = useGlobalContext()
	const [loading, setLoading] = useState(false)
	
	const { data } = useQuery<ConcatenatedTables>(
		CONCATENATED_TABLES_QUERY,
		{ variables: { moduleId: globalContext.currentModule.id } }
	)
	const concatenatedTables = data?.concatenatedTable_List?.objects || []

	const tableObj = useSelector((state: ReduxState) => (state.analytics.aggregatedTable))
	const tables = Object.keys(tableObj).map(tId => tableObj[tId]).filter(t => t.module == globalContext.currentModule.id)

	const printableDashboard = useSelector((state: ReduxState) => state.userInterface.printableDashboard)

	const dispatch = useDispatch()

	useEffect(() => {
		setLoading(true)
		//@ts-ignore: Should be a simple one, 
		dispatch(getAggregatedTablesList(globalContext.currentModule)).then(() => {
			setLoading(false)
		})
	}, [globalContext.currentModule])

	// remove printable version on unmount
	useEffect(
		() => (() => {dispatch(disablePrintableDashboard())}),
		[],
	)
	return (
		<div className={style.topRow}>
			<div>
				{ !printableDashboard &&
					<Tabs tabs={[
						{name: 'Dashboard', url: teamMetricsUrl(globalContext)},
						...concatenatedTables.map(c => ({
							name: c.name,
							url: teamConcatenatedTableUrl({ tableId: c.id, ...globalContext}),
						})),
						...tables.map(t => ({
							name: t.name,
							url: teamAggregatedTableUrl({ tableId: t.id, ...globalContext}),
						}))
					]}/>
				}
			</div>

			<div className={style.buttonContainer}>
				<button 
					className={style.icon + " " + (printableDashboard ? "icon-cross" : "icon-expand")}
					onClick={() => dispatch(togglePrintableDashboard())}
				/>
			</div>
		</div>
	)
}

export default TopRow

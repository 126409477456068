import React, { useContext, useState } from 'react'
import _ from 'lodash'
import gql from "graphql-tag"
import c from 'classnames'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'

import { apolloClient } from 'state/apollo-client'
import { useGlobalContext, useRouter } from 'components/context'
import {
	adviceUrl,
	pharosAdminUrl,
	userSettingsUrl,
	addModuleUrl,
	organisationSettingsUrl,
	userManagementAddUrl,
	getPublicSiteDomain,
	loginUrl
} from 'utils/url'
import { SiteNameType } from 'types/common'
import Icon from './icon'
import IconModule from './icon-module'
import * as style from './index.module.sass'
import { ReduxState } from 'types/redux'
import { SITE_NAME } from 'utils/constants'

const LOGOUT_USER = gql`
	mutation logoutUser {
		user_Logout {
			success
		}
	}
`

export default () => {
	const router = useRouter()
	const { organisation, user } = useGlobalContext()
	const logo = SITE_NAME === 'PHAROS' ? 'icon-pharos'
		: SITE_NAME === 'QUESTOS' ? 'icon-questos'
		: SITE_NAME === 'SCHOOL' ? 'icon-school'
		: SITE_NAME === 'BASTION' ? 'icon-bastion'
		: 'icon-pharos'
	
	const [hideDropdown, toggleHideDropdown] = useState(false)
	const closeDropdown = () => {
		toggleHideDropdown(true)
		_.delay(toggleHideDropdown, 500, false)
	}

	const [logout, {}] = useMutation(LOGOUT_USER)

	const showModules = organisation.suite.moduleSetItems.filter(
		m => m.module.teams.length > 0
	)

	const printableDashboard = useSelector((state: ReduxState) => state.userInterface.printableDashboard)
	const fullScreenField = useSelector((state: ReduxState) => state.userInterface.fullScreenField)
	if (printableDashboard || fullScreenField) {
		return null
	}

	const allModulesAdded = showModules.length === organisation.suite.moduleSetItems.length

	return (
		<div
			className={style.navBarContainer}
			style={{pointerEvents: hideDropdown ? "none" : "inherit"}}
		>
			<div className={style.navBarGroup}>
				<a href={getPublicSiteDomain()} className={c(style.logo, logo)} />
				{ showModules.map(m => (
					<IconModule
						key={m.id}
						module={m.module}
						closeDropdown={closeDropdown}
					/>
				))}
				{ user.topLevelManager && !allModulesAdded &&
					<Icon
						iconLink={addModuleUrl()}
						activePath={addModuleUrl()}
						icon={"add"}
						title={`Add ${organisation.suite.moduleWord}`} 
						closeDropdown={closeDropdown}
						primary
					/>
				}
			</div>
			<div className={style.navBarGroup}>
				{ user.topLevelManager &&
					<Icon
						iconLink={userManagementAddUrl()}
						activePath={userManagementAddUrl()}
						icon={"users"}
						title="User Management"
						closeDropdown={closeDropdown}
						small
					/>
				}
				{ organisation.suite.adviceSection &&
					<Icon
						iconLink={adviceUrl()}
						activePath={adviceUrl()}
						icon={"not-found"}
						title={organisation.suite.adviceSection.title} 
						closeDropdown={closeDropdown}
						small
					/>
				}
				{ user.isAdmin &&
					<Icon
						iconLink={pharosAdminUrl()}
						activePath={pharosAdminUrl()}
						icon={"list"}
						title="Superuser"
						closeDropdown={closeDropdown}
						links={[
							{
								name: "Change team",
								to: pharosAdminUrl(),
							},
							{
								name: <a href="/admin">Django admin</a>,
							},
						]}
						small
					/>
				}
				<Icon
					iconLink={userSettingsUrl()}
					activePath={userSettingsUrl()}
					icon={"user"}
					title="Account"
					links={[
						...(user.topLevelManager ? [{
							name: "Organisation settings",
							to: organisationSettingsUrl(),
						}] : []),
						{
							name: "Your settings",
							to: userSettingsUrl(),
						},
						{
							name: "Logout",
							onClick: async () => {
								await logout()
								apolloClient.resetStore()

								// without this, ?next=pathname will be set in the url
								// this can cause confusion when logging in between different accounts
								setTimeout(() => router.history.push(loginUrl()), 10)
							}
						},
					]}
					moveDropdown={-70}
				/>
			</div>
		</div>
	)
}
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Link, Route } from 'react-router-dom'

import { getFormLinks } from 'state/actions/file'
import { Folder } from './files'
import { wrapWithContext, useGlobalContext } from 'components/context'
import { formLinksEditUrl } from 'utils/url'
import FormLinksEdit from 'components/file/FormLinksEdit'

const flattenFolders = (folder) => {
	return [...folder.files, ...folder.folders.map(f => flattenFolders(f))]
}

const flattenLinksToFolder = ({fileLinks, folderLinks}) => {
	

	let files = [
		...fileLinks.map(f => f.file),
		..._.flattenDeep(folderLinks.map(f => flattenFolders(f.folder)))
	]

	files = _.uniqBy(files, function (f) {
	  return f.id;
	});

	return {
		id: null,
		name: 'Reference documents',
		files,
		folders: [],
	}
}

const FormLinks = (props) => {
	useEffect(
		() => {props.getFormLinks({form: props.form.id, code: props.publicFormCode})},
		[],
	)
	const globalContext = useGlobalContext()

	const folder = flattenLinksToFolder({
		fileLinks: props.formLinks.formFileLinks || [],
		folderLinks: props.formLinks.formFolderLinks || [],
	})
	const to = !props.publicMode && formLinksEditUrl({recordId: props.record.id, formId: props.form.id, ...globalContext})
	return (
		<div>
			{props.form.show_team_files && props.organisation.showTeamFiles &&
				<div className="form-links">
					{ (folder.files.length > 0 || !props.publicMode) &&
						<div className="root-folder">
							<Folder folder={folder} details={{readOnly:true, contentType:'team'}}/>
						</div>
					}
					{!props.publicMode &&
						<>
							<Link to={to}>
								<button className="button-small button-grey-solid edit-button icon-add">Files</button>
							</Link>
							<Route path={to} render={() => <FormLinksEdit recordId={props.record.id} formId={props.form.id} />} />
						</>
					}
				</div>
			}
		</div>
	)
}


const mapStateToProps = (state, ownProps) => {
	return {
		formLinks: state.file.formLinks[ownProps.form.id] || {},
		form: state.mainForm.form,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getFormLinks: (params) => dispatch(getFormLinks(params))
	}
}

export default wrapWithContext(connect(
	mapStateToProps, 
	mapDispatchToProps
)(FormLinks))
import React from 'react'
import gql from "graphql-tag"
import { Link } from "react-router-dom"
import { Formik, Field } from 'formik'
import { TextField, ImageField } from 'components/common/formik'
import { SpinnerButton } from 'components/common/button'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { useGlobalContext, GlobalContextProps, useRouter } from 'components/context'
import { organisationSettingsUrl, teamMetricsDefaultUrl } from 'utils/url'
import { createNotification } from 'state/actions/userInterface'
import { useDispatch } from 'react-redux'
import { apolloClient } from 'state/apollo-client'


const UPDATE_TEAM = gql`
	mutation team_Update($id: ID!, $name: String!, $logo: Upload, $active: Boolean!) {
		team_Update(input: {id: $id, name: $name, logo: $logo, active: $active}) {
			object {
				id
				name
				logo
			}
		}
	}
`

type Props = {
	team: NonNullable<GlobalContextProps["currentTeam"]>
}

export default ({team}: Props) => {
	const [updateTeam, {}] = useMutation(UPDATE_TEAM)
	const globalContext = useGlobalContext()
	const router = useRouter()
	const dispatch = useDispatch()

	return (
		<>
			<Formik
				initialValues={{
					name: team.name,
					logo: team.logo,
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Required'),
					logo: ImageField.validator
				})}
				onSubmit={(values, actions) => {
					updateTeam({variables: {id: team.id, active: true, ...values}}).then(() => {
						actions.resetForm()
					}).catch((r) => {
						console.error(r)
						actions.setFieldError('all', 'There was a problem saving data')
					}).finally(() => {
						actions.setSubmitting(false)
					})
				}}
			>
				{({ errors, isSubmitting, handleSubmit }) => (
					<form onSubmit={handleSubmit} className="common-form">
						<h3 className="common-form-title">{globalContext.currentModule.teamWord} settings</h3>
						<Field
							name="name"
							placeholder="Name"
							component={TextField}
						/>

						{/* 
							We have disabled logo editing as Laser is the only client that
							requires different logos on a per team basis
							<Field
								title="logo"
								name="logo"
								component={ImageField}
							/>
							<div>
								<br/>
								<span className="muted">
									To upload a logo that applies to your entire account, head to your
								</span> <Link to={organisationSettingsUrl()}>Organisation Settings</Link>
							</div>
						*/}
						
						<div className="error">{
							//@ts-ignore
							errors.all}</div>

						<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
							{globalContext.user.topLevelManager &&
								<button
									className="button-small button-red"
									onClick={async () => {
										if (window.confirm(`Are you sure you want to archive ${team.name}?`)) {
											await updateTeam({variables: {...team, active: false}})
											router.history.push(teamMetricsDefaultUrl(globalContext))
											apolloClient.resetStore()
											dispatch(createNotification({
												'content': `${team.name} archived`
											}))
										}
									}}
									type="button"
								>
									Archive
								</button>
							}

							<SpinnerButton loading={isSubmitting} type="submit">
								Save
							</SpinnerButton>
						</div>
					</form>
				)}
			</Formik>
		</>
	)
}
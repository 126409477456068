import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from "graphql-tag"
import { Link, RouteComponentProps } from 'react-router-dom'
import c from 'classnames'
import { QueryResult } from '@apollo/react-common'

import { RouterContext } from 'components/context'
import Placeholder from 'components/common/placeholder'
import { userManagementAddUrl } from 'utils/url'
import * as style from './index.module.sass'
import UserItem from './user-item'
import { userList as QueryType } from './gql-types/userList'


export const GET_USERS = gql`
	query userList {
		user_List(
			limit: 200,
			isSelf: false,
			offset: 0,
			orderBy: [
				{field: firstName, modifiers: [CASE_INSENSITIVE]},
				{field: lastName, modifiers: [CASE_INSENSITIVE]}
			],
		) {
			objects {
				id
				firstName
				lastName
				email
				topLevelManager
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`


export default ({ data, loading }: QueryResult<QueryType>) => {

	return (
		<div className={style.containerOuter}>
			<div className={style.container}>
				<div className={style.title}>User Management</div>
				<Link
					to={userManagementAddUrl()}
					className={style.addLink}
				>
					<button className={c("button-primary-solid", "icon-add", style.addButtonInner)}>
						Invite User
					</button>
				</Link>
				{ loading
					? (
						<>
							{[...Array(10).keys()].map((i) => (
								<Placeholder key={i} style={{marginTop: 0, }} />
							))}
						</>
					)
					: data && data.user_List && data.user_List.objects.map(u => (
						<UserItem key={u.id} user={u} />
					))
				}
			</div>
		</div>
	)
}

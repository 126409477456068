import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { signupUrl, loginUrl, getPublicSiteDomain, getDjangoDomain } from 'utils/url'
import PHAROS from './icons/pharos.svg'
import QUESTOS from './icons/questos.svg'
import SCHOOL from './icons/school.svg'
import { useGlobalContext } from 'components/context'
import { SITE_NAME, IS_INCIDENT_WATCH, STATIC_BASE } from 'utils/constants'
import { NavProps } from '../common'


const LOGOS = {
	PHAROS,
	QUESTOS,
	SCHOOL,
	BASTION: null, //light mode
	INCIDENT: null,
}

const Nav = ({ printableDashboard }: NavProps) => {
	return (
		<div
			className="navbar"
			style={printableDashboard ? {display: 'none', height: 0} : {}}
		>
			<div className="navbar-group">
				{ !IS_INCIDENT_WATCH &&
					<a href={getPublicSiteDomain()}>
						<div className="standard-link"><img className="logo-questos" src={STATIC_BASE + LOGOS[SITE_NAME]}/></div>
					</a>
				}
			</div>
			<div className="navbar-group">
				{ !IS_INCIDENT_WATCH &&
					<a href={getDjangoDomain() + signupUrl()}><div className="standard-link">Signup</div></a>
				}
				<a href={getDjangoDomain() + loginUrl()}><div className="standard-link">Login</div></a>
			</div>
		</div>
	)
}

export default Nav

import React, { useState } from 'react'
import _ from "lodash"
import c from 'classnames'

import { GlobalContextProps, useGlobalContext } from 'components/context'
import { teamMetricsUrl, moduleUrl } from 'utils/url'
import Icon from './icon'
import * as style from './icon-module.module.sass'
import AddTeamModal from 'components/common/add-team-modal'

type Props = {
    module: GlobalContextProps["organisation"]["suite"]["moduleSetItems"][0]["module"]
    closeDropdown(): void
}

export default ({module, closeDropdown}: Props) => {
    const { user } = useGlobalContext()
    const [showAddTeamModal, setShowAddTeamModal] = useState(false)
    const teamLinks = module.teams.map(team => ({
        name: team.name,
        to: teamMetricsUrl({currentModule: module, currentTeam: team})
    }))
    const viewAll = {
        name: <div className={c(style.topLink)}>View All</div>,
        to: teamMetricsUrl({currentModule: module, currentTeam: null}),
    }
    const addTeam = {
        name: <button className={c("icon-add", "button-small", "button-primary-solid", style.addTeamButton)}>New {module.teamWord.toLowerCase()}</button>,
        onClick: () => setShowAddTeamModal(true),
    }
    const links = [
        ...(!module.singleton && user.topLevelManager ? [addTeam] : []),
        ...(module.teams.length >= 2 ? [viewAll] : []),
        ...teamLinks,
    ]
    return (
        <>
            <Icon
                title={module.name}
                icon={module.icon || ''}
                iconLink={teamMetricsUrl({
                    currentModule: module,
                    currentTeam: module.teams.length >= 2 ? null: module.teams[0]
                })}
                links={links}
                activePath={moduleUrl(module)}
                closeDropdown={closeDropdown}
            />
            <AddTeamModal module={module} show={showAddTeamModal} callback={() => {setShowAddTeamModal(false)}} />
        </>

    )
}

import { ReduxState } from "types/redux"
import { SHOW_MODAL, HIDE_MODAL } from '../actions/modal'


type ShowModalType = {
	type: typeof SHOW_MODAL
	modal?: ReduxState["modal"]
}

type HideModalType = {
	type: typeof HIDE_MODAL
}

export default function modal(state=null, action: ShowModalType | HideModalType) {
	if(action.type == 'SHOW_MODAL') {
		return { ...action.modal }
	}
	else if(action.type == 'HIDE_MODAL') {
		return null
	}
	return state
}
import React, { Component } from 'react'
import { connect, useSelector } from 'react-redux'

import gql from "graphql-tag"
import { SinglePanel } from 'components/common/panel'
import { RouteComponentProps } from 'react-router-dom'
import Files from 'components/file/files'
import Events from 'components/analytics/dashboard/events'
import { EMPLOYEE_CONTENT_TYPE } from 'utils/constants'
import FormSetDashboard from './form-set-dashboard'
import { Record, RecordVariables } from './gql-types/Record'
import DetailsSection from './details-section'
import { ReduxState } from 'types/redux'
import Placeholder from 'components/common/placeholder'
import { useQuery } from '@apollo/react-hooks'
import { useGlobalContext } from 'components/context'


const GET_EMPLOYEE = gql`
	query Record($id: ID!) {
		record(
			id: $id
		) {
			id
			name
			position
			archived
			image
			team {
				id
				name
			}
			template {
				id
				position
				formSet {
					id
					dashboard {
						id
						dashboardFields {
							id
							field {
								id
								title
							}
							hideIfBlank
							rank
							answers(recordId: $id)
							form(recordId: $id)
						}
						sections {
							id
							name
							rank
						}
					}
				}
			}
		}
	}
`
type InnerProps = {
	record: Record["record"]
}

const RecordDashboard: React.FC<InnerProps> = ({ record }) => {
	const refreshRecordList = useSelector((state: ReduxState) => state.record.refreshRecordList)
	const globalContext = useGlobalContext()
	return (
		<div>
			<DetailsSection record={record} refreshRecordList={refreshRecordList} />
			
			<div className="line-break"/>

			{ record.template!.formSet.dashboard &&
				<div>
					<FormSetDashboard dashboard={record.template!.formSet.dashboard} record={record} />
					<div className="line-break"/>
				</div>
			}

			<div className="analytics-metrics">
				<Events record={record.id} limit={4}/>
			</div>

			
			{ globalContext.currentModule.recordFilesEnabled &&
				<>
					<div className="line-break"/>
					<Files
						contentType={EMPLOYEE_CONTENT_TYPE}
						objectId={record.id}
					/>
				</>
			}
		</div>
	)
}


type Props = RouteComponentProps<{recordId: string}>


const RecordDashboardOuter: React.FC<Props> = (props) => {
	const { data, loading } = useQuery<Record, RecordVariables>(
		GET_EMPLOYEE,
		{ variables: { id: props.match.params.recordId } }
	)
	return (
		<SinglePanel type="thin">
			<div className="record-dashboard">
				{ loading
					? <>
						<Placeholder />
						<Placeholder style={{height: 300}}/>
						<Placeholder style={{height: 300}}/>
					</>
				: (!data || !data.record)
					? <div>error</div>
					: <RecordDashboard {...props} record={data.record} />
				}
			</div>
		</SinglePanel>
	)
}

export default RecordDashboardOuter
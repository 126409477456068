import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SinglePanel } from 'components/common/panel'
import Placeholder from 'components/common/placeholder'

import { Error } from 'components/common/error-boundary'
import { ReduxState } from 'types/redux'
import Form from './form'
import { useFormContextQuery } from './hooks/form-context'
import { useSubmitPublicFormMutation } from './hooks/public-form-submit'
import { useFetchRestResources } from './hooks/fetch-rest-resources'
import * as style from './form.module.sass'


type Props = RouteComponentProps<{recordId: string, formId: string}> | RouteComponentProps<{publicFormCode: string}>

const FormContainer = ({ match: { params } }: Props) => {
	const publicFormCode: string | null = "publicFormCode" in params ? params.publicFormCode : null
	const { record, form, organisation, loading: loadingContext, error } = useFormContextQuery(params)
	const fullScreenField = useSelector((state: ReduxState) => state.userInterface.fullScreenField)
	const formLoading = useSelector((state: ReduxState) => state.loading.form)
	const [publicFormSubmit, { loading: savingPublicForm }] = useSubmitPublicFormMutation(publicFormCode)
	useFetchRestResources(params)

	if (loadingContext || formLoading || savingPublicForm) {
		return <div className={style.container}>
		<SinglePanel type="full-width">
			<Placeholder style={{height: 50, width: "100%"}}/>
			<Placeholder style={{height: 300}}/>
			<Placeholder style={{height: 600}}/>
		</SinglePanel>
		<div className={style.rightPanelContainer}>
			<Placeholder style={{height: 50}}/>
			<Placeholder style={{height: 120}}/>
			<Placeholder style={{height: 50}}/>
			<Placeholder style={{height: 200}}/>
		</div>
	</div>
	}
	if (error || !record || !form || !organisation) {
		const message = (error?.message)?.toLowerCase().includes("permission")
			? (
				<p>It appears you don't have access to this page. Please contact your administrator.</p>
			)
			: null // use default
		return <Error content={message} />
	}

	const formProps = {
		record,
		form,
		organisation,
		fullScreenField,
		publicFormCode,
		publicFormSubmit,
	}
	return <Form formProps={formProps} />
}

export default FormContainer
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { OptionalTypename } from 'types/utils'

import { FormContextPublic, FormContextPublicVariables } from './gql-types/FormContextPublic'
import { FormContextAuth, FormContextAuthVariables } from './gql-types/FormContextAuth'
import { useGlobalContext } from 'components/context'

export const FORM_CONTEXT_PUBLIC = gql`
	query FormContextPublic($publicFormCode: String!) {
		unauthenticatedPublicFormData(publicFormCode: $publicFormCode) {
			publicForm {
				id
				record {
					id
					name
					archived
					position
					team {
						id
						organisation {
							id
						}
					}
				}
				form {
					id
					title
					description
					showTeamFiles
					publicLinkIsReadOnly
					allowPublicLinks
					allowReset
					readOnly
				}
			}
		}
	}
`

export const FORM_CONTEXT_AUTH = gql`
	query FormContextAuth($formId: ID!, $recordId: ID!) {
		form(id: $formId) {
			id
			title
			description
			showTeamFiles
			publicLinkIsReadOnly
			allowPublicLinks
			allowReset
			readOnly
		}
		record(id: $recordId) {
			id
			name
			archived
			position
			team {
				id
			}
		}
	}
`


type PublicForm = FormContextPublic["unauthenticatedPublicFormData"]["publicForm"]
type FormPublicContext = {
	record: OptionalTypename<PublicForm["record"]>
	form: OptionalTypename<PublicForm['form']>
}

type FormAuthContext = {
	record: OptionalTypename<FormContextAuth['record']>
	form: OptionalTypename<FormContextAuth['form']>
}
type SharedProps = {
	fullScreenField: string | null
	publicFormCode: string | null
	publicFormSubmit: (() => void) | null
	organisation: {id: string}
}

export type FormProps = (FormPublicContext | FormAuthContext) & SharedProps

interface PublicParams { publicFormCode: string }
interface AuthParams { formId: string, recordId: string }

export const useFormContextQuery = (params: AuthParams | PublicParams) => {
	let q, data, organisation
	if ('publicFormCode' in params) {
		q = useQuery<FormContextPublic, FormContextPublicVariables>(
			FORM_CONTEXT_PUBLIC,
			{ variables: { publicFormCode: params.publicFormCode } }
		)
		data = q.data?.unauthenticatedPublicFormData.publicForm
		organisation = data?.record.team.organisation
	} else {
		const globalContext = useGlobalContext()
		q = useQuery<FormContextAuth, FormContextAuthVariables>(
			FORM_CONTEXT_AUTH,
			{ variables: { formId: params.formId, recordId: params.recordId  } }
		)
		data = q.data
		organisation = globalContext.organisation
	}

	return {
		loading: q.loading,
		error: q.error,
		record: data?.record,
		form: data?.form,
		organisation: organisation,
	}
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export interface IIcon {
	title: string;
	icon: string;
	text: string;
}

interface IState {
	showMore: boolean;
}

interface IProps {
	showItems: IIcon[],
	hideItems: IIcon[],
}

export default class IconFeatureList extends Component<IProps, IState> {
	state = {
		showMore: false
	}

	render() {
		return (
			<div className="icon-feature-list-container">
				<div className="icon-feature-list">
					{
						this.props.showItems.map((item, i) => {
							const icon = classNames('icon-feature-list-icon', item.icon)
							return (
								<div key={i} className="icon-feature-list-item">
									<div className={icon}></div>
									<div className="title">{item.title}</div>
									<div className="text">{item.text}</div>
								</div>
							)
						})
					}
				</div>
				{ this.props.hideItems.length > 0 && 
					<button onClick={() => {this.setState({showMore: !this.state.showMore})}}>
						{ this.state.showMore ? 'Show less' : 'Show more' }
					</button>
				}
				{ this.state.showMore &&
					<div className="icon-feature-list">
						{
							this.props.hideItems.map((item, i) => {
								const icon = classNames(
									'icon-feature-list-icon',
									{
										[item.icon]: true,
									},
								)
								return (
									<div key={i} className="icon-feature-list-item">
										<div className={icon}></div>
										<div className="title">{item.title}</div>
										<div className="text">{item.text}</div>
									</div>
								)
							})
						}
					</div>
				}
			</div>
		)
	}
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ImageCarousel from './image-carousel'

export interface ISection {
	color: string;
	images: string[];
	title: string;
	text: string
}

export default function ScreenDemo(props: ISection) {
	return (
		<div className={`product-screens ${props.color}`}>
			<div className="screens">
				<ImageCarousel images={props.images} />
			</div>
			<div className="text-container">
				<div className="title">{props.title}</div>
				<div className="text">{props.text}</div>
			</div>
		</div>
	)
}
import React, { useState, useEffect } from 'react'

// stolen from https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci

export function useDebounce<T>(value: T, delay: number) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Set debouncedValue to value (passed in) after the specified delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			return () => {
				clearTimeout(handler);
			};
		},
		[value] 
	);

  return debouncedValue;
}
import React, { useState } from 'react'
import c from 'classnames'
import Form from './form'
import _ from 'lodash'
import { 
	record_record_template_formSet_forms as FormType,
	record_record_template_formSet_forms_category as CategoryQueryType
} from '../gql-types/record'
import { record_record as RecordType } from '../gql-types/record'
import * as style from './category.module.sass'
import { useFormParam } from 'components/context'


//This is in the utilities, don't know why it must be defined...
export type CategoryType = CategoryQueryType & {
	forms: FormType[]
}

type Props = {
	category: CategoryType
	record: RecordType
	allForms: FormType[]
}

export const Category = ({category, ...props}: Props) => {
	const formParamId = useFormParam().formId
	const initialOpen = !!category.forms.filter(f => (
		f.id === formParamId || f.hasSaved
	)).length
	const [show, updateShow] = useState(initialOpen || !category.collapsible)
	return (
		<div className={style.container}>
			<div className={style.title} onClick={() => updateShow(!show)}>
				{category.title}
			</div>
			<div className={c(style.forms, { [style.formsShow]: show })}>
				{category.forms.map(f => {
					let disabled = false
					if(f.dependency) {
						const dependentRf = _.find(props.allForms, currForm => {
							return currForm.id == f.dependency!.id
						})
						disabled = (dependentRf && dependentRf.hasCreatedPdf) || false
					}
					return (
						<Form 
							key={f.id}
							form={f} 
							record={props.record}
							disabled={false}
						/>
					)
				})}
			</div>
		</div>
	)
}
import React, { Component, SyntheticEvent } from 'react'
import { AnswerTextBaseProps } from './types'
import * as style from './answer-text.module.sass'
import * as commonStyle from './common.module.sass'

export default class AnswerTextShort extends Component<AnswerTextBaseProps> {
	onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onAnswerChanged({
			...this.props.answer,
			content: event.target.value,
		})
	}

	render() {
		return this.props.disabled ?
			<div className={style.answerTextDisabled}>
				{this.props.answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
		:
			<input 
				type='text'
				className={commonStyle.formInput}
				onChange={this.onChange}
				placeholder={this.props.field.placeholder}
				value={this.props.answer.content || ""}
			/>
			

	}
}


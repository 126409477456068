import c from 'classnames'
import React, { Component } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ReduxState } from 'types/redux'
import { toggleFullScreenField } from 'state/actions/userInterface'
import * as style from './shrink-button.module.sass'


const ShrinkButtonInner = () => {
	const fullScreenField = useSelector((state: ReduxState) => state.userInterface.fullScreenField)
	const dispatch = useDispatch()

	if(!fullScreenField) {
		return null
	}
	
	else {
		return (
			<button
				className={c(style.shrinkButton, "icon-shrink", "button-grey-solid")}
				onClick={() => dispatch(toggleFullScreenField(null))}
			>
				Show menu
			</button>
		)
	}
}

export default ShrinkButtonInner
import React, { useRef } from 'react'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import { connect, useSelector, useDispatch } from 'react-redux'

import { PUBLIC_FORM_CONTENT_TYPE } from 'utils/constants'
import { showModal } from 'state/actions/modal'
import { DRF } from 'types/drf'
import EmailModalIcon from 'components/email/icon'
import * as style from './external-link.module.sass'
import { useGlobalContext } from 'components/context'
import SimpleIconButton from 'components/common/simple-icon-button'
import { ExternalLink as ExternalLinkType } from './gql-types/ExternalLink'
import { FormProps } from 'components/form/hooks/form-context'
import { publicFormUrl, getDjangoDomain } from 'utils/url'



const getPublicUrl = (code: string) => {
	if(code) {
		return getDjangoDomain() + publicFormUrl(code)
	}
	else {
		return ""
	}
}

export const EXTERNAL_LINK_FRAGMENT = gql`
	fragment ExternalLink on PublicFormType {
		id
		active
		code
	}
`

const DEACTIVATE_PUBLIC_FORM = gql`
	mutation deactivatePublicForm($id: ID!) {
		publicForm_Update(input: {id: $id, active: false}) {
			object {
				...ExternalLink
			}
		}
	}
	${EXTERNAL_LINK_FRAGMENT}
`

type Props = {
	externalLink: ExternalLinkType
	formProps: FormProps
}


const ExternalLink = ({ externalLink, formProps }: Props) => {
	const globalContext = useGlobalContext()
	const publicUrlRef = useRef<HTMLInputElement>(null)
	const publicUrl = getPublicUrl(externalLink.code)
	const dispatch = useDispatch()
	const [deactivatePublicForm, { loading: mutationLoading }] = useMutation(DEACTIVATE_PUBLIC_FORM)
	return (
		<div className={style.publicLink}>
			<input
				className={style.url}
				type="text"
				ref={publicUrlRef}
				readOnly
				value={publicUrl}
				onClick={() => publicUrlRef.current && publicUrlRef.current.select()}
			/>
			<div className={style.linkButtonRow}>
				<SimpleIconButton
					iconClass="icon-link"
					tooltipDirection="right"
					title="Open link"
					onClick={() => window.open(publicUrl)}
				/>
				<EmailModalIcon
					recordId={formProps.record.id}
					formId={formProps.form.id}
					regardingObjectId={externalLink.id}
					regardingObjectContentType={PUBLIC_FORM_CONTENT_TYPE}
				/>
				
				<SimpleIconButton
					iconClass="icon-bin"
					tooltipDirection="right"
					title="Delete link"
					description={`Note: A deleted link cannot be reactivated, you must create a new one`}
					onClick={() => {
						dispatch(showModal({
							title: 'Warning',
							content: "You can't reactivate a deleted link, only create a new one.",
							confirmClass: 'button-red',
							confirmText: 'Delete',
							confirmAction: () => {
								deactivatePublicForm({variables: {id: externalLink.id}})
							}
						}))
					}}
				/>
			</div>
		</div>
	)
}

export default ExternalLink
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import subMinutes from 'date-fns/subMinutes'
import * as style from './answer-date.module.sass'
import * as commonStyle from './common.module.sass'
import { IconTextInput } from 'components/common/inputs/icon-text-input'

import { AnswerDatetimeBaseProps } from './types'

interface IState {
	date: Date | null
}

const TZ_OFFSET = new Date().getTimezoneOffset()

export default class AnswerDate extends Component<AnswerDatetimeBaseProps, IState> {
	datePicker: any;

	constructor(props: AnswerDatetimeBaseProps) {
		super(props)
		this.state = {
			date: this.props.answer.content
				? subMinutes(new Date(this.props.answer.content), -TZ_OFFSET)
				: null,
		}
	}

	onChange = (date: Date) => {
		this.setState({...this.state, date: date})
		this.props.onAnswerChanged({...
			this.props.answer,
			content: date ? subMinutes(date, TZ_OFFSET).toISOString() : null
		})
	}

	openDatePicker = () => {
		this.datePicker.input.focus()
	}

	render() {
		return <div className={style.dateInput}>

				{ this.props.disabled ?
					<div>
						<span className={`${style.iconCalendar} icon-calendar`} onClick={this.openDatePicker}></span>
						{ this.state.date ?
							format(this.state.date, "dd-MM-yyyy")
						:
							<i className={commonStyle.noContent}>No content</i>
						}
					</div>
				:
					<DatePicker
						ref={(c: any) => this.datePicker=c}
						placeholderText={this.props.field.placeholder}
						disabledKeyboardNavigation
						selected={this.state.date}
						onChange={this.onChange} 
						fixedHeight
						dateFormat="dd/MM/yyyy"
						popperPlacement="top-end"
						customInput={<IconTextInput iconClass="icon-calendar" />}
						popperModifiers={{
							offset: {
								enabled: true,
								offset: '0px, 0px'
							},
						}}
					/>
				}
		</div>
	}
}
import React from 'react'

import { SinglePanel } from 'components/common/panel'

export default function() {
  	return (
		<SinglePanel>
			<h1>Your form has been submitted</h1>
		</SinglePanel>
  	)
}

import { Folder, File, FormFolderLink, FormFileLink } from 'resources/resource'


export function updatedFile(file) {
	return {
		type: "UPDATED_FILE",
		file,
	}
}
export function updateFile(data) {
	return dispatch => {
		return new File().saveFormData(data).then(response => {
			return response.json().then(f => {
				dispatch(updatedFile(f))
			})
		})
	}
}


export function createdFile(file) {
	return {
		type: "CREATED_FILE",
		file,
	}
}
export function createFile(data, file) {
	return dispatch => {
		return new File().createFormData(data, file).then(response => {
			return response.json().then(f => {
				dispatch(createdFile(f))
			})
		})
	}
}


export function updatedFolder(folder) {
	return {
		type: "UPDATED_FOLDER",
		folder,
	}
}
export function updateFolder(data) {
	return dispatch => {
		return new Folder().save(data).then(response => {
			return response.json().then(f => {
				dispatch(updatedFolder(f))
			})
		})
	}
}


export function createdFolder(folder) {
	return {
		type: "CREATED_FOLDER",
		folder,
	}
}
export function createFolder(data) {
	return dispatch => {
		return new Folder().create(data).then(response => {
			return response.json().then(folder => {
				dispatch(createdFolder(folder))
			})
		})
	}
}


export function createEmptyFolder(params) {
	return dispatch => {
		dispatch(gotFiles([], params))
		dispatch(gotFolders([], params))
	}
}

export function gotFiles(files, {content_type, object_id}) {
	return {
		type: "GOT_FILES",
		files,
		content_type,
		object_id,
	}
}

export function getFiles(params) {
	return dispatch => {
		return new File().query(params).then(response => {
			return response.json().then(fs => {
				dispatch(gotFiles(fs, params))
			})
		})
	}
}

export function gotFolders(folders, {content_type, object_id}) {
	return {
		type: "GOT_FOLDERS",
		folders,
		content_type,
		object_id,
	}
}

export function getFolders(params) {
	return dispatch => {
		return new Folder().query(params).then(response => {
			return response.json().then(fs => {
				dispatch(gotFolders(fs, params))
			})
		})
	}
}


/////////////////////////////////////////////////////////////////


export function gotFormFolderLinks(formFolderLinks, { form }) {
	return {
		type: "GOT_FORM_FOLDER_LINKS",
		formFolderLinks,
		form,
	}
}

export function gotFormFileLinks(formFileLinks, { form }) {
	return {
		type: "GOT_FORM_FILE_LINKS",
		formFileLinks,
		form,
	}
}

export function getFormLinks(params) {
	return dispatch => {
		const formFolderPromise = new FormFolderLink().query(params).then(response => {
			return response.json().then(fs => {
				dispatch(gotFormFolderLinks(fs, params))
			})
		})

		const formFilePromise = new FormFileLink().query(params).then(response => {
			return response.json().then(fs => {
				dispatch(gotFormFileLinks(fs, params))
			})
		})

		return Promise.all([
			formFolderPromise,
			formFilePromise
		])
	}
}

function removedFormLink(formLink) {
	return {
		type: "REMOVED_FORM_LINK",
		formLink,
	}
}

export function removeFormLink(obj) {
	return dispatch => {
		const Resource = obj.file ? FormFileLink : FormFolderLink
		new Resource().save({id: obj.id, team: obj.team, active: false}).then(response => {
			return response.json().then(link => {
				dispatch(removedFormLink(link))
			})
		})
	}
}

function createdFormLink(formLink) {
	return {
		type: "CREATED_FORM_LINK",
		formLink,
	}
}

export function createFormLink(obj) {
	return dispatch => {
		const Resource = obj.file ? FormFileLink : FormFolderLink

		new Resource().create(obj).then(response => {
			return response.json().then(link => {
				dispatch(createdFormLink(link))
			})
		})
	}
}



import React from 'react'
import { Link } from 'react-router-dom'

import { signupUrl, getDjangoDomain } from 'utils/url'
import { useGlobalContext } from 'components/context'
import * as styles from './index.module.sass'
import { STATIC_BASE } from 'utils/constants'
import SmallFeatureList from './small-feature-list'
import LargeFeatureList from './large-feature-list'
import Video from './video'
import Pricing from '../../pricing'
import Footer from './footer'

const SMALL_FEATURES = ["Two modules free", "Unlimited users", "No credit card required", "Pay only for modules you use"]

const LARGE_FEATURES = [
	{
		title: "Free for all",
		subtitle: "Everyone needs these",
		items: [
			{
				title: "Meetings (one team)",
				body: "Automated electronic management of meeting minutes & actions",
				icon: "medium-meeting",
			},
			{
				title: "Governing Docs",
				body: "Safekeeping of legal entity information",
				icon: "medium-binders",
			},
		],
	},
	{
		title: "Base",
		subtitle: "For getting the foundations right",
		items: [
			{
				title: "Responsible Persons",
				body: "Meet regulatory requirements for key church appointments",
				icon: "medium-users-table",
			},
			{
				title: "Senior Pastor Employment",
				body: "In-depth guidance for a quality church/pastor relationship",
				icon: "medium-users", // filler
			},
			{
				title: "Volunteer compliance (per team)",
				body: "Centralised record keeping to improve the volunteer experience",
				icon: "medium-volunteer",
			},
			{
				title: "Meetings (per team)",
				body: "Additional meeting groups for sub committees, management teams etc",
				icon: "medium-meeting",
			},
		],
	},
	{
		title: "Growth",
		subtitle: "For when you need to manage growth",
		items: [
			{
				title: "Policy Library",
				body: "All current church policies in one secure place",
				icon: "medium-page-cog", // filler
			},
			{
				title: "People Management (HR)",
				body: "Forms & processes to better manage your people",
				icon: "medium-users",
			},
			{
				title: "Church Registers",
				body: "Tracking key church actions for audit & risk purposes",
				icon: "medium-clipboard",
			},
			{
				title: "Sub-Committees",
				body: "Model for effective board/council/committee operations",
				icon: "medium-users-network",
			},
		],
	},
	{
		title: 'Established',
		subtitle: "For when you're big and complex",
		items: [
			{
				title: "Risk Management",
				body: "Active risk tracking and assessment",
				icon: "medium-radar",
			},
			{
				title: "Incident Reporting",
				body: "Tracking of incidents for active management & risk reporting",
				icon: "medium-warning", // filler
			},
			{
				title: "Succession Planning",
				body: "Techniques to ensure the continuity of church leadership roles",
				icon: "medium-map", // filler
			},
			{
				title: "Property Maintenance",
				body: "Manage maintenance for church property and assets",
				icon: "medium-building", // filler
			},
		],
	},
]

const PRICING = [
	{
		title: "Free for all",
		cost: '$0',
		costSuffix: '',
		buttonText: "Get started",
		planFeatures: [
			{ text: "Meetings (one team)", },
			{ text: "Governing Docs", },
		],
	},
	{
		title: "Base",
		cost: "$3",
		costSuffix: "month / module",
		subText: "Or $9/month for all base modules",
		buttonText: "Get started",
		planFeatures: [
			{ text: "Responsible Persons", },
			{ text: "Senior Pastor Employment", },
			{ text: "Volunteer compliance (per team)", },
			{ text: "Meetings (per team)", },
		],
	},
	{
		title: "Growth",
		cost: "$5",
		costSuffix: "month / module ",
		subText: "Or $15/month for all* growth modules",
		buttonText: "Get started",
		planFeatures: [
			{ text: "Policy Library", },
			{ text: "Human Resources (per team)", },
			{ text: "Church Registers", },
			{ text: "Sub-Committees", },
		],
	},
	{
		title: "Established",
		cost: "$19",
		costSuffix: "month / module ",
		subText: "$57/month for all Established modules",
		buttonText: "Get started",
		planFeatures: [
			{ text: "Risk Management", },
			{ text: "Property Actions", },
			{ text: "Incident Reporting", },
			{ text: "Succession Planning", },
		],
	},
]

const Nav = () => {
	return (
		<div className={styles.container}>
			<div className={styles.heroText}>
				No fuss admin solutions for churches. So you can focus on what matters most.
			</div>
			<div className={styles.heroTextSmall}>
				Manage your governing documents and board meetings. For free. Always.
			</div>
			<a href={getDjangoDomain() + signupUrl()}>
				<button className={styles.getStartedButton}>
					Get Started
				</button>
			</a> 
			<SmallFeatureList items={SMALL_FEATURES} />
			<LargeFeatureList features={LARGE_FEATURES} />
			<div className={styles.heroTextSmall}>
				Select your own combination of modules that suit you.<br/>
				Pay only for the modules you use.
			</div>
			<Video url="URL" />
			<Pricing plans={PRICING} />
			<div className={styles.pricingHero}>$70/month for everything</div>
			<div className={styles.pricingConditions}>
				* A team is capped at 50 volunteers.<br/>
				* All prices are ex GST
			</div>
			<Footer />
		</div>
	)
}

export default Nav

import React, { Component } from 'react'
import { connect } from 'react-redux'
import c from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { getFormLinks, removeFormLink, createFormLink } from 'state/actions/file'
import { FilesTeamInner } from './team'
import { wrapWithContext } from 'components/context'
import { formUrl } from 'utils/url'
import StaticModal from 'components/general/modal/static'
import * as modalStyle from 'components/general/modal/index.module.sass'


class FormLinksEdit extends Component {
	state = {
		uploadFiles: false
	}

	createFormLink = (obj) => {
		const attribute = obj.folders ? 'folder' : 'file'
		const newObj = {
			[attribute]: obj.id,
			form: this.props.formId,
			team: this.props.teamId,
			created_by: this.props.user.id,
		}
		return this.props.createFormLink(newObj)
	}

	render() {
		const doneLink = formUrl({recordId: this.props.recordId, formId: this.props.formId, ...this.props})

		const files = _.reduce(
			this.props.formLinks.formFileLinks,
			(obj, f) => {obj[f.file.id] = f; return obj},
			{},
		)

		const folders = _.reduce(
			this.props.formLinks.formFolderLinks,
			(obj, f) => {obj[f.folder.id] = f; return obj},
			{},
		)

		return (
			<StaticModal>
				<div className="form-links-edit">
					<Link to={doneLink}>
						<button className={c("icon-cross", modalStyle.modalCancel)}/>
					</Link>
					<FilesTeamInner
						linkMode={!this.state.uploadFiles}
						matchFiles={files}
						matchFolders={folders}
						removeFormLink={this.props.removeFormLink}
						createFormLink={(obj) => this.createFormLink(obj)}
						readOnly={!this.state.uploadFiles}
						teamId={this.props.teamId}
					/>

					{!this.state.uploadFiles &&

						<div className="description">
							<div className="icon-info" />
							To attatch a file to this form, click the <span className="icon-tick"/> icon above.
							This file will be attached for all {this.props.currentModule.recordWord}s in your {this.props.currentModule.teamWord}.
							Attaching a folder will add all the files and folders within, including ones you add later.
						</div>
					}
					<div className="button-bottom-cotainer">
						<button className="switch-mode-button button-secondary" onClick={() => this.setState({...this.state, uploadFiles: !this.state.uploadFiles})}>
							{this.state.uploadFiles ? 
								<div className="icon-tick">Select Files</div>
							:
								<div className="icon-upload">Upload Files</div>
							}
						</button>
						<Link to={doneLink}>
							<button className="button-green">
								Done
							</button>
						</Link>
					</div>
				</div>
			</StaticModal>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		// Note: the current team could be `all`
		teamId: state.record.records.objects[ownProps.recordId].team,
		formLinks: state.file.formLinks[ownProps.formId] || {formFileLinks: [], formFolderLinks: []}
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getFormLinks: (params) => dispatch(getFormLinks(params)),
		removeFormLink: (obj) => dispatch(removeFormLink(obj)),
		createFormLink: (obj) => dispatch(createFormLink(obj)),
	}
}

export default wrapWithContext(connect(
	mapStateToProps, 
	mapDispatchToProps
)(FormLinksEdit))